/**
 *
 * CircleArrowUpIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CircleArrowUpIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon
          points="14.894,30.661 17.604,33.603 29.91,22.26 29.91,48.5 33.91,48.5 33.91,22.265 46.217,33.603 48.926,30.661 
      31.906,14.979     "
        />
        <path
          d="M31.91,0C14.314,0,0,14.355,0,32s14.314,32,31.91,32s31.91-14.355,31.91-32S49.506,0,31.91,0z M31.91,60
      C16.521,60,4,47.439,4,32S16.521,4,31.91,4s27.91,12.561,27.91,28S47.3,60,31.91,60z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default CircleArrowUpIcon;
