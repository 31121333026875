import * as React from "react";
import Tooltip, { TooltipErrorMessage } from "components/Tooltip";
import Field from "./Field";

export function ValidationTooltip({ children, anchor, onClick }) {
  return (
    <Tooltip open={!!children} theme="dark" anchor={anchor}>
      <TooltipErrorMessage onClick={onClick}>{children}</TooltipErrorMessage>
    </Tooltip>
  );
}

function FieldWithValidatiom({ errorMessage, ...rest }, ref) {
  return (
    <ValidationTooltip
      anchor={({ registerAnchor }) => (
        <Field
          {...rest}
          ref={node => {
            registerAnchor.current = node;

            if (ref && "current" in ref) {
              ref.current = node;
            } else if (typeof ref === "function") {
              ref(node);
            }
          }}
          error={!!errorMessage}
        />
      )}
    >
      {errorMessage}
    </ValidationTooltip>
  );
}

export default React.forwardRef(FieldWithValidatiom);
