import styled, { CSSProperties } from "styled-components/macro";

type Props = {
  flexDirection?: CSSProperties["flexDirection"];
  flexWrap?: CSSProperties["flexWrap"];
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
};

const Flex = styled.div<Props>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? "nowrap"};
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
`;

export default Flex;
