/**
 *
 * VirtualKeypadLogo
 * @author Chad Watson
 *
 *
 */
import React from "react";
import styled from "styled-components/macro";
const Svg = styled.svg`
  width: 100%;
`;

const VirtualKeypadLogo = ({ type = "light", ...rest }) => (
  <Svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.1 79.51">
    <defs>
      <style>
        {`.cls-1{fill:url(#linear-gradient);}.cls-2{fill:${
          type === "light" ? "#FFFFFF" : "#383F45"
        };}`}
      </style>
      <linearGradient
        id="linear-gradient"
        y1="39.75"
        x2="64.72"
        y2="39.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#a8aeff" />
        <stop offset="1" stopColor="#8f95f1" />
      </linearGradient>
    </defs>
    <title>Virtual Keypad</title>
    <path
      className="cls-1"
      d="M64.37,9.63c-.25-.79-1-3.19-5.1-5.09C54.83,2.47,46.64,0,32.83,0,22.36,0,14,1.09,7.86,3.23,5.69,4,.61,6.18.11,10,0,11,0,13.66,0,14V42.74C0,53.82,8.38,63.15,15.44,69c1.33,1.1,2.73,2.2,4.17,3.23a.57.57,0,0,0,.12.1h0c6.1,4.36,11.85,7.15,13.1,7.15,2.5,0,31.86-15.67,31.86-36.77V14A23.41,23.41,0,0,0,64.37,9.63Zm-55.16,3C10,11.9,15,9,32.76,9c13.62,0,20.76,2.24,22.75,3.59v29.3c0,7.75-6.25,14.84-11.49,19.43a75.09,75.09,0,0,1-11.26,8.07C30,67.91,9.21,55.94,9.21,41.93Z"
    />
    <path
      className="cls-2"
      d="M113.86,18.78l-8.37,32c-1.22,4.74-4.9,5.2-7.09,5.2s-5.87-.46-7.09-5.2l-8.37-32c0-.1-.05-.15-.05-.25a.87.87,0,0,1,.92-.87h5.41a1.08,1.08,0,0,1,1,.87l7.4,30.66c.15.61.31.82.82.82s.66-.2.82-.82l7.4-30.66a1.08,1.08,0,0,1,1-.87H113a.87.87,0,0,1,.92.87C113.91,18.63,113.86,18.68,113.86,18.78Z"
    />
    <path
      className="cls-2"
      d="M123.4,23h-4.95a1.07,1.07,0,0,1-1.07-1V17.46a1.07,1.07,0,0,1,1.07-1h4.95a1,1,0,0,1,1,1v4.49A1,1,0,0,1,123.4,23Zm-.15,32.6h-4.69a1.07,1.07,0,0,1-1-1.07V27.81a1,1,0,0,1,1-1h4.69a1,1,0,0,1,1,1V54.49A1.07,1.07,0,0,1,123.24,55.56Z"
    />
    <path
      className="cls-2"
      d="M143.75,32.15a14.44,14.44,0,0,0-7,1.84V54.49a1.07,1.07,0,0,1-1,1.07H131a1.07,1.07,0,0,1-1-1.07V27.81a1,1,0,0,1,1-1h4.44a1,1,0,0,1,1,1v1.07a11.87,11.87,0,0,1,7.29-2.45,1,1,0,0,1,1,1v3.67A1,1,0,0,1,143.75,32.15Z"
    />
    <path
      className="cls-2"
      d="M163.13,55.61a21.07,21.07,0,0,1-3.47.36c-5.76,0-8.11-1.28-8.11-8.11v-16l-4.79-.71a1.1,1.1,0,0,1-1-1v-2.3a1,1,0,0,1,1-1h4.79V22.61a1.1,1.1,0,0,1,1-1l4.74-.82h.2a.74.74,0,0,1,.82.77v5.25h4.59a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1h-4.59v16c0,2.55.15,2.86,2,2.86H163c.66,0,1.07.26,1.07.77v3.21C164.1,55.21,163.8,55.51,163.13,55.61Z"
    />
    <path
      className="cls-2"
      d="M190.17,55.56h-4.69a1,1,0,0,1-1-1V53.37a13.15,13.15,0,0,1-8,2.55,8.69,8.69,0,0,1-6.27-2.14c-1.89-1.89-2.55-5.05-2.55-9.28V27.86a1.07,1.07,0,0,1,1-1.07h4.69a1.07,1.07,0,0,1,1,1.07V44.49c0,2.55.2,4,1.17,4.9a4.42,4.42,0,0,0,3.16.92c1.89,0,4.34-1.28,5.76-2V27.86a1.07,1.07,0,0,1,1-1.07h4.69a1.07,1.07,0,0,1,1,1.07V54.54A1,1,0,0,1,190.17,55.56Z"
    />
    <path
      className="cls-2"
      d="M217.06,55.56h-4.34a1,1,0,0,1-1-1v-.82A13.83,13.83,0,0,1,204.51,56c-4.23,0-8.82-1.63-8.82-8.52v-.2c0-5.82,3.57-8.82,12.45-8.82h3.21V36.18c0-3.32-1.48-4.13-4.49-4.13a80.5,80.5,0,0,0-8.31.41h-.25c-.51,0-.92-.15-.92-.87V28.32c0-.56.31-.92,1-1.07a44,44,0,0,1,8.52-.82c7.24,0,11.22,3.21,11.22,9.74V54.54A1,1,0,0,1,217.06,55.56Zm-5.71-12.65h-3.21c-4.64,0-5.66,1.48-5.66,4.34v.2c0,2.5,1.12,3.16,3.67,3.16a11.17,11.17,0,0,0,5.2-1.43Z"
    />
    <path
      className="cls-2"
      d="M229.66,55.56h-4.74a1.07,1.07,0,0,1-1-1.07V17.76a1,1,0,0,1,1-1h4.74a1,1,0,0,1,1,1V54.49A1,1,0,0,1,229.66,55.56Z"
    />
    <path
      className="cls-2"
      d="M275.41,55.56h-6.84a1.17,1.17,0,0,1-1.17-.56L255.72,37.61V54.54a1,1,0,0,1-1,1h-5a1,1,0,0,1-1-1V18.68a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1V34.9l12.5-16.78c.25-.36.61-.41,1.17-.41h5.81c.66,0,1,.26,1,.61a1.44,1.44,0,0,1-.36.82L262.76,36l13.16,18.47a1.42,1.42,0,0,1,.25.61Q276.18,55.56,275.41,55.56Z"
    />
    <path
      className="cls-2"
      d="M299.8,43.52H283.17v.15c0,3,1.22,6.48,6.12,6.48,3.72,0,7.24-.31,9.39-.51h.15c.51,0,.92.25.92.77v3.37c0,.82-.15,1.17-1.07,1.33a45.38,45.38,0,0,1-9.9.87c-5.1,0-12.34-2.75-12.34-12.8V39.39c0-8,4.44-13,12.24-13s12.14,5.36,12.14,13v2.86C300.82,43.06,300.51,43.52,299.8,43.52Zm-5.76-5c0-4-2.19-6.27-5.36-6.27s-5.46,2.24-5.46,6.27v.2H294Z"
    />
    <path
      className="cls-2"
      d="M328.42,28,319.9,57.76c-1.58,5.56-3.47,8-10.41,8a23.74,23.74,0,0,1-5.41-.66c-.56-.15-1.07-.36-1.07-.87V61a.94.94,0,0,1,1-.92h.1c1.43.05,4.34.31,5.76.31,2.19,0,3.32-.61,4-3.26l.41-1.53h-.77c-1.48,0-3.32-.36-4.44-4.23L302.45,28a1,1,0,0,1-.05-.36c0-.51.31-.82,1-.82h5.15a1.13,1.13,0,0,1,1,.92l5.15,20.76c.2.87.41,1.12.71,1.12h.46l5.51-21.93a1.09,1.09,0,0,1,1-.87h5.2a.9.9,0,0,1,.92.87A.64.64,0,0,1,328.42,28Z"
    />
    <path
      className="cls-2"
      d="M343.62,56a38.55,38.55,0,0,1-4.9-.36v9a1,1,0,0,1-1,1H333a1,1,0,0,1-1-1V29.14c0-.77.2-1.07,1.38-1.38a43.46,43.46,0,0,1,10.25-1.33c8.21,0,12.19,4.85,12.19,12.8v3.93C355.81,51.12,351.83,56,343.62,56Zm5.46-16.73c0-4.18-1.07-7.29-5.46-7.29a29.68,29.68,0,0,0-4.9.36V50.1a42.57,42.57,0,0,0,4.9.36c4.39,0,5.46-3.11,5.46-7.29Z"
    />
    <path
      className="cls-2"
      d="M380.66,55.56h-4.34a1,1,0,0,1-1-1v-.82A13.83,13.83,0,0,1,368.11,56c-4.23,0-8.82-1.63-8.82-8.52v-.2c0-5.82,3.57-8.82,12.45-8.82h3.21V36.18c0-3.32-1.48-4.13-4.49-4.13a80.5,80.5,0,0,0-8.31.41h-.25c-.51,0-.92-.15-.92-.87V28.32c0-.56.31-.92,1-1.07a44,44,0,0,1,8.52-.82c7.24,0,11.22,3.21,11.22,9.74V54.54A1,1,0,0,1,380.66,55.56Zm-5.71-12.65h-3.21c-4.64,0-5.66,1.48-5.66,4.34v.2c0,2.5,1.12,3.16,3.67,3.16a11.17,11.17,0,0,0,5.2-1.43Z"
    />
    <path
      className="cls-2"
      d="M408.66,54.64A43.48,43.48,0,0,1,398.41,56c-8.21,0-12.19-4.85-12.19-12.8V39.24c0-8,4-12.8,12.19-12.8a38.54,38.54,0,0,1,4.9.36v-9a1,1,0,0,1,1-1H409a1,1,0,0,1,1,1v35.5C410,54,409.83,54.34,408.66,54.64ZM403.31,32.3a42.57,42.57,0,0,0-4.9-.36c-4.39,0-5.46,3.11-5.46,7.29v3.93c0,4.18,1.07,7.29,5.46,7.29a29.68,29.68,0,0,0,4.9-.36Z"
    />
    <path className="cls-2" d="M414.11,18.46h-1.56v-.8h4v.8H415v4.1h-.86Z" />
    <path
      className="cls-2"
      d="M417.28,17.66h.92L419.69,20l1.49-2.32h.92v4.9h-.86V19l-1.55,2.31h0l-1.53-2.3v3.5h-.85Z"
    />
  </Svg>
);

export default VirtualKeypadLogo;
