/**
 *
 * PhoneIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PhoneIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M41.59,64c0,0-0.001,0-0.002,0c-8.31-0.001-18.782-9.631-25.468-23.418C8.554,24.975,8.155,9.656,15.147,4.059
      c0.341-0.335,0.698-0.594,1.087-0.786l5.521-2.75C22.447,0.176,23.198,0,23.983,0c2.17,0,4.235,1.367,5.262,3.482l4.85,10.004
      c1.432,2.952,0.425,6.435-2.244,7.764l-6.037,3.008c0.931,3.245,2.183,6.503,3.725,9.686c1.539,3.172,3.309,6.155,5.263,8.869
      l6.005-2.992c0.695-0.346,1.445-0.521,2.229-0.521c2.171,0,4.236,1.367,5.263,3.482l4.85,10.003
      c1.432,2.951,0.425,6.435-2.244,7.764l-5.521,2.752c-0.392,0.194-0.811,0.322-1.279,0.392C43.302,63.897,42.456,64,41.59,64z
       M18.016,6.854c0,0-0.029,0.021-0.09,0.084c-0.067,0.07-0.139,0.134-0.215,0.194c-5.993,4.662-3.624,20.087,2.009,31.705
      C26.456,52.729,35.82,59.999,41.589,60h0.001c0.563,0,1.104-0.069,1.608-0.204c0.093-0.024,0.188-0.043,0.283-0.055
      c0.092-0.011,0.124-0.023,0.125-0.023l5.513-2.748c0.692-0.345,0.893-1.484,0.429-2.438l-4.85-10.003
      c-0.534-1.104-1.523-1.417-2.109-1.126l-6.402,3.19c-1.477,0.737-3.427,0.213-4.453-1.194c-2.159-2.967-4.109-6.233-5.795-9.708
      c-1.681-3.469-3.04-7.028-4.041-10.579c-0.479-1.713,0.263-3.498,1.763-4.248l6.407-3.191c0.691-0.345,0.892-1.483,0.428-2.438
      l-4.85-10.004C25.236,4.385,24.497,4,23.983,4c-0.161,0-0.307,0.033-0.443,0.101L18.016,6.854L18.016,6.854z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PhoneIcon;
