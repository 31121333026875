/**
 *
 * PlayIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PlayIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M45.236,30.323l-19.09-13.595c-0.609-0.436-1.406-0.492-2.069-0.148C23.411,16.923,23,17.608,23,18.357v27.415
      c0,0.752,0.414,1.439,1.084,1.781c0.287,0.146,0.596,0.219,0.906,0.219c0.412,0,0.823-0.128,1.171-0.38l19.089-13.82
      c0.521-0.378,0.829-0.983,0.826-1.628S45.761,30.696,45.236,30.323z M27,41.855V22.237l13.661,9.729L27,41.855z"
        />
        <path
          d="M53.129,0H10.871C4.877,0,0,5.126,0,11.269v41.594C0,59.004,4.877,64,10.871,64h42.258C59.123,64,64,59.004,64,52.862
      V11.269C64,5.126,59.123,0,53.129,0z M60,52.862C60,56.798,56.918,60,53.129,60H10.871C7.082,60,4,56.798,4,52.862V11.269
      C4,7.332,7.082,4,10.871,4h42.258C56.918,4,60,7.332,60,11.269V52.862z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PlayIcon;
