/**
 *
 * DownloadIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DownloadIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon
          points="34,41.74 34,15.5 30,15.5 30,41.735 17.693,30.397 14.984,33.339 32.004,49.021 49.017,33.339 46.306,30.397     
      "
        />
        <path
          d="M32,0C14.404,0,0.09,14.355,0.09,32S14.404,64,32,64s31.91-14.355,31.91-32S49.596,0,32,0z M32,60
      C16.61,60,4.09,47.439,4.09,32S16.61,4,32,4s27.91,12.561,27.91,28S47.39,60,32,60z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default DownloadIcon;
