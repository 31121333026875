/**
 *
 * UserIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const UserIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M31.06,30.11c8.385,0,15.207-6.753,15.207-15.053C46.267,6.754,39.445,0,31.06,0c-8.384,0-15.205,6.754-15.205,15.057
      C15.854,23.357,22.675,30.11,31.06,30.11z M31.06,4c6.18,0,11.207,4.96,11.207,11.057c0,6.095-5.027,11.053-11.207,11.053
      c-6.179,0-11.205-4.958-11.205-11.053C19.854,8.96,24.881,4,31.06,4z"
        />
        <path
          d="M46.263,30.072l-1.079-0.521l-0.969,0.706c-3.902,2.844-8.451,4.348-13.156,4.348c-4.642,0-9.133-1.458-12.988-4.218
      l-1.005-0.719l-1.093,0.577C1.609,37.829,0.403,40.958,0.078,55.131c-0.016,0.731-0.032,1.964-0.047,3.241
      c-0.016,1.318-0.033,2.684-0.052,3.585L-0.066,64H63.94l0.047-1.952c0.028-1.174,0.051-3.665,0.064-5.155l0.007-0.722
      C64.221,39.934,61.747,37.55,46.263,30.072z M60.058,56.131l-0.006,0.726c-0.008,0.862-0.019,2.063-0.032,3.144H50V48h-4v12H18V48
      h-4v12H4.01c0.007-0.512,0.013-1.049,0.02-1.579c0.015-1.261,0.03-2.479,0.046-3.199c0.288-12.565,0.738-14.475,12.703-20.876
      c4.299,2.79,9.21,4.259,14.28,4.259c5.16,0,10.153-1.525,14.516-4.422C58.945,40.668,60.2,42.024,60.058,56.131z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default UserIcon;
