/**
 *
 * BellChimeIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const BellChimeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M51.918,30.081c-2.539-2.318-3.454-5.869-4.325-9.64C46.253,14.617,45,6.76,34,5.271V3.56C34,1.597,32.467,0,30.5,0
    S27,1.597,27,3.56v1.774c-10,1.642-11.727,9.376-13.043,15.112c-0.867,3.761-1.672,7.313-4.214,9.634
    c-7.331,6.685-6.235,22.404-6.185,23.069L3.704,55H6h13.786c0.234,2.282,0.992,3.976,3.172,6.162
    C24.847,63.045,27.426,64,30.625,64c3.214,0,5.802-0.958,7.689-2.847c2.173-2.173,2.927-3.866,3.158-6.153H57h2.101l0.013-1.987
    C59.118,52.348,59.136,36.658,51.918,30.081z M35.485,58.326C34.375,59.437,32.74,60,30.625,60c-2.102,0-3.73-0.562-4.838-1.667
    C24.5,57.043,24.011,56.178,23.82,55h13.629C37.27,56.152,36.796,57.015,35.485,58.326z M37.574,51H23.68H7.49
    c-0.05-4.068,0.347-13.755,4.963-17.965c3.464-3.161,4.509-7.692,5.432-11.696c1.361-5.928,2.646-11.527,11.337-12.242l1.431-0.118
    l1.411,0.091c9.038,0.58,10.344,6.256,11.73,12.283c0.922,3.995,1.967,8.523,5.428,11.683c4.579,4.173,5.6,13.793,5.827,17.964
    H37.574z"
      />
    </g>
  </SvgIcon>
);

export default BellChimeIcon;
