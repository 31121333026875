/**
 *
 * MediaQuery: A React component to conditionally render content based on a media query for the app's breakpoints
 * @author Chad Watson
 *
 */

import * as React from "react";
import PropTypes from "prop-types";
import Breakpoints from "./index";

export const TYPES = {
  MIN: "min",
  MAX: "max",
};

export const MediaQuery = ({
  targetBreakpoint,
  type = TYPES.MIN,
  children,
}) => (
  <Breakpoints.Consumer>
    {({ breakpointIsActive }) =>
      breakpointIsActive(targetBreakpoint, type === TYPES.MAX) ? children : null
    }
  </Breakpoints.Consumer>
);

MediaQuery.propTypes = {
  targetBreakpoint: PropTypes.string.isRequired,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(TYPES)),
};

MediaQuery.defaultProps = {
  children: null,
  type: TYPES.MIN,
};

export default MediaQuery;
