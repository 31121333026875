/**
 * NakedList
 * @author Chad Watson
 */

import styled from "styled-components/macro";

const NakedList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export default NakedList;
