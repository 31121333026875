/**
 *
 * DeleteButton
 * @author Chad Watson
 *
 */

import React from "react";
import { FormattedMessage } from "react-intl";
import { DestructiveButton } from "components/Button";
import messages from "./messages";

const DeleteButton = ({
  children,
  ...props
}: React.HTMLProps<HTMLButtonElement>) => (
  <DestructiveButton {...props} size="small" variant="outline">
    {children || <FormattedMessage {...messages.delete} />}
  </DestructiveButton>
);

export default DeleteButton;
