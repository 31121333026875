/**
 *
 * RadialCheckIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialCheckIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon points="24.779,39.018 18.578,31.058 15.422,33.517 24.238,44.83 48.323,23.576 45.677,20.577     " />
        <path
          d="M32,0.09C14.355,0.09,0,14.405,0,32s14.355,31.91,32,31.91S64,49.595,64,32S49.645,0.09,32,0.09z M32,59.91
      C16.561,59.91,4,47.39,4,32S16.561,4.09,32,4.09S60,16.61,60,32S47.439,59.91,32,59.91z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default RadialCheckIcon;
