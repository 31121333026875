/**
 *
 * ExclamationIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ExclamationIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path d="M40,2c0-1.104-0.896-2-2-2H26c-1.104,0-2,0.896-2,2v41c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V2z M36,41h-8V4h8V41z" />
        <path
          d="M40,52c0-1.104-0.896-2-2-2H26c-1.104,0-2,0.896-2,2v10c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V52z M36,60h-8v-6h8
      V60z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ExclamationIcon;
