/**
 *
 * CreditCardFrontIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CreditCardFrontIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M56.788,9H7.212C3.235,9,0,12.267,0,16.282v30.983C0,51.458,3.303,55,7.212,55h49.576C60.697,55,64,51.458,64,47.266
      V16.282C64,12.267,60.765,9,56.788,9z M60,47.266C60,49.186,58.439,51,56.788,51H7.212C5.561,51,4,49.186,4,47.266V16.282
      C4,14.473,5.44,13,7.212,13h49.576C58.56,13,60,14.473,60,16.282V47.266z"
        />
        <rect x="11" y="39" width="8" height="4" />
        <rect x="22" y="39" width="9" height="4" />
        <rect x="34" y="39" width="9" height="4" />
        <rect x="46" y="39" width="8" height="4" />
        <path
          d="M17.802,19h-4.619C11.969,19,11,19.91,11,21.057v5.866C11,28.07,11.969,29,13.183,29h4.619C19.016,29,20,28.07,20,26.923
      v-5.866C20,19.91,19.016,19,17.802,19z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default CreditCardFrontIcon;
