/**
 *
 * A React Component that functions as a cross-browser select field
 * @author: Chad Watson
 *
 */
import Flex from "components/Flex";
import {
  BACKSPACE,
  DOWN_ARROW,
  ENTER,
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
} from "containers/App/constants";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import useWindowDimensions from "hooks/use-window-dimensions";
import { useIntl } from "hooks/useIntl";
import { compose, isNil, not } from "ramda";
import React, {
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import OptionLabel, {
  DropdownOption,
  LabeledValueDropdownOption,
  dropdownOptionIsStringDropdownOption,
} from "../OptionLabel";
import messages from "../messages";
import {
  DropdownSize,
  Option,
  Options,
  Selected,
  Wrapper,
} from "../styled-components";

export type {
  DropdownOption,
  LabeledValueDropdownOption,
  StringDropdownOption,
} from "../OptionLabel";

export type Props<Value, Option extends DropdownOption<Value>> = {
  onChange: (option: Option, index: number) => void;
  selectedIndex: number | null;
  options: Option[];
  id?: string;
  size?: DropdownSize;
  label?: string;
  className?: string;
  withBorder?: boolean;
  error?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  openZIndex?: number;
};

function Dropdown<Value, Option extends DropdownOption<Value>>({
  onChange: notifyChange,
  selectedIndex,
  options,
  id = "",
  size = "default",
  label = "",
  className,
  withBorder = true,
  error = false,
  disabled,
  openZIndex = 1,
}: Props<Value, Option>) {
  // References
  const wrapperRef = useRef(null);
  const selectedOptionRef = useRef<HTMLButtonElement | null>(null);
  const queryTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  // ---------------------------
  // State & Derrived State
  // ---------------------------
  // Derrived State Helpers

  const getQueryMatches = (query: string) =>
    options
      .map((option, i) => {
        const value = dropdownOptionIsStringDropdownOption(option)
          ? (option as string)
          : (option as { label: string }).label;
        return value.toLowerCase().indexOf(query) === 0 ? i : null;
      })
      .filter(compose(not, isNil)); // Base State

  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false); // Derrived State

  const queryMatchesByIndex = getQueryMatches(query);
  const [activeOptionIndex, setActiveOptionIndex] = useState(
    queryMatchesByIndex[0] || selectedIndex
  );
  const selectedOption = selectedIndex !== null ? options[selectedIndex] : null;

  // Set SelectedOptionIcon if selected option is non-string, and has an infoIcon
  const SelectedOptionIcon =
    typeof selectedOption !== "string" &&
    !!(selectedOption as LabeledValueDropdownOption<Value>)?.infoIcon
      ? (selectedOption as LabeledValueDropdownOption<Value>)?.infoIcon
      : null;
  const windowDimensions = useWindowDimensions();

  const optionIsDisabled = (option: DropdownOption<Value>) =>
    typeof option !== "string" && !!option.disabled;

  // ---------------------------
  // State Functions
  // ---------------------------

  const close = () => setOpen(false);

  const onChange = (selectedIndex: number | null) => {
    if (selectedIndex !== null && selectedIndex in options) {
      notifyChange(options[selectedIndex], selectedIndex);
    }
  };
  // ---------------------------
  // Event Handlers
  // ---------------------------

  const handleOptionClick = (e: MouseEvent<HTMLButtonElement>) => {
    const selectedIndex = parseInt(e.currentTarget.value, 10);
    const option = options[selectedIndex];

    if (optionIsDisabled(option)) {
      return;
    }

    setActiveOptionIndex(selectedIndex);
    setOpen(false);
    onChange(selectedIndex);
  };

  const handleOptionMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    const index = parseInt(e.currentTarget.value, 10);
    const option = options[index];

    if (optionIsDisabled(option)) {
      return;
    }

    setActiveOptionIndex(index);
  };

  const handleOptionMouseLeave = () => setActiveOptionIndex(selectedIndex);

  const handleFocus = () => setFocus(true);

  const handleBlur = () => {
    setFocus(false);
    setOpen(false);
    onChange(activeOptionIndex);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (!open && keysThatOpen.indexOf(event.which) !== -1) {
      event.preventDefault();
      setOpen(true);
    } else if (keyHandlers[event.which]) {
      event.preventDefault();
      keyHandlers[event.which](event);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLButtonElement>) => {
    const { key } = event;

    if (!key) {
      return;
    }

    setQuery(query + key.toLowerCase());
  };

  const handleSelectedClick = () => setOpen(!open);
  // ---------------------------
  // Helper Functions
  // ---------------------------

  const keysThatOpen = [ENTER, UP_ARROW, DOWN_ARROW];
  const keyHandlers = {
    [BACKSPACE]() {
      setQuery(query.slice(0, query.length - 1));
    },

    [LEFT_ARROW]() {
      keyHandlers[UP_ARROW]();
    },

    [UP_ARROW]() {
      setActiveOptionIndex(
        activeOptionIndex === null || activeOptionIndex <= 0
          ? 0
          : activeOptionIndex - 1
      );
    },

    [RIGHT_ARROW]() {
      keyHandlers[DOWN_ARROW]();
    },

    [DOWN_ARROW]() {
      setActiveOptionIndex(
        activeOptionIndex === null
          ? 0
          : activeOptionIndex >= options.length - 1
          ? options.length - 1
          : activeOptionIndex + 1
      );
    },

    [ENTER]() {
      setOpen(false);
      onChange(queryMatchesByIndex[0] || activeOptionIndex);
    },
  };

  const distanceFromBottom =
    selectedOptionRef.current === null
      ? 0
      : windowDimensions.height -
        selectedOptionRef.current.getBoundingClientRect().bottom;

  const optionIndexMatchesQuery = (index: number) =>
    queryMatchesByIndex.indexOf(index) !== -1;
  // ---------------------------
  // Side Effects
  // ---------------------------

  const intl = useIntl();
  useOnClickOutside(wrapperRef, close);
  useEffect(() => {
    if (queryTimer.current !== null) {
      clearTimeout(queryTimer.current);
    }

    queryTimer.current = setTimeout(() => setQuery(""), 1500);
    return () => {
      if (queryTimer.current !== null) {
        clearTimeout(queryTimer.current);
      }
    };
  }, [query]);
  return (
    <Wrapper
      size={size}
      isOpen={open}
      className={className}
      ref={wrapperRef}
      id={id}
      openZIndex={openZIndex}
    >
      <Options
        isVisible={open}
        distanceFromBottom={distanceFromBottom}
        withBorder={withBorder}
        error={error}
        openZIndex={openZIndex}
      >
        {options.map((option, i) => (
          <Option
            type="button"
            value={i}
            key={i.toString()}
            onClick={handleOptionClick}
            onMouseEnter={handleOptionMouseEnter}
            onMouseLeave={handleOptionMouseLeave}
            isActive={i === activeOptionIndex}
            disabled={optionIsDisabled(option)}
            distanceFromBottom={distanceFromBottom}
          >
            <OptionLabel
              option={option}
              queryMatch={optionIndexMatchesQuery(i) ? query : undefined}
            />
          </Option>
        ))}
      </Options>

      <Selected
        type="button"
        tabIndex={0}
        isActive={open}
        isFocused={focus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={(event) => {
          // This is to prevent the onBlur from firing during scroll
          // Didn't usually happen, but it would under specific circumstances.
          event.preventDefault();
        }}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
        onClick={handleSelectedClick}
        ref={selectedOptionRef}
        withBorder={withBorder}
        error={error}
        disabled={disabled}
      >
        {selectedIndex !== null && !!selectedOption ? (
          <Flex>
            <OptionLabel
              option={selectedOption}
              queryMatch={
                optionIndexMatchesQuery(selectedIndex) ? query : undefined
              }
            />
            {!!SelectedOptionIcon ? <SelectedOptionIcon /> : null}
          </Flex>
        ) : (
          label || intl.formatMessage(messages.default)
        )}
      </Selected>
    </Wrapper>
  );
}

export default Dropdown;
