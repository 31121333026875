/**
 *
 * ThinlineKeypadIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ThinlineKeypadIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect y="0" fill="none" width="64" height="64" />
      <g>
        <path
          d="M60.4,6.5h-56C2.18,6.5,0,8.68,0,10.9v43c0,2.086,1.851,3.6,4.4,3.6h56c2.221,0,3.6-1.379,3.6-3.6v-43
      C64,8.351,62.486,6.5,60.4,6.5z M60,53.5H4.4c-0.156,0-0.298-0.02-0.405-0.046V10.996C4.044,10.832,4.332,10.539,4.4,10.5h55.555
      C59.98,10.607,60,10.744,60,10.9V53.5z"
        />
        <polygon points="27,19.5 27,23.5 55,23.5 55,19.5 55,15.5 27,15.5     " />
        <rect x="27" y="25.5" width="4" height="4" />
        <rect x="35" y="25.5" width="4" height="4" />
        <rect x="43" y="25.5" width="4" height="4" />
        <rect x="51" y="25.5" width="4" height="4" />
        <rect x="27" y="34.5" width="4" height="4" />
        <rect x="35" y="34.5" width="4" height="4" />
        <rect x="43" y="34.5" width="4" height="4" />
        <rect x="51" y="34.5" width="4" height="4" />
        <rect x="27" y="40.5" width="4" height="4" />
        <rect x="35" y="40.5" width="4" height="4" />
        <rect x="43" y="40.5" width="4" height="4" />
        <rect x="51" y="40.5" width="4" height="4" />
        <rect x="27" y="46.5" width="4" height="4" />
        <rect x="35" y="46.5" width="4" height="4" />
        <rect x="43" y="46.5" width="4" height="4" />
        <rect x="51" y="46.5" width="4" height="4" />
        <polygon points="14,46.5 14,50.5 22,50.5 22,46.5 22,42.5 14,42.5     " />
      </g>
    </g>
  </SvgIcon>
);

export default ThinlineKeypadIcon;
