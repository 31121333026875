/**
 *
 * FolderIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const FolderIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <rect fill="none" width="64" height="64" />
    <g transform="translate(0 -10)">
      <path
        d="M59.143,64H4.857C2.179,64,0,61.826,0,59.154V20.417C0,17.857,2.043,16,4.857,16h23.809C31.252,16,34,17.548,34,20.417V24
      h25.143C61.82,24,64,26.153,64,28.8v30.354C64,61.826,61.82,64,59.143,64z M4.857,20C4.537,20,4,20.054,4,20.417v38.737
      C4,59.621,4.385,60,4.857,60h54.286C59.615,60,60,59.621,60,59.154V28.8c0-0.456-0.368-0.8-0.857-0.8H32c-1.104,0-2-0.896-2-2
      v-5.583C29.923,20.293,29.37,20,28.666,20H4.857z"
      />
    </g>
  </SvgIcon>
);

export default FolderIcon;
