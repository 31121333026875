import { RadialAlertIcon } from "components/Icons";
import { BORDER_RADIUS } from "constants/index";
import { themeWarning } from "containers/Theme";
import React from "react";
import styled from "styled-components/macro";

const Root = styled.div`
  display: flex;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: ${BORDER_RADIUS};
  background-color: ${themeWarning};
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
`;
const Icon = styled(RadialAlertIcon)`
  flex-shrink: 0;
  margin-right: 0.5em;
  font-size: 1.35em;
  top: 0.1rem;
`;

function FieldValidationError({ children, ...rest }) {
  return (
    <Root {...rest}>
      <Icon />
      {children}
    </Root>
  );
}

export default FieldValidationError;
