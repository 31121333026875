import React from "react";
import SvgIcon from "common/components/web/Icons/SvgIcon";
const VIEW_SIZE = 64;

const EmptyPlaceholderIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <path d="" />
  </SvgIcon>
);

export default EmptyPlaceholderIcon;
