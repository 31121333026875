/**
 *
 * ArmedSolidShieldLargeIcon
 * @author Chad Watson
 *
 */

import React from "react";
import SvgIcon from "./SvgIcon";

const VIEW_SIZE = 64;

const ArmedSolidShieldLargeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>armed_solid_shield_large</title>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M57.8,7.8c-0.2-0.6-0.8-2.6-4.1-4.1C50.1,2,43.5,0,32.4,0C24,0,17.2,0.9,12.3,2.6C10.6,3.2,6.5,5,6.1,8.1
      C6,8.8,6,11,6,11.2v23.2c0,8.9,6.8,16.4,12.4,21.2c1.1,0.9,2.2,1.8,3.4,2.6l0.1,0.1l0,0c4.9,3.5,9.5,5.8,10.5,5.8
      c2,0,25.6-12.6,25.6-29.6V11.2C58.1,10.1,58,8.9,57.8,7.8z M13.3,10.1c0.7-0.6,4.7-2.9,19-2.9c11,0,16.7,1.8,18.4,2.9v23.6
      c0,6.2-5,12-9.2,15.6c-2.8,2.4-5.9,4.6-9.1,6.5c-2.2-1.2-19-10.9-19-22.1V10.1z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ArmedSolidShieldLargeIcon;
