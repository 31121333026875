/**
 *
 * ScrollObserver
 * @author Chad Watson
 *
 */

import getNodeDimensions from "get-node-dimensions";
import PropTypes from "prop-types";
import React from "react";
import ResizeObserver from "resize-observer-polyfill";
import { offsetTop } from "utils";

export const OBSERVE_POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
};

class ScrollObserver extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    scrollTarget: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    threshold: PropTypes.number,
    active: PropTypes.bool,
    observePosition: PropTypes.oneOf(Object.values(OBSERVE_POSITIONS)),
    onHasMetThreshold: PropTypes.func,
  };

  static defaultProps = {
    scrollTarget: window,
    threshold: 0,
    active: true,
    observePosition: OBSERVE_POSITIONS.TOP,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasMetThreshold: false,
      clientRect: {},
      dimensions: {},
    };

    this.resizeObserver = new ResizeObserver((entries) => {
      const dimensions = getNodeDimensions(entries[0].target);

      this.setState({
        top: offsetTop(entries[0].target),
        dimensions,
        clientRect: entries[0].contentRect,
      });
    });
  }

  componentDidMount() {
    if (this.props.active) {
      this.listen();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { scrollTarget, active } = this.props;

    if (
      nextProps.scrollTarget !== scrollTarget ||
      (!nextProps.active && active)
    ) {
      this.kill();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, scrollTarget, onHasMetThreshold } = this.props;
    const { hasMetThreshold } = this.state;

    if (
      (active && !prevProps.active) ||
      scrollTarget !== prevProps.scrollTarget
    ) {
      this.listen();
    }

    if (onHasMetThreshold && hasMetThreshold && !prevState.hasMetThreshold) {
      onHasMetThreshold();
    }
  }

  componentWillUnmount() {
    this.kill();
  }

  registerChild = (child) => {
    this.child = child;
  };

  handleScroll = () => requestAnimationFrame(this.measureAndUpdateState);

  measureAndUpdateState = () => {
    const { top, hasMetThreshold } = this.state;
    const { height } = this.state.dimensions;
    const { scrollTarget, threshold, observePosition } = this.props;
    const scrollTop =
      scrollTarget === window ? scrollTarget.scrollY : scrollTarget.scrollTop;
    const targetPosition =
      observePosition === OBSERVE_POSITIONS.BOTTOM ? top + height : top;

    if (scrollTop + threshold >= targetPosition && !hasMetThreshold) {
      this.setState({
        hasMetThreshold: true,
        dimensions: getNodeDimensions(this.child),
      });
      this.setState({
        hasMetThreshold: false,
      });
    } else if (scrollTop + threshold < targetPosition && hasMetThreshold) {
      this.setState({ hasMetThreshold: false });
    }
  };

  listen = () => {
    this.props.scrollTarget.addEventListener("scroll", this.handleScroll);
    this.resizeObserver.observe(this.child);
  };

  kill = () => {
    this.props.scrollTarget.removeEventListener("scroll", this.handleScroll);
    this.resizeObserver.disconnect();
  };

  render() {
    return this.props.children({
      ...this.state,
      registerChild: this.registerChild,
    });
  }
}

export default ScrollObserver;
