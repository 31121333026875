/**
 *
 * UploadIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const UploadIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <rect x="8" width="50" height="4" />
          <polygon points="6.709,34.45 9.3,37.507 31,19.188 31,64 35,64 35,19.22 56.564,37.504 59.146,34.453 32.973,12.271       " />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default UploadIcon;
