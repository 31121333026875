/**
 *
 * IdCardProfilesIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const IdCardProfilesIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M57.435,10H6.561C2.943,10,0,12.846,0,16.574v30.85C0,51.153,2.943,54,6.561,54h50.874C61.055,54,64,51.153,64,47.424
      v-30.85C64,12.846,61.055,10,57.435,10z M60,47.424C60,48.947,58.849,50,57.435,50H6.561C5.149,50,4,48.947,4,47.424v-30.85
      C4,15.052,5.149,14,6.561,14h50.874C58.849,14,60,15.052,60,16.574V47.424z"
        />
        <path
          d="M25.373,19H13.412C11.961,19,11,20.106,11,21.47v11.229C11,34.059,11.961,35,13.412,35h11.961
      C26.824,35,28,34.059,28,32.699V21.47C28,20.106,26.824,19,25.373,19z"
        />
        <rect x="37" y="20" width="17" height="4" />
        <rect x="37" y="30" width="17" height="4" />
        <rect x="37" y="40" width="17" height="4" />
      </g>
    </g>
  </SvgIcon>
);

export default IdCardProfilesIcon;
