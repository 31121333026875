import { TOP_BAR_HEIGHT } from "constants/index";
import { themeGrayLight } from "containers/Theme";
import styled, { css } from "styled-components/macro";
import { media } from "utils/styles";

export const SIZES = {
  SMALL: "small",
};

export const paddingXCss = css`
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.medium`
    padding-left: 2rem;
    padding-right: 2rem;
  `};
`;
export const paddingYCss = css`
  padding-top: 1rem;
  padding-bottom: 1rem;

  ${media.medium`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `};
`;

type Props = {
  centered?: boolean;
  fullHeightMax?: boolean;
  fullHeightMin?: boolean;
  paddingX?: boolean;
  paddingY?: boolean;
  size?: "small";
} & React.HTMLAttributes<HTMLDivElement>;

const PageContainer = styled.div<Props>`
  width: 100%;
  background: ${themeGrayLight};

  ${({ paddingX }) => paddingX && paddingXCss}
  ${({ paddingY }) => paddingY && paddingYCss}

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${({ size }) =>
    size &&
    css`
      max-width: ${size === "small" ? "600px" : "none"};
      margin-left: auto;
      margin-right: auto;
    `}

  ${({ fullHeightMax }) =>
    fullHeightMax &&
    css`
      height: calc(100vh - ${TOP_BAR_HEIGHT}px);
      max-height: calc(100vh - ${TOP_BAR_HEIGHT}px);
    `};

  ${({ fullHeightMin }) =>
    fullHeightMin &&
    css`
      height: calc(100vh - ${TOP_BAR_HEIGHT}px);
      min-height: calc(100vh - ${TOP_BAR_HEIGHT}px);
    `};
`;

PageContainer.defaultProps = {
  paddingX: true,
  paddingY: true,
};

export default PageContainer;
