/**
 *
 * SearchLeftIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SearchLeftIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M63.613,58.659L36.124,33.127c2.956-3.61,4.565-8.088,4.565-12.814c0.001-5.427-2.116-10.527-5.961-14.364
    C30.885,2.113,25.775,0,20.341,0C14.908,0,9.801,2.112,5.963,5.945C2.118,9.779,0,14.879,0,20.303
    c-0.001,5.426,2.117,10.527,5.962,14.363c3.841,3.834,8.949,5.945,14.384,5.945c4.784,0,9.315-1.641,12.953-4.648l27.592,25.628
    L63.613,58.659z M20.346,36.611c-4.368,0-8.473-1.696-11.559-4.775C5.699,28.754,3.999,24.659,4,20.304
    c0-4.354,1.7-8.448,4.788-11.527C11.872,5.696,15.976,4,20.341,4c4.368,0,8.474,1.697,11.562,4.78
    c3.088,3.08,4.787,7.176,4.787,11.532c0,4.354-1.699,8.445-4.783,11.523C28.818,34.915,24.713,36.611,20.346,36.611z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default SearchLeftIcon;
