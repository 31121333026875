/**
 *
 * SectionHeader Messages
 * @author Chad Watson
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  add: {
    id: "app.components.SectionHeader.add",
    defaultMessage: "Add"
  },
  refreshButtonLabel: {
    id: "app.containers.RefreshButton.refreshButtonLabel",
    defaultMessage: "Refresh"
  },
  backButtonLabel: {
    id: "app.containers.RefreshButton.backButtonLabel",
    defaultMessage: "Back"
  }
});
