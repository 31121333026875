/**
 *
 * PauseIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PauseIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect x="0.416" fill="none" width="63.584" height="63.742" />
      <rect x="0.416" fill="none" width="63.584" height="63.742" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M16.436,62.504c-1.104,0-2-0.896-2-2V3.496c0-1.104,0.896-2,2-2s2,0.896,2,2v57.008
        C18.436,61.608,17.54,62.504,16.436,62.504z"
          />
        </g>
        <g>
          <path
            d="M47.564,62.504c-1.104,0-2-0.896-2-2V3.496c0-1.104,0.896-2,2-2s2,0.896,2,2v57.008
        C49.564,61.608,48.669,62.504,47.564,62.504z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default PauseIcon;
