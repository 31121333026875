/**
 *
 * FadeInOut
 * @author Chad Watson
 *
 */

import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components/macro";
import { fadeIn } from "utils/styles";

export const fadeAnimation = css`
  0.35s 1 ease-in-out ${fadeIn};
`;

const Wrapper = styled.div`
  animation: ${fadeAnimation};
`;

const FadeInOut = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
);

FadeInOut.propTypes = {
  children: PropTypes.node,
};

export default FadeInOut;
