/**
 *
 * ClipIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ClipIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M60,0v5h-7V0h-4v5H15V0h-4v5H4V0H0v64h4v-5h7v5h4v-5h34v5h4v-5h7v5h4V0H60z M60,9v6h-7V9H60z M60,35h-7v-6
    h7V35z M53,39h7v6h-7V39z M60,25h-7v-6h7V25z M49,9v21H15V9H49z M11,9v6H4V9H11z M11,35H4v-6h7V35z M4,39h7v6H4V39z M11,25H4v-6h7
    V25z M4,55v-6h7v6H4z M15,55V34h34v21H15z M53,55v-6h7v6H53z"
      />
    </g>
  </SvgIcon>
);

export default ClipIcon;
