/**
 *
 * FavoriteAnim4Icon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const FavoriteAnim4Icon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M63.758,24.362c-0.674-2.059-2.577-3.36-4.735-3.36H41.994L36.739,4.755C36.098,2.804,34.238,1.483,32,1.483
    c-2.24,0-4.102,1.305-4.743,3.266l-5.249,16.253H4.981c-2.158,0-4.061,1.302-4.737,3.368c-0.666,2.061,0.062,4.219,1.812,5.486
    l13.771,9.998l-5.264,16.172c-0.672,2.047,0.053,4.285,1.811,5.576c1.716,1.244,4.146,1.242,5.854-0.002L32,51.603l13.775,10.002
    c0.863,0.623,1.875,0.953,2.926,0.953c1.047,0,2.057-0.324,2.923-0.951c1.753-1.272,2.481-3.504,1.812-5.561L48.18,39.874
    l13.771-9.979C63.701,28.627,64.428,26.429,63.758,24.362z M51.134,28.871l-11.727,8.512l4.475,13.775
    c0.013,0.04-0.006,0.094-0.018,0.102c-0.037,0.026-0.069,0.033-0.078,0.033v2l-0.063-2.021l-11.721-8.515L20.28,51.272l1.175,1.618
    l-1.29-1.61c-0.041-0.03-0.062-0.08-0.047-0.126l4.482-13.771l-11.727-8.515c-0.033-0.023-0.048-0.069-0.033-0.113
    c0.014-0.042,0.052-0.07,0.095-0.07h14.493l4.44-13.675c0.063-0.017,0.201-0.018,0.268,0.002l4.438,13.673h14.496
    c0.043,0,0.081,0.029,0.094,0.067C51.179,28.796,51.164,28.849,51.134,28.871z M32,5.483c0.379,0,0.835,0.185,0.937,0.494
    l1.982,6.129c-0.741-0.702-1.771-1.111-2.919-1.111c-1.147,0-2.173,0.408-2.912,1.109l1.973-6.098
    C31.181,5.64,31.707,5.483,32,5.483z M4.047,25.608c0.135-0.41,0.511-0.606,0.935-0.606h6.405
    c-1.091,0.444-1.966,1.339-2.347,2.508c-0.275,0.833-0.268,1.7-0.022,2.492l-4.612-3.343C4.061,26.409,3.918,26.007,4.047,25.608z
     M15.876,58.368c-0.52,0.377-0.873,0.205-1.146,0.009c-0.351-0.259-0.498-0.694-0.365-1.099l1.792-5.504
    c0.155,1.065,0.728,2.057,1.648,2.735c0.685,0.496,1.507,0.747,2.334,0.763L15.876,58.368z M49.633,57.274
    c0.129,0.395-0.02,0.844-0.355,1.088c-0.36,0.26-0.813,0.25-1.156,0.002l-4.242-3.08c0.812-0.02,1.627-0.285,2.327-0.782
    c0.916-0.674,1.485-1.668,1.637-2.739L49.633,57.274z M59.602,26.62l-4.606,3.344c0.232-0.779,0.238-1.63-0.027-2.45
    c-0.384-1.173-1.26-2.067-2.349-2.512h6.403c0.425,0,0.8,0.196,0.933,0.599C60.084,26.003,59.939,26.374,59.602,26.62z"
      />
    </g>
  </SvgIcon>
);

export default FavoriteAnim4Icon;
