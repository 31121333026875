/**
 *
 * ControlRightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ControlRightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <g>
            <polygon points="20.276,63.997 17.197,61.444 41.607,32.001 17.197,2.556 20.276,0.003 46.803,32.001         " />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ControlRightIcon;
