/**
 *
 * SecondaryButton
 * @author Chad Watson
 */

import React, { useCallback } from "react";
import { ThemeProvider } from "styled-components/macro";
import { UnthemedButton } from "./index";

const SecondaryButton = (props) => (
  <ThemeProvider
    theme={useCallback(
      (currentTheme) => ({
        ...currentTheme,
        color: currentTheme.primary500,
        opposedColor: currentTheme.trueWhite,
      }),
      []
    )}
  >
    <UnthemedButton {...props} />
  </ThemeProvider>
);

export default SecondaryButton;
