/**
 *
 * ArmingShieldNewOutlineIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ArmingShieldNewOutlineIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M32.316,64h-0.046C29.235,63.93,7,51.233,7,34.286V12.229c0.012-0.323,0.104-2.438,0.2-3.221
      c0.155-1.246,1.111-4.367,6.626-6.432C18.387,0.868,24.604,0.002,32.308,0l0,0l0,0l0,0c0.003,0,0.006,0,0.009,0
      c0,0,0.001,0,0.002,0l0,0c7.707,0,13.927,0.867,18.488,2.576c5.515,2.064,6.471,5.186,6.625,6.43
      c0.098,0.785,0.189,2.899,0.199,3.138l0.002,22.143c0,16.947-22.235,29.644-25.271,29.714H32.316z M32.316,4
      C25.09,4,19.341,4.781,15.229,6.322c-3.734,1.398-4.036,3-4.059,3.178c-0.058,0.47-0.136,1.975-0.172,2.813L11,34.286
      c0,13.319,17.91,24.384,21.316,25.648c3.406-1.265,21.316-12.329,21.316-25.648V12.229c-0.034-0.754-0.112-2.26-0.17-2.729
      c-0.022-0.177-0.324-1.778-4.059-3.177C45.292,4.781,39.543,4,32.316,4z"
        />
      </g>
      <g>
        <path
          d="M32.325,56.327l-0.927-0.484C30.688,55.471,14,46.598,14,33.668V11.586l0.165-0.381c0.686-1.582,3.956-4.236,18.16-4.236
      c14.219,0,17.489,2.654,18.175,4.236l0.165,0.381v22.082c0,12.93-16.703,21.804-17.414,22.175L32.325,56.327z M18,12.89v20.778
      c0,8.973,11.017,16.15,14.325,18.104c3.312-1.952,14.34-9.132,14.34-18.104V12.89c-1.018-0.57-4.47-1.921-14.325-1.921
      C22.472,10.969,19.018,12.32,18,12.89z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ArmingShieldNewOutlineIcon;
