import styled from "styled-components/macro";

const CssIcon = styled.i`
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 1em;
  color: currentColor;
  line-height: inherit;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

export default CssIcon;
