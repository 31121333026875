/**
 *
 * LockUnlockedIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LockUnlockedIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M51.318,0H41.194C36.879,0,34,3.378,34,8.405V26H12.366C7.753,26,4,29.944,4,34.578v21.018C4,60.23,7.753,64,12.366,64
      h26.336C43.313,64,47,60.23,47,55.596V34.578C47,29.944,43.313,26,38.702,26H38V8.405C38,7.081,38.294,4,41.194,4h10.124
      C53.848,4,55,6.284,55,8.405V28h4V8.405C59,3.613,55.697,0,51.318,0z M38.702,30C41.108,30,43,32.149,43,34.578v21.018
      C43,58.024,41.108,60,38.702,60H12.366C9.958,60,8,58.024,8,55.596V34.578C8,32.149,9.958,30,12.366,30H38.702z"
        />
        <path
          d="M24,46.684v5.371C24,53.143,24.73,54,25.817,54h0.062c1.087,0,2.12-0.857,2.12-1.945v-5.371
      c2-0.782,3.112-2.629,3.112-4.785c0-2.865-2.36-5.188-5.225-5.188s-5.131,2.322-5.131,5.188C20.756,44.055,22,45.901,24,46.684z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default LockUnlockedIcon;
