import { Z_INDEX } from "common/utils/styles";
import { elevation, useTheme } from "containers/Theme";
import { useCurrentScrollElement } from "contexts/ScrollElementContext";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import useWindowDimensions from "hooks/use-window-dimensions";
import useLockedValue from "hooks/useLockedValue";
import { usePortal } from "hooks/usePortal";
import { always, contains, flip, isNil, path, prop } from "ramda";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Motion, spring } from "react-motion";
import ResizeObserver from "resize-observer-polyfill";
import styled, { ThemeProvider, css } from "styled-components/macro";
import { noop, offsetTop } from "utils";

export { default as TooltipErrorMessage } from "./ErrorMessage";

export const POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};

const TooltipPortal = ({ children }) => {
  return usePortal(children);
};

const MESSAGE_OFFSET_IN_PX = 10;
const CARET_WIDTH = 16;

const inList = flip(contains);
const isXPosition = inList([POSITIONS.LEFT, POSITIONS.RIGHT]);
const isYPosition = inList([POSITIONS.TOP, POSITIONS.BOTTOM]);

const springConfig = always({
  stiffness: 377,
  damping: 28,
});

const getMessageOffsetX = ({
  anchorDimensions,
  messagePosition,
  windowWidth,
  messageWidth,
}) => {
  if (isXPosition(messagePosition)) {
    return 0;
  }

  const anchorWidth = anchorDimensions.width || 0;

  if ((!anchorDimensions.left && !anchorWidth) || !messageWidth) {
    return 0;
  }

  const anchorMiddle = anchorDimensions.left + anchorWidth / 2;
  const messageLeft = anchorMiddle - messageWidth / 2;
  const messageRight = anchorMiddle + messageWidth / 2;
  const gutterLeft = 5;
  const gutterRight = windowWidth - 5;

  if (messageLeft < gutterLeft) {
    return gutterLeft - messageLeft;
  }

  if (messageRight > gutterRight) {
    return gutterRight - messageRight;
  }

  return 0;
};

const createTheme = (themeName) => (theme) => {
  switch (themeName) {
    case "dark":
      return {
        ...theme,
        padding: 0,
        backgroundColor: theme.grayDark,
        textColor: "white",
        borderColor: "none",
        borderWidth: "0",
        caretFill: theme.grayDark,
        caretStrokeColor: undefined,
        caretStrokeWidth: undefined,
      };
    case "warning":
      return {
        ...theme,
        padding: "1rem 2rem 1rem 1.5rem",
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.warning,
        borderWidth: "8px 1px 1px",
        caretFill: theme.warning,
        caretStrokeColor: undefined,
        caretStrokeWidth: undefined,
      };
    case "error":
      return {
        ...theme,
        padding: "1rem 2rem 1rem 1.5rem",
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.red600,
        borderWidth: "8px 1px 1px",
        caretFill: theme.red600,
        caretStrokeColor: undefined,
        caretStrokeWidth: undefined,
        fontWeight: 600,
        lineHeight: 1.2,
      };
    default:
      return {
        ...theme,
        padding: 0,
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.panelBorderColor,
        borderWidth: "1px",
        caretFill: "white",
        caretStrokeColor: theme.panelBorderColor,
        caretStrokeWidth: 5,
      };
  }
};

const getScrollPosition = (scrollTarget) =>
  scrollTarget === window
    ? {
        x: scrollTarget.scrollX,
        y: scrollTarget.scrollY,
      }
    : {
        x: scrollTarget.scrollLeft,
        y: scrollTarget.scrollTop,
      };

/** @param {{
 * id?:string,
  children: React.ReactNode,
  anchor: (ref: any) => JSX.Element,
  maxHeight?: number | string,
  animated?: boolean,
  open?: boolean,
  dark?: boolean,
  style?: React.AllHTMLAttributes["style"],
  onClose?: () => {},
  anchorX?: number,
  anchorY?: number,
  position?: "top" | "bottom" | "left" | "right",
  className?: string,
  onDismiss?: () => void,
  theme?: "dark" | "warning" | "error",
  zIndex?: index,
}} props */
const Tooltip = ({
  children,
  anchor,
  maxHeight = 1000,
  animated = true,
  open = false,
  dark = false,
  style = {},
  onClose = noop,
  anchorX,
  anchorY,
  position,
  className,
  onDismiss,
  theme,
  zIndex = Z_INDEX.TOOLTIP,
  dismissOnScroll,
}) => {
  const scrollTarget = useCurrentScrollElement();
  const themeContext = useTheme();
  const messageTheme = useMemo(
    () => createTheme(dark ? "dark" : theme)(themeContext),
    [dark, theme, themeContext]
  );
  const anchorRef = useRef();
  const messageRef = useRef();
  const windowDimensions = useWindowDimensions();
  const [messageDimensions, setMessageDimensions] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [anchorDimensions, setAnchorDimensions] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [animating, setAnimating] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(
    getScrollPosition(scrollTarget)
  );
  const cachedChildren = useLockedValue(children, !open);

  const bottomWouldOverflow =
    anchorDimensions.top +
      anchorDimensions.height +
      messageDimensions.height +
      MESSAGE_OFFSET_IN_PX >
    windowDimensions.height + scrollPosition.y;

  const topWouldOverflow =
    anchorDimensions.top - messageDimensions.height - MESSAGE_OFFSET_IN_PX < 0;

  const leftWouldOverflow =
    anchorDimensions.left - messageDimensions.width - MESSAGE_OFFSET_IN_PX < 0;

  const rightWouldOverflow =
    anchorDimensions.left +
      anchorDimensions.width +
      messageDimensions.width +
      MESSAGE_OFFSET_IN_PX >
    windowDimensions.width;

  const messagePosition =
    position ||
    (!bottomWouldOverflow
      ? POSITIONS.BOTTOM
      : !topWouldOverflow
      ? POSITIONS.TOP
      : !leftWouldOverflow
      ? POSITIONS.LEFT
      : !rightWouldOverflow
      ? POSITIONS.RIGHT
      : POSITIONS.BOTTOM);

  const normalizeAnchorBoundingClientRect = useCallback(
    (rect) => {
      const top = !isNil(anchorY) ? anchorY : rect.top;
      return {
        top,
        right: rect.right,
        bottom: top + rect.height,
        left: !isNil(anchorX) ? anchorX : rect.left,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
      };
    },
    [anchorX, anchorY]
  );

  useLayoutEffect(() => {
    if (open && anchorRef.current) {
      setAnchorDimensions({
        ...normalizeAnchorBoundingClientRect(
          anchorRef.current.getBoundingClientRect()
        ),
        offsetTop: offsetTop(anchorRef.current),
      });
    }
    if (open && messageRef.current) {
      setMessageDimensions(messageRef.current.getBoundingClientRect());
    }
  }, [anchorX, anchorY, normalizeAnchorBoundingClientRect, open]);

  useOnClickOutside(
    messageRef,
    (event) => {
      if (onDismiss && open && !anchorRef.current?.contains(event.target)) {
        onDismiss(event);
      }
    },
    open
  );

  useLayoutEffect(() => {
    if (open) {
      setScrollPosition(getScrollPosition(scrollTarget));
    }
  }, [scrollTarget, open]);

  useEffect(() => {
    if (open) {
      const handleScroll = () => {
        if (dismissOnScroll && onDismiss) {
          onDismiss();
        } else {
          setScrollPosition(getScrollPosition(scrollTarget));
          if (anchorRef.current) {
            setAnchorDimensions({
              ...normalizeAnchorBoundingClientRect(
                anchorRef.current.getBoundingClientRect()
              ),
              offsetTop: offsetTop(anchorRef.current),
            });
          }
        }
      };

      const handleResize = () => {
        if (anchorRef.current) {
          setAnchorDimensions({
            ...normalizeAnchorBoundingClientRect(
              anchorRef.current.getBoundingClientRect()
            ),
            offsetTop: offsetTop(anchorRef.current),
          });
        }
      };

      const resizeObserver = new ResizeObserver(([{ target }]) => {
        setAnchorDimensions({
          ...normalizeAnchorBoundingClientRect(target.getBoundingClientRect()),
          offsetTop: offsetTop(target),
        });
      });
      if (anchorRef.current) {
        resizeObserver.observe(anchorRef.current);
      }

      scrollTarget.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      return () => {
        resizeObserver.disconnect();
        scrollTarget.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [
    anchorX,
    anchorY,
    normalizeAnchorBoundingClientRect,
    open,
    scrollTarget,
    dismissOnScroll,
    onDismiss,
  ]);

  return (
    <Root className={className}>
      {anchor({ registerAnchor: anchorRef })}
      <ThemeProvider theme={messageTheme}>
        <Motion
          defaultStyle={{
            offset: 0,
            opacity: 0,
          }}
          style={
            open
              ? {
                  offset: animated
                    ? spring(MESSAGE_OFFSET_IN_PX, springConfig())
                    : MESSAGE_OFFSET_IN_PX,
                  opacity: animated ? spring(1, springConfig()) : 1,
                }
              : {
                  offset: animated ? spring(0, springConfig()) : 0,
                  opacity: animated ? spring(0, springConfig()) : 0,
                }
          }
          onRest={() => {
            setAnimating(false);
            onClose();
          }}
        >
          {({ offset, opacity }) => (
            <TooltipPortal>
              <MessageWrapper
                zIndex={zIndex}
                anchorDimensions={anchorDimensions}
                animating={animating}
                fixed={false} // This flag has been deprecated
                ref={messageRef}
                isOpen={open}
                position={messagePosition}
                style={{
                  ...style,
                  opacity,
                  transform:
                    messagePosition === POSITIONS.LEFT
                      ? `translate(calc(-100% - ${offset}px), -50%)`
                      : messagePosition === POSITIONS.RIGHT
                      ? `translate(${offset}px, -50%)`
                      : messagePosition === POSITIONS.TOP
                      ? `translate(-50%, calc(-100% - ${offset}px))`
                      : `translate(-50%, ${offset}px)`,
                }}
              >
                <Caret
                  viewBox={
                    isXPosition(messagePosition) ? "0 0 40 80" : "0 0 80 40"
                  }
                  position={messagePosition}
                >
                  <path
                    fill={messageTheme.caretFill}
                    stroke={messageTheme.caretStrokeColor}
                    strokeWidth={messageTheme.caretStrokeWidth}
                    d={
                      isXPosition(messagePosition)
                        ? "M5 5 L40 40 L5 75"
                        : "M5 40 L40 5 L75 40"
                    }
                  />
                </Caret>
                <MessageContent
                  maxHeight={maxHeight}
                  closed={!open}
                  offsetX={getMessageOffsetX({
                    anchorDimensions,
                    messagePosition,
                    windowWidth: windowDimensions.width,
                    messageWidth: messageDimensions.width,
                  })}
                >
                  {cachedChildren}
                </MessageContent>
              </MessageWrapper>
            </TooltipPortal>
          )}
        </Motion>
      </ThemeProvider>
    </Root>
  );
};

Tooltip.borderRadius = "6px";

export default Tooltip;

const Root = styled.div`
  position: relative;
  line-height: 1;
  width: inherit;
`;

const MessageWrapper = styled.div`
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  top: ${({ anchorDimensions, position }) =>
    isXPosition(position)
      ? anchorDimensions.offsetTop + anchorDimensions.height / 2
      : position === POSITIONS.BOTTOM
      ? anchorDimensions.offsetTop + anchorDimensions.height
      : anchorDimensions.offsetTop}px;
  left: ${({ anchorDimensions, position }) =>
    isYPosition(position)
      ? anchorDimensions.left + anchorDimensions.width / 2
      : position === POSITIONS.RIGHT
      ? anchorDimensions.right
      : anchorDimensions.left}px;
  visibility: ${({ isOpen, animating }) =>
    isOpen || animating ? "visible" : "hidden"};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  color: ${path(["theme", "textColor"])};
  z-index: ${prop("zIndex")};
`;

const MessageContent = styled.div`
  position: relative;
  z-index: inherit;
  max-height: ${({ maxHeight }) =>
    typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight};
  padding: ${path(["theme", "padding"])};
  transform: translateX(${prop("offsetX")}px);
  border-radius: ${Tooltip.borderRadius};
  box-shadow: ${elevation(300)};
  font-size: 0.875rem;
  background: ${path(["theme", "backgroundColor"])};
  border-color: ${path(["theme", "borderColor"])};
  border-width: ${path(["theme", "borderWidth"])};
  border-style: solid;
  ${({ closed }) =>
    closed &&
    css`
      & * {
        pointer-events: none;
      }
    `};
`;
const Caret = styled.svg`
  position: absolute;
  font-size: ${CARET_WIDTH}px;
  z-index: 1;

  ${({ position }) => {
    if (position === POSITIONS.BOTTOM) {
      return css`
        top: 1px;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 1em;
        height: 0.5em;
      `;
    }
    if (position === POSITIONS.TOP) {
      return css`
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 100%) rotate(180deg);
        width: 1em;
        height: 0.5em;
      `;
    }
    if (position === POSITIONS.LEFT) {
      return css`
        right: 2px;
        top: 50%;
        transform: translate(100%, -50%);
        width: 0.5em;
        height: 1em;
      `;
    }

    return css`
      left: 2px;
      top: 50%;
      transform: translate(-100%, -50%) rotate(180deg);
      width: 0.5em;
      height: 1em;
    `;
  }};
`;
