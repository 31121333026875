/**
 *
 * ResetButton
 * @author Chad Watson
 *
 */

import { UnthemedButton } from "components/Button";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components/macro";
import messages from "./messages";

const ResetButton = ({ children, ...props }) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.grayMedium,
      opposingColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton {...props} size="small" variant="outline">
      {children || <FormattedMessage {...messages.reset} />}
    </UnthemedButton>
  </ThemeProvider>
);

ResetButton.propTypes = {
  children: PropTypes.node,
};

export default ResetButton;
