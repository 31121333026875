/**
 *
 * MenuIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const MenuIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <rect y="30" width="64" height="4" />
          <rect y="10" width="64" height="4" />
          <rect y="50" width="64" height="4" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default MenuIcon;
