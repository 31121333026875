/**
 *
 * A React Component for rendering a pretty dropdown option label
 * @author: Chad Watson
 *
 */

import { themeHighlightColor } from "containers/Theme";
import React from "react";
import styled from "styled-components/macro";

export type LabeledValueDropdownOption<A> = {
  label: string;
  value: A;
  disabled?: boolean;
  infoIcon?: () => JSX.Element;
};

export type StringDropdownOption<A extends string> = A;

export type DropdownOption<Value> =
  | LabeledValueDropdownOption<Value>
  | StringDropdownOption<Value extends string ? Value : never>;

export const dropdownOptionIsStringDropdownOption = <A extends any>(
  option: DropdownOption<A>
): option is StringDropdownOption<A extends string ? A : never> =>
  typeof option === "string";

function OptionLabel<Value>({
  option,
  queryMatch = "",
}: {
  option: DropdownOption<Value>;
  queryMatch?: string;
}) {
  const label = typeof option === "string" ? option : option.label;

  if (queryMatch) {
    return (
      <Label>
        <QueryHighlight>{label.slice(0, queryMatch.length)}</QueryHighlight>
        {label.slice(queryMatch.length)}
      </Label>
    );
  }

  return <Label>{label}</Label>;
}

export default OptionLabel;

export const QueryHighlight = styled.span`
  background: ${themeHighlightColor};
`;
const Label = styled.span`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  line-height: 1.2;
  vertical-align: middle;
`;
