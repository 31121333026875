/**
 *
 * DataIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DataIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <g>
            <path
              d="M43.072,54.49c-0.084,0-0.169-0.005-0.254-0.016c-0.793-0.102-1.45-0.664-1.67-1.434L31.12,18.004l-8.695,24.049
          c-0.265,0.732-0.931,1.244-1.707,1.312c-0.77,0.072-1.521-0.32-1.908-0.996l-3.577-6.229l-4.231,8.709
          C10.666,45.537,9.968,46,9.203,46H2c-1.104,0-2-0.896-2-2c0-1.105,0.896-2,2-2h5.953l5.337-10.978
          c0.324-0.666,0.99-1.111,1.73-1.136c0.736-0.024,1.434,0.355,1.802,0.996l3.308,5.757l9.331-25.807
          c0.295-0.815,1.046-1.356,1.949-1.319c0.867,0.029,1.616,0.614,1.854,1.448l10.447,36.5l6.778-11.516
          c0.369-0.627,1.05-1.006,1.779-0.984c0.728,0.021,1.387,0.461,1.722,1.108L57.04,42H62c1.104,0,2,0.895,2,2c0,1.104-0.896,2-2,2
          h-6.178c-0.748,0-1.434-0.444-1.777-1.108L52.1,41.111l-7.305,12.402C44.433,54.128,43.773,54.49,43.072,54.49z"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DataIcon;
