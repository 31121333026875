/**
 *
 * CubeCameraIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CubeCameraIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M51,60H39v-3.78L37.636,51H45c3.309,0,6-2.691,6-6V6c0-3.309-2.691-6-6-6H18c-3.309,0-6,2.691-6,6v39
        c0,3.309,2.691,6,6,6h8.364L25,56.22V60H12c-1.104,0-2,0.896-2,2s0.896,2,2,2h39c1.104,0,2-0.896,2-2S52.104,60,51,60z M18,4h27
        c1.103,0,2,0.897,2,2v22h-4.992c-0.379,0-0.751,0.107-1.071,0.312l-4.445,2.819c-1.058-1.474-2.782-2.438-4.73-2.438
        c-1.855,0-3.507,0.875-4.575,2.23l-4.114-2.611C22.751,28.108,22.379,28,22,28h-6V6C16,4.897,16.897,4,18,4z M16,45V32h5.419
        l4.534,2.878c0.188,3.046,2.717,5.467,5.808,5.467c2.982,0,5.444-2.253,5.783-5.146L42.588,32H47v13c0,1.103-0.897,2-2,2H18
        C16.897,47,16,46.103,16,45z M29,56.733L30.499,51h3.003L35,56.733V60h-6V56.733z"
          />
          <path
            d="M32,25.473c4.939,0,8.958-4.019,8.958-8.958S36.939,7.557,32,7.557s-8.958,4.019-8.958,8.958
        S27.061,25.473,32,25.473z M32,11.557c2.734,0,4.958,2.225,4.958,4.958S34.734,21.473,32,21.473s-4.958-2.225-4.958-4.958
        S29.266,11.557,32,11.557z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default CubeCameraIcon;
