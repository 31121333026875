/**
 *
 * Styled React Components for Dropdown
 * @author: Chad Watson
 *
 */

import { Z_INDEX } from "common/utils/styles";
import NakedButton from "components/NakedButton";
import { BORDER_RADIUS } from "constants/index";
import {
  themeGrayAccent,
  themeGrayLight,
  themeSecondary,
} from "containers/Theme";
import styled, { css } from "styled-components/macro";

export const sizes = {
  default: "1em",
  small: "0.875em",
};

export type DropdownSize = keyof typeof sizes;

const caretSize = 0.375; // em
const dropdownPaddingX = 0.8; // em
const selectedHeight = 2; // em

const OPTIONS_MAX_HEIGHT = 250;

const selectedSizeStyles = css`
  padding: 0 calc(${dropdownPaddingX * 2}em + 5px) 0 ${dropdownPaddingX}em;
  text-align: left;
`;
const selectedBorderStyles = css<{ error?: boolean }>`
  border-radius: inherit;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.failure : theme.grayAccent)};
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  size: DropdownSize;
  openZIndex: number;
}>`
  position: relative;
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  font-size: ${({ size }) => sizes[size]};

  ${({ isOpen, openZIndex }) =>
    isOpen &&
    css`
      z-index: ${openZIndex};
    `};
`;

export const Selected = styled.button<{
  withBorder?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  isFocused?: boolean;
  error?: boolean;
}>`
  ${selectedSizeStyles};
  ${({ withBorder }) => withBorder && selectedBorderStyles};
  position: relative;
  display: block;
  width: 100%;
  min-height: calc(${selectedHeight}em + 2px);
  background: ${({ disabled, theme }) =>
    disabled ? theme.grayXlight : "white"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: ${dropdownPaddingX}em;
    top: 50%;
    width: 0;
    height: 0;
    border-left: ${caretSize}em solid transparent;
    border-right: ${caretSize}em solid transparent;
    border-top: ${caretSize}em solid ${themeGrayAccent};
    transform: translateY(-50%);
    transition: 250ms border-top-color;
  }

  &:enabled:hover:after {
    border-top-color: ${themeSecondary};
  }

  &:focus {
    outline: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    `};
  ${({ isFocused, isActive, theme }) =>
    isFocused &&
    !isActive &&
    css`
      box-shadow: ${theme.elevation300};
    `};
`;

export const Options = styled.div<{
  withBorder?: boolean;
  isVisible?: boolean;
  distanceFromBottom: number;
  error?: boolean;
  openZIndex?: number;
}>`
  ${({ withBorder }) => withBorder && selectedBorderStyles};
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: ${OPTIONS_MAX_HEIGHT}px;
  overflow-y: auto;
  height: auto;
  border-radius: inherit;
  background: white;
  z-index: ${({ openZIndex }) => openZIndex || Z_INDEX.BACKDROP};

  ${({ isVisible, theme }) =>
    isVisible &&
    css`
      display: block;
      box-shadow: ${theme.elevation300};
    `};
  ${({ distanceFromBottom }) =>
    distanceFromBottom < OPTIONS_MAX_HEIGHT
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
          padding-top: ${selectedHeight}em;
        `};
`;

export const Option = styled(NakedButton)<{
  isActive?: boolean;
  distanceFromBottom: number;
}>`
  ${selectedSizeStyles};
  display: block;
  width: 100%;
  min-height: ${selectedHeight}em;
  padding-right: 0;

  &:first-child {
    padding-top: 5px;
  }

  &:last-child {
    margin-bottom: ${({ distanceFromBottom }) =>
      distanceFromBottom < OPTIONS_MAX_HEIGHT ? "2em" : 0};
  }

  &[disabled] {
    color: ${themeGrayAccent};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${themeGrayLight};
    `};
`;
