/**
 *
 * AppLoading
 * @author Chad Watson
 *
 *
 */
import LoadingSpinner from "components/LoadingSpinner";
import DefaultVirtualKeypadLogo from "components/VirtualKeypadLogo";
import Maybe from "data.maybe";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { parseLocationSearch } from "utils";
const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px 20px 60px;
  text-align: center;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  max-height: 160px;
  margin-bottom: 10px;
`;
const LogoImg = styled.img`
  display: block;
  max-width: 100%;
`;
const VirtualKeypadLogo = styled(DefaultVirtualKeypadLogo)`
  width: 100%;
`;
const LoadingIndicator = styled(LoadingSpinner)`
  font-size: 40px;
`;

const AppLoading = ({ location }) => {
  const searchParams = parseLocationSearch(location);
  return (
    <Root>
      <LogoContainer>
        {Maybe.fromNullable(searchParams.logo_url)
          .chain((src) => (!src ? Maybe.Nothing() : Maybe.of(src)))
          .map((src) => (
            <LogoImg key="logo" src={src} alt={searchParams.app_name} />
          ))
          .getOrElse(<VirtualKeypadLogo type="dark" />)}
      </LogoContainer>
      <LoadingIndicator />
    </Root>
  );
};

export default withRouter(AppLoading);
