/**
 *
 * ConfirmModal Messages
 * @author Tyler Torres
 *
 * This contains all the text for the ConfirmModal component.
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  cancel: {
    id: "app.components.ConfirmModal.cancel",
    defaultMessage: "Cancel"
  },
  confirm: {
    id: "app.components.ConfirmModal.confirm",
    defaultMessage: "Confirm"
  },
  defaultMessage: {
    id: "app.components.ConfirmModal.defaultMessage",
    defaultMessage: "Are you sure?"
  }
});
