/**
 *
 * FullHeightMinWrapper
 * @author Chad Watson
 *
 */

import styled from "styled-components/macro";

const FullHeightMinWrapper = styled.div`
  height: 100%;
  min-height: 100%;
`;

export default FullHeightMinWrapper;
