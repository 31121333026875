/**
 *
 * ButtonWithIcon
 * @author Chad Watson
 *
 *
 */
import * as React from "react";
import styled from "styled-components/macro";
import { UnthemedButton } from "./index";
const Span = styled.span`
  line-height: 1;
`;
export const InnerWrapper = styled(Span)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconWrapper = styled(Span)`
  margin-right: 0.6em;
  & svg {
    display: block;
  }
`;
const ButtonWithIcon = React.forwardRef(({ icon, children, ...rest }, ref) => (
  <UnthemedButton {...rest} ref={ref}>
    <InnerWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Span>{children}</Span>
    </InnerWrapper>
  </UnthemedButton>
));
export default ButtonWithIcon;
