/**
 *
 * BackButton
 * @author Chad Watson
 *
 */

import { ControlLeftIcon } from "components/Icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { media } from "utils/styles";
import ActionButton from "./ActionButton";
import messages from "./messages";

const Button = styled(ActionButton)`
  margin-right: 5px;
`;
const Icon = styled(ControlLeftIcon)`
  font-size: 0.8em;
`;
const Text = styled.span`
  display: none;

  ${media.small`
    display: inline-block;
  `};
`;

const BackButton = (props) => (
  <Button type="button" {...props} icon={<Icon />}>
    <Text>
      <FormattedMessage {...messages.backButtonLabel} />
    </Text>
  </Button>
);

export default BackButton;
