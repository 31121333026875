/**
 *
 * BreakpointsProvider
 * @author Chad Watson
 *
 *
 */
import * as React from "react";
import { toUpper, prop, nthArg, F } from "ramda";
import { OrderedMap, Seq } from "immutable";
export const Context = React.createContext({
  breakpointNames: {},
  currentBreakpoint: 0,
  matchingBreakpoints: OrderedMap(),
  breakpointIsActive: F,
  minBreakpoint: F,
  maxBreakpoint: F
});

class BreakpointsProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    const { breakpoints } = props;
    const breakpointNames = Seq(breakpoints)
      .map(nthArg(1))
      .mapKeys(toUpper)
      .toObject();
    const currentBreakpoint = breakpoints.keySeq().last();
    this.state = {
      breakpointNames,
      currentBreakpoint,
      matchingBreakpoints: breakpoints.skipUntil(
        (value, key) => key === currentBreakpoint
      )
    };
  }

  componentDidMount() {
    this.mediaQueries = this.props.breakpoints.map(breakpoint => {
      const mediaQuery = matchMedia(`(min-width: ${breakpoint}px)`);
      mediaQuery.addListener(this.handleBreakpointChange);
      return mediaQuery;
    });
    this.handleBreakpointChange();
  }

  componentWillUnmount() {
    this.mediaQueries.forEach(mediaQuery => {
      mediaQuery.removeListener(this.handleBreakpointChange);
    });
  }

  handleBreakpointChange = () => {
    const { breakpoints } = this.props;
    const matches = Seq(this.mediaQueries)
      .filter(prop("matches"))
      .map(nthArg(1));
    const currentBreakpoint =
      !matches.isEmpty() && matches.first() !== this.state.currentBreakpoint
        ? matches.first()
        : matches.isEmpty()
          ? breakpoints.keySeq().last()
          : null;

    if (currentBreakpoint) {
      this.setState({
        currentBreakpoint,
        matchingBreakpoints: breakpoints.skipUntil(
          (value, key) => key === currentBreakpoint
        )
      });
    }
  };
  isActive = (targetBreakpoint, max) => {
    const { breakpoints } = this.props;
    return (max
      ? breakpoints.keySeq().skipUntil(key => key === targetBreakpoint)
      : breakpoints
          .keySeq()
          .reverse()
          .skipUntil(key => key === targetBreakpoint)
    ).includes(this.state.currentBreakpoint);
  };
  min = targetBreakpoint => this.isActive(targetBreakpoint, false);
  max = targetBreakpoint => this.isActive(targetBreakpoint, true);

  render() {
    return (
      <Context.Provider
        value={{
          currentBreakpoint: this.state.currentBreakpoint,
          matchingBreakpoints: this.state.matchingBreakpoints,
          breakpointNames: this.state.breakpointNames,
          breakpointIsActive: this.isActive,
          minBreakpoint: this.min,
          maxBreakpoint: this.max
        }}
      >
        {React.Children.only(this.props.children)}
      </Context.Provider>
    );
  }
}

const BreakpointsConsumer = props => <Context.Consumer {...props} />;

export default {
  Provider: BreakpointsProvider,
  Consumer: BreakpointsConsumer
};
