/**
 *
 * QuestionIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const QuestionIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M39,62c0,1.104-0.896,2-2,2H25c-1.104,0-2-0.896-2-2V52c0-1.104,0.896-2,2-2h12c1.104,0,2,0.896,2,2V62z M27,60h8v-6h-8
      V60z M36.488,46H24.726c-0.533,0-1.044-0.213-1.419-0.591c-0.375-0.378-0.584-0.891-0.581-1.424
      c0.031-4.249,0.719-6.154,1.978-8.185c1.231-1.984,3.522-4.257,7.004-6.947c1.69-1.39,3.092-2.938,4.12-4.564
      c0.946-1.501,1.407-3.145,1.407-5.025c0-1.987-0.466-3.455-1.426-4.489c-0.92-0.992-2.271-1.474-4.131-1.474
      c-1.514,0-2.747,0.428-3.771,1.306c-0.939,0.806-1.405,1.867-1.425,3.581c-0.013,1.095-0.904,1.812-2,1.812H12.965
      C11.861,20,11,19.435,11,18.33v-0.243c0-5.818,1.853-10.375,5.813-13.511C20.648,1.54,25.647,0,31.688,0
      c6.514,0,11.738,1.722,15.535,5.119c3.861,3.458,5.816,8.175,5.816,14.024c0,3.837-1.132,7.429-3.363,10.679
      c-2.132,3.105-4.831,5.745-8.022,7.849c-1.316,1.028-2.17,2.021-2.54,2.95l-0.039,0.096c-0.44,1.104-0.588,1.476-0.588,3.284
      C38.488,45.104,37.592,46,36.488,46z M26.812,42h7.773c0.13-1.098,0.391-1.803,0.774-2.765l0.037-0.094
      c0.653-1.641,1.918-3.171,3.867-4.681c0.042-0.032,0.085-0.062,0.129-0.091c2.784-1.824,5.134-4.116,6.985-6.812
      c1.79-2.607,2.66-5.36,2.66-8.415c0-4.728-1.467-8.34-4.486-11.043C41.511,5.379,37.18,4,31.679,4c-5.118,0-9.29,1.083-12.4,3.546
      C16.688,9.598,15.284,13,15.012,16h7.655c0.358-2,1.241-3.395,2.637-4.593c1.755-1.506,3.9-2.188,6.376-2.188
      c2.992,0,5.368,0.968,7.063,2.794c1.655,1.783,2.494,4.229,2.494,7.23c0,2.62-0.681,5.04-2.025,7.17
      c-1.258,1.99-2.943,3.864-5.01,5.562c-3.101,2.396-5.136,4.381-6.097,5.93C27.481,38.91,27.001,39.86,26.812,42z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default QuestionIcon;
