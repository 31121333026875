/**
 *
 * RotateLeftIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RotateLeftIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <rect x="0.208" y="0.129" fill="none" width="63.584" height="63.742" />
    <g>
      <rect y="0.002" fill="none" width="64" height="64" />
      <path
        d="M9.795,7.521l0.176-5.545L5.973,1.849l-0.397,12.57l13.315,0.171l0.051-4l-6.477-0.083
    c5.35-4.308,12.168-6.651,19.193-6.499c7.925,0.168,15.254,3.358,20.639,8.984c5.128,5.357,7.883,12.344,7.696,19.622
    c-0.352,15.439-13.636,27.724-29.612,27.382c-8.48-0.179-16.426-3.924-21.8-10.274l-3.054,2.584
    c6.115,7.226,15.143,11.486,24.769,11.69c18.179,0.388,33.295-13.649,33.696-31.291C63.998,32.467,64,32.228,64,31.989
    c0-8.104-3.113-15.808-8.812-21.763C49.062,3.828,40.737,0.199,31.744,0.008C23.705-0.165,15.898,2.544,9.795,7.521z"
      />
      <rect y="0.002" fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RotateLeftIcon;
