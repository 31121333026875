/**
 *
 * DoorClosedIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DoorClosedIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path d="M48,0H16a2,2,0,0,0-2,2V49a2,2,0,0,0,.6,1.5A2.4,2.4,0,0,0,16,51H48a2,2,0,0,0,2-2V2A2,2,0,0,0,48,0ZM46,47H18V4H46Z" />
        <path d="M40,29.3a2,2,0,0,0,2-2v-4a2,2,0,1,0-4,0v4A2,2,0,0,0,40,29.3Z" />
      </g>
    </g>
  </SvgIcon>
);

export default DoorClosedIcon;
