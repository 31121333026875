/**
 *
 * Server2Icon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const Server2Icon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M64,3.932C64,1.764,62.248,0,60.096,0H3.904C1.752,0,0,1.764,0,3.932v15.185c0,0.613,0.156,1.186,0.412,1.701
      C0.144,21.318,0,21.873,0,22.432v18.185c0,0.75,0.225,1.53,0.611,2.223C0.229,43.446,0,44.16,0,44.932v15.185
      C0,62.258,1.752,64,3.904,64h56.191C62.248,64,64,62.258,64,60.116V44.932c0-0.771-0.229-1.485-0.611-2.093
      C63.775,42.146,64,41.366,64,40.616V22.432c0-0.559-0.144-1.113-0.412-1.614C63.844,20.302,64,19.729,64,19.116V3.932z M4,40.616
      V23h56v17.615c-0.003,0.098-0.078,0.272-0.158,0.385H4.158C4.078,40.888,4.003,40.713,4,40.616z M4,4h56v15H4V4z M60,60H4V45h56
      V60z"
        />
        <rect x="9" y="11" width="15" height="4" />
        <rect x="9" y="33" width="15" height="4" />
        <rect x="9" y="52" width="15" height="4" />
        <rect x="41" y="11" width="5" height="4" />
        <rect x="41" y="33" width="5" height="4" />
        <rect x="41" y="52" width="5" height="4" />
        <rect x="50" y="11" width="5" height="4" />
        <rect x="50" y="33" width="5" height="4" />
        <rect x="50" y="52" width="5" height="4" />
      </g>
    </g>
  </SvgIcon>
);

export default Server2Icon;
