/**
 *
 * DatePicker
 * @author Chad Watson
 *
 */

import NakedButton from "components/NakedButton";
import Table from "components/Table";
import { TimePickerFromDateString } from "components/TimePicker";
import { themeGrayLight, themePrimary } from "containers/Theme";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import momentPropTypes from "react-moment-proptypes";
import {
  compose,
  lifecycle,
  setPropTypes,
  withHandlers,
  withState,
} from "recompose";
import styled from "styled-components/macro";
import { addEventListeners, removeEventListeners } from "utils";
import { intlShape } from "utils/prop-types";
import messages from "./messages";

const DatePicker = ({
  id,
  startValue,
  endValue,
  dateForActiveMonth,
  onStartDateMouseDown,
  onEndDateMouseDown,
  onDateClick,
  onDateMouseEnter,
  onStartChange,
  onEndChange,
  navigateToPrevYear,
  navigateToPrevMonth,
  navigateToNextMonth,
  navigateToNextYear,
  intl,
  className,
  withTime,
  disablePast,
  disableFuture,
  disableAfterOneYear,
  now,
}) => {
  const daysInAWeek = 7;
  const datesInOrder = [];

  const activeMonthStartingIndex = moment(dateForActiveMonth)
    .startOf("month")
    .day();

  const lastDateOfPrevMonth = moment(dateForActiveMonth)
    .subtract(1, "months")
    .endOf("month");

  for (let i = activeMonthStartingIndex - 1; i >= 0; i -= 1) {
    datesInOrder.push(moment(lastDateOfPrevMonth).subtract(i, "days"));
  }

  for (
    let i = 0;
    i < moment(dateForActiveMonth).endOf("month").date();
    i += 1
  ) {
    datesInOrder.push(
      moment(dateForActiveMonth).startOf("month").add(i, "days")
    );
  }

  const amountLeftToFill =
    daysInAWeek - 1 - datesInOrder[datesInOrder.length - 1].day();
  for (let i = 0; i < amountLeftToFill; i += 1) {
    datesInOrder.push(
      moment(dateForActiveMonth)
        .add(1, "months")
        .startOf("month")
        .add(i, "days")
    );
  }

  const calendarRows = Array(datesInOrder.length / daysInAWeek)
    .fill(0)
    .map((_, index) => {
      const start = daysInAWeek * index;
      const end = start + daysInAWeek;

      return datesInOrder.slice(start, end);
    });

  return (
    <Calendar className={className}>
      <CalendarTop>
        <CalendarNavLeft>
          <NavButton
            onClick={navigateToPrevYear}
            title={intl.formatMessage(messages.prevYearButtonLabel)}
          >
            &laquo;
          </NavButton>
          <NavButton
            onClick={navigateToPrevMonth}
            title={intl.formatMessage(messages.prevMonthButtonLabel)}
          >
            &lsaquo;
          </NavButton>
        </CalendarNavLeft>
        <CalendarTitle>{dateForActiveMonth.format("MMM YYYY")}</CalendarTitle>
        <CalendarNavRight>
          <NavButton
            onClick={navigateToNextMonth}
            title={intl.formatMessage(messages.nextMonthButtonLabel)}
          >
            &rsaquo;
          </NavButton>
          <NavButton
            onClick={navigateToNextYear}
            title={intl.formatMessage(messages.nextYearButtonLabel)}
          >
            &raquo;
          </NavButton>
        </CalendarNavRight>
      </CalendarTop>
      <Table
        headers={[
          <Th key="sun">
            <FormattedMessage {...messages.sun} />
          </Th>,
          <Th key="mon">
            <FormattedMessage {...messages.mon} />
          </Th>,
          <Th key="tue">
            <FormattedMessage {...messages.tue} />
          </Th>,
          <Th key="wed">
            <FormattedMessage {...messages.wed} />
          </Th>,
          <Th key="thu">
            <FormattedMessage {...messages.thu} />
          </Th>,
          <Th key="fri">
            <FormattedMessage {...messages.fri} />
          </Th>,
          <Th key="sat">
            <FormattedMessage {...messages.sat} />
          </Th>,
        ]}
      >
        {calendarRows.map((row, i) => (
          <tr key={i.toString()}>
            {row.map((date, j) => {
              const isStartDate =
                !!startValue && date.isSame(startValue, "day");
              const isEndDate = !!endValue && date.isSame(endValue, "day");
              const isWithinRange = !!(
                startValue &&
                endValue &&
                date.isBetween(startValue, endValue)
              );
              // isToday is being added to properly compare the date to current
              // moment.js seems to be doing something odd with the timezone offsets
              // as a result, the date.isBefore() or date.isAfter had undesired behavior
              const isToday =
                date.format("DDMMYY") === (now || moment()).format("DDMMYY");
              return (
                <DateCell key={j.toString()}>
                  <DateButton
                    disabled={
                      (disablePast &&
                        date.isBefore(now || moment(), "day") &&
                        !isToday) ||
                      (disableFuture &&
                        date.isAfter(now || moment(), "day") &&
                        !isToday) ||
                      (disableAfterOneYear &&
                        date.isAfter(moment().add(1, "year"), "day") &&
                        !isToday)
                    }
                    value={date.toISOString()}
                    isToday={isToday}
                    isStartDate={isStartDate}
                    isEndDate={isEndDate}
                    isWithinRange={isWithinRange}
                    isBeforeStart={
                      !!startValue && date.isBefore(startValue, "day")
                    }
                    isAfterStart={
                      !!startValue && date.isAfter(startValue, "day")
                    }
                    hasEndDate={!!(onEndChange && endValue)}
                    onMouseDown={
                      isStartDate && onStartChange
                        ? onStartDateMouseDown
                        : isEndDate && onEndChange
                        ? onEndDateMouseDown
                        : undefined
                    }
                    onMouseEnter={() => onDateMouseEnter(date)}
                    onClick={(evt) => onDateClick(date, evt)}
                  >
                    <DateText>{date.date()}</DateText>
                  </DateButton>
                </DateCell>
              );
            })}
          </tr>
        ))}
      </Table>
      {withTime && (
        <TimeFields>
          <TimeField>
            {onEndChange && (
              <TimeFieldLabel>
                <FormattedMessage {...messages.startTime} />
              </TimeFieldLabel>
            )}
            <TimePickerFromDateString
              id={`${id}-start-time`}
              dateString={startValue.toISOString()}
              onChange={onStartChange}
              utc={!now}
            />
          </TimeField>
          {onEndChange && endValue && (
            <TimeField>
              <TimeFieldLabel>
                <FormattedMessage {...messages.endTime} />
              </TimeFieldLabel>
              <TimePickerFromDateString
                id={`${id}-end-time`}
                dateString={endValue.toISOString()}
                onChange={onEndChange}
                utc={!now}
              />
            </TimeField>
          )}
        </TimeFields>
      )}
    </Calendar>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  dateForActiveMonth: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }).isRequired,
  onStartDateMouseDown: PropTypes.func.isRequired,
  onEndDateMouseDown: PropTypes.func.isRequired,
  onDateClick: PropTypes.func.isRequired,
  onDateMouseEnter: PropTypes.func.isRequired,
  onStartChange: PropTypes.func.isRequired,
  navigateToPrevYear: PropTypes.func.isRequired,
  navigateToPrevMonth: PropTypes.func.isRequired,
  navigateToNextMonth: PropTypes.func.isRequired,
  navigateToNextYear: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onEndChange: PropTypes.func,
  startValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withTime: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disableAfterOneYear: PropTypes.bool,
  className: PropTypes.string,
  now: momentPropTypes.momentObj,
};

DatePicker.defaultProps = {
  disablePast: false,
  disableFuture: false,
  disableAfterOneYear: false,
  onEndChange: null,
  startValue: null,
  endValue: null,
  withTime: false,
  className: "",
};

const enhance = compose(
  setPropTypes({
    startValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  withState("dateForActiveMonth", "setDateForActiveMonth", ({ startValue }) =>
    startValue ? moment(startValue) : moment()
  ),
  withState("movingStartDate", "setMovingStartDate", false),
  withState("movingEndDate", "setMovingEndDate", false),
  withHandlers({
    navigateToPrevYear: ({ setDateForActiveMonth, dateForActiveMonth }) => () =>
      setDateForActiveMonth(moment(dateForActiveMonth).subtract(1, "years")),
    navigateToPrevMonth: ({
      setDateForActiveMonth,
      dateForActiveMonth,
    }) => () =>
      setDateForActiveMonth(moment(dateForActiveMonth).subtract(1, "months")),
    navigateToNextMonth: ({
      setDateForActiveMonth,
      dateForActiveMonth,
    }) => () =>
      setDateForActiveMonth(moment(dateForActiveMonth).add(1, "months")),
    navigateToNextYear: ({ setDateForActiveMonth, dateForActiveMonth }) => () =>
      setDateForActiveMonth(moment(dateForActiveMonth).add(1, "years")),
    onStartDateMouseDown: ({ setMovingStartDate }) => () =>
      setMovingStartDate(true),
    onEndDateMouseDown: ({ setMovingEndDate }) => () => setMovingEndDate(true),
    onDateClick: (props) => (date, evt) => {
      const {
        startValue,
        endValue,
        onStartChange,
        onEndChange,
        movingStartDate,
        movingEndDate,
        setMovingStartDate,
        setMovingEndDate,
        closeFunc,
      } = props;

      if (
        !startValue ||
        movingStartDate ||
        !onEndChange ||
        date.isBefore(startValue)
      ) {
        onStartChange(
          startValue
            ? moment(startValue)
                .year(date.year())
                .month(date.month())
                .date(date.date())
                .toISOString()
            : date.toISOString()
        );
        setMovingStartDate(false);
      } else if (
        onEndChange &&
        (!endValue || movingEndDate || date.isAfter(startValue))
      ) {
        onEndChange(date.endOf("day").toISOString());
        setMovingEndDate(false);
      }
      if (!!closeFunc) {
        closeFunc(evt);
      }
    },
    onDateMouseEnter: ({
      movingStartDate,
      movingEndDate,
      onStartChange,
      onEndChange,
    }) => (date) => {
      if (movingStartDate) {
        onStartChange(date);
      } else if (movingEndDate) {
        onEndChange(date.endOf("day").toISOString());
      }
    },
    onMouseUp: ({
      movingStartDate,
      movingEndDate,
      setMovingStartDate,
      setMovingEndDate,
    }) => () => {
      if (movingStartDate) {
        setMovingStartDate(false);
      } else if (movingEndDate) {
        setMovingEndDate(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      addEventListeners(document, "mouseup touchend", this.props.onMouseUp);
    },
    componentWillUnmount() {
      removeEventListeners(document, "mouseup touchend", this.props.onMouseUp);
    },
  })
);

export default enhance(injectIntl(DatePicker));

const Calendar = styled.div`
  padding: 0.5em;
`;
const CalendarTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.25em;
`;
const CalendarNavLeft = styled.div`
  display: flex;
  align-items: center;
`;
const CalendarNavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const NavButton = styled(NakedButton)`
  width: 1.4em;
  height: 1.4em;
  border: 1px solid transparent;
  border-radius: 50%;
  font-weight: 500;

  &:hover {
    background: ${themePrimary};
    color: white;
  }
`;
const CalendarTitle = styled.span`
  font-weight: bold;

  &::first-letter {
    text-transform: uppercase;
  }
`;
const Th = styled.th`
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.8;
  text-transform: uppercase;
  text-align: center;
`;
const DateCell = styled.td`
  padding: 3px;
  text-align: center;
`;
const DateButton = styled(NakedButton)`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 50%;

  &[disabled] {
    color: ${themeGrayLight};
  }

  &:enabled {
    color: ${({ isStartDate, isEndDate, theme }) =>
      isStartDate || isEndDate ? theme.trueWhite : "inherit"};
    background: ${({ isStartDate, isEndDate, isWithinRange, theme }) => {
      if (isStartDate || isEndDate) {
        return theme.primary;
      }

      if (isWithinRange) {
        return theme.grayLight;
      }

      return "transparent";
    }};
    font-weight: ${({ isToday }) => (isToday ? "bold" : "normal")};
    cursor: ${({
      hasEndDate,
      isStartDate,
      isEndDate,
      isBeforeStart,
      isAfterStart,
      onMouseDown,
    }) => {
      if (onMouseDown && (isStartDate || isEndDate)) {
        return "move";
      }

      if (hasEndDate && isBeforeStart) {
        return "e-resize";
      }

      if (hasEndDate && isAfterStart) {
        return "w-resize";
      }

      return "pointer";
    }};

    &:hover {
      background: ${themePrimary};
      color: white;
    }
  }
`;
const DateText = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const TimeFields = styled.div`
  margin-top: 10px;
`;
const TimeField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
const TimeFieldLabel = styled.label`
  display: block;
  min-width: 6.5em;
  font-size: 14px;
  font-weight: bold;
`;
