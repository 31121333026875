/**
 *
 * ListItem
 * @author Chad Watson
 *
 */

import { ControlRightIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import StatusIndicators from "components/StatusIndicators";
import { themeBorderColor, themeGrayXlight } from "containers/Theme";
import PropTypes from "prop-types";
import { propOr } from "ramda";
import React from "react";
import { pure } from "recompose";
import styled, { css } from "styled-components/macro";

const ITEM_PADDING_X_IN_PX = 20;
export const ITEM_SEPARATOR_HEIGHT_IN_PX = 1;
export const ITEM_HEIGHT = 52;

const itemStyles = css`
  position: relative;
  color: ${({ active, theme }) => (active ? theme.primary : theme.gray)};

  &:not(:last-child) {
    border-bottom: ${ITEM_SEPARATOR_HEIGHT_IN_PX}px solid ${themeBorderColor};
  }
`;
const Li = styled.li`
  ${itemStyles};
`;
const Div = styled.div`
  ${itemStyles};
`;

const RightContainer = styled.span`
  display: block;
  font-size: 1.1em;
`;
const ArrowIcon = styled(ControlRightIcon)`
  font-size: 0.75em;
`;

/** @type {{
 *   (props: { component?: "div" | "li", children: React.ReactNode, active?:boolean } & React.BaseHTMLAttributes<any>) => JSX.Element;
 *   Button: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   Content: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   ButtonContent: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   Left: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   Icon: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   Text: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   SecondaryText: (props: React.AllHTMLAttributes<any>) => JSX.Element
 *   Right: (props: React.AllHTMLAttributes<any>) => JSX.Element
 * }} */
const ListItem = React.forwardRef(
  ({ component = "li", children, ...rest }, ref) => {
    const Component = component === "div" ? Div : Li;
    return (
      <Component {...rest} ref={ref}>
        {children}
      </Component>
    );
  }
);
ListItem.propTypes = {
  component: PropTypes.oneOf(["li", "div"]),
  children: PropTypes.node,
};
ListItem.defaultProps = {
  component: "li",
  children: null,
};

ListItem.Button = styled(NakedButton)`
  width: 100%;
  border-radius: inherit;
  font-size: 16px;

  &[disabled] {
    opacity: 0.6;
  }

  &:enabled:hover,
  &:enabled:focus {
    background: ${themeGrayXlight};
  }
`;
ListItem.Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  padding: 0 ${ITEM_PADDING_X_IN_PX}px;
`;
ListItem.ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  padding: 0 ${ITEM_PADDING_X_IN_PX}px;
`;
ListItem.Left = styled.span`
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
`;
ListItem.Icon = styled.span`
  display: block;
  margin-right: 10px;
  font-size: ${propOr("1.35em", "width")};
  width: 1em;
  height: 1em;
  line-height: 1;

  & svg {
    display: block;
  }
`;
ListItem.Text = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  line-height: 1.25;
`;
ListItem.SecondaryText = styled(ListItem.Text)`
  font-weight: normal;
`;
ListItem.Right = ({ loading, error }) => (
  <RightContainer>
    {loading || error ? (
      <StatusIndicators error={error} loading={loading} />
    ) : (
      <ArrowIcon />
    )}
  </RightContainer>
);
ListItem.Right.displayName = "ListItemRight";
ListItem.Right.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
};

export const DefaultListItem = pure(
  ({
    isActive,
    value,
    icon,
    children,
    onClick,
    error,
    loading,
    disabled,
    iconWidth,
    ...rest
  }) => (
    <ListItem {...rest} active={isActive}>
      <ListItem.Button disabled={disabled} onClick={onClick} value={value}>
        <ListItem.ButtonContent>
          <ListItem.Left>
            {!!icon && <ListItem.Icon width={iconWidth}>{icon}</ListItem.Icon>}
            <ListItem.Text>{children}</ListItem.Text>
          </ListItem.Left>
          {!disabled && <ListItem.Right error={error} loading={loading} />}
        </ListItem.ButtonContent>
      </ListItem.Button>
    </ListItem>
  )
);

DefaultListItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  iconWidth: PropTypes.string,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  loading: PropTypes.bool,
};

DefaultListItem.defaultProps = {
  disabled: false,
};

export default ListItem;
