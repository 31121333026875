import { Z_INDEX } from "common/utils/styles";
import ScrollObserver from "components/ScrollObserver";
import React from "react";
import styled, { css } from "styled-components/macro";

export type AffixState = {
  affix: boolean;
  width: number;
  height: number;
  left: number;
  topThreshold: number;
};

const StickyWrapper = styled.div<AffixState>`
  ${({ affix, width, height, left, topThreshold }) =>
    affix &&
    css`
      position: fixed;
      width: ${width}px;
      height: ${height}px;
      left: ${left}px;
      top: ${topThreshold}px;
      z-index: ${Z_INDEX.COMPONENT};
    `};
`;

type Props = {
  children: React.ReactNode | ((affixState: AffixState) => React.ReactNode);
  topThreshold: number;
  className?: string;
  active?: boolean;
  scrollTarget?: HTMLElement | typeof window;
  id?: string;
};

function Affix({
  children,
  topThreshold = 0,
  className,
  active = true,
  ...rest
}: Props) {
  return (
    <ScrollObserver {...rest} threshold={topThreshold} active={active}>
      {({ hasMetThreshold, dimensions, registerChild }) => (
        <div
          style={{ height: `${dimensions.height}px` }}
          ref={registerChild}
          className={className}
        >
          {typeof children === "function" ? (
            children({
              ...dimensions,
              affix: active && hasMetThreshold,
              topThreshold,
            })
          ) : (
            <StickyWrapper
              {...dimensions}
              affix={active && hasMetThreshold}
              topThreshold={topThreshold}
            >
              {children}
            </StickyWrapper>
          )}
        </div>
      )}
    </ScrollObserver>
  );
}

export default Affix;
