/**
 *
 * MailIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const MailIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M0,10v42h64V10H0z M58.6,14c-8.36,8-21.743,19.623-26.482,19.623C28.038,33.623,15.188,22,6.109,14H58.6z M4,17.179
    c3.494,3.364,9.057,8.521,14.528,12.771L4,45.645V17.179z M6.948,48l14.81-15.826c4.076,2.882,7.851,5.015,10.359,5.015
    c1.026,0,3.616-0.134,10.364-4.802L57.055,48H6.948z M60,45.641L45.732,30.229c1.585-1.211,3.341-2.625,5.299-4.29
    c3.513-2.985,6.753-5.989,8.969-8.099V45.641z"
      />
    </g>
  </SvgIcon>
);

export default MailIcon;
