/**
 *
 * RadialDownloadIcon
 * @author Chad Watson
 *
 */

import React from "react";
import SvgIcon from "./SvgIcon";

const VIEW_SIZE = 64;

const RadialDownloadIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <path
        d="M54.633,9.226c-6.05-6.036-14.087-9.348-22.646-9.348c-8.546,0-16.581,3.346-22.626,9.378
    C3.325,15.278,0.001,23.347,0,31.889c0,8.547,3.326,16.669,9.366,22.699c6.039,6.026,14.08,9.534,22.643,9.534c0,0,0.001,0,0.002,0
    c8.557,0,16.594-3.503,22.629-9.525C60.676,48.573,64,40.365,64,31.821C64,23.274,60.674,15.253,54.633,9.226z M51.813,51.763
    c-5.279,5.269-12.312,8.359-19.803,8.359h-0.002c-7.495,0-14.533-3.093-19.816-8.365C6.909,46.483,4,39.272,4,31.794
    c0.001-7.473,2.908-14.488,8.187-19.755c5.289-5.278,12.321-8.185,19.815-8.185c7.478,0,14.512,2.957,19.806,8.238
    C57.091,17.364,60,24.433,60,31.91C60,39.384,57.093,46.495,51.813,51.763z"
      />
      <polygon points="34,41.876 34,14.122 30,14.122 30,42.1 22.128,35.259 19.505,38.278 31.959,49.1 43.993,38.254 41.315,35.283   " />
    </g>
  </SvgIcon>
);

export default RadialDownloadIcon;
