/**
 *
 * NumberField
 * @author Chad Watson
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import BigField from "./BigField";
import messages from "./messages";

class NumberField extends React.Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  handleChange = ({ target }) => {
    const value = target.value ? parseInt(target.value, 10) : 0;
    return this.props.onChange(value);
  };

  render() {
    return (
      <BigField
        {...this.props}
        type="number"
        id="numberField"
        onChange={this.handleChange}
        label={<FormattedMessage {...messages.numberFieldLabel} />}
      />
    );
  }
}

export default NumberField;
