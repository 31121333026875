import { VisibilityIcon, VisibilityOffIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import { themeGrayMedium } from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

const Button = styled(NakedButton)`
  font-size: inherit;
  width: 2em;
  height: 2em;
  color: ${({ theme, active }) =>
    active ? `${theme.grayDark} !important` : theme.grayAccent};
  transition: 0.25s opacity;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};

  &:hover {
    color: ${themeGrayMedium};
  }
`;
const Icon = styled.svg`
  font-size: 1.2em;
`;

function ShowPasswordButton({ visible, active, ...rest }) {
  return (
    <Button type="button" {...rest} active={active} visible={visible}>
      <Icon as={active ? VisibilityOffIcon : VisibilityIcon} />
    </Button>
  );
}

ShowPasswordButton.propTypes = {
  visible: PropTypes.bool,
  active: PropTypes.bool,
};

export default ShowPasswordButton;
