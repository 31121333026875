/**
 * HelpButton
 * @author Chad Watson
 */

import { RadialInfoIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import Tooltip from "components/Tooltip";
import { themeGrayDark, themePrimary } from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components/macro";

class HelpButton extends React.Component {
  static defaultProps = {
    zIndex: 10,
    variant: "default",
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    variant: PropTypes.string,
    zIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClick = () => this.setState(({ open }) => ({ open: !open }));
  dismissTooltip = () => this.setState({ open: false });

  render() {
    return (
      <Root>
        <Tooltip
          anchor={({ registerAnchor }) => (
            <Button
              type="button"
              ref={registerAnchor}
              className={this.props.className}
              onClick={this.handleClick}
              isOpen={this.state.open}
              variant={this.props.variant}
            >
              <RadialInfoIcon valign="top" />
            </Button>
          )}
          open={this.state.open}
          onDismiss={this.dismissTooltip}
          dark
          zIndex={this.props.zIndex}
        >
          <TooltipContent>{this.props.children}</TooltipContent>
        </Tooltip>
      </Root>
    );
  }
}

export default HelpButton;

const Root = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;
const Button = styled(NakedButton)`
  position: relative;
  width: 1em;
  height: 1em;
  padding-left: 0.05em;
  border-radius: 50%;
  color: ${themeGrayDark};
  line-height: 1em;
  font-size: ${({ variant }) => variant === "small" ? null : "1.5em"};
  text-align: center;
  vertical-align: middle;
  opacity: 0.7;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: none;
      color: ${themePrimary};
      opacity: 1;
    `};
`;
const TooltipContent = styled.div`
  min-width: 200px;
  max-width: 300px;
  padding: 10px 15px;
  margin: 0;
  line-height: 1.5;
  white-space: pre-wrap;
`;
