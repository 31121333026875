/**
 *
 * IdBadgeProfilesIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const IdBadgeProfilesIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M44.941,9H37V2c0-1.104-0.726-2-1.83-2h-6.34C27.726,0,27,0.896,27,2v7h-7.939C15.754,9,13,11.437,13,14.686v43.418
        C13,61.354,15.754,64,19.061,64h25.881C48.247,64,51,61.354,51,58.104V14.686C51,11.437,48.247,9,44.941,9z M31,4h2v8h-2V4z
         M47,58.104C47,59.149,46.041,60,44.941,60H19.061C17.96,60,17,59.149,17,58.104V14.686C17,13.642,17.96,13,19.061,13H27v0.87
        c0,1.104,0.726,2.13,1.83,2.13h6.34c1.104,0,1.83-1.025,1.83-2.13V13h7.941c1.1,0,2.059,0.642,2.059,1.686V58.104z"
          />
          <path
            d="M26.982,34h10.035C38.234,34,39,32.853,39,31.689v-9.584C39,20.945,38.234,20,37.018,20H26.982
        C25.766,20,25,20.945,25,22.105v9.584C25,32.853,25.766,34,26.982,34z"
          />
          <path d="M41,40H23c-1.104,0-2,0.896-2,2s0.896,2,2,2h18c1.104,0,2-0.896,2-2S42.104,40,41,40z" />
          <path d="M41,50H23c-1.104,0-2,0.896-2,2s0.896,2,2,2h18c1.104,0,2-0.896,2-2S42.104,50,41,50z" />
        </g>
        <path
          fill="none"
          stroke="#000000"
          d="
      M32.105,5.75"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </g>
    </g>
  </SvgIcon>
);

export default IdBadgeProfilesIcon;
