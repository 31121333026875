/**
 *
 * TrashIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TrashIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M59.588,7.913C59.541,6.843,58.66,6,57.59,6H43.644l-1.489-4.614C41.888,0.56,41.119,0,40.251,0H24.032
      c-0.833,0-1.578,0.516-1.872,1.295L20.388,6H7.395c-1.07,0-1.951,0.843-1.998,1.912l-0.395,9
      c-0.024,0.546,0.176,1.076,0.553,1.471C5.933,18.777,6.454,19,7,19h1.007l3.015,43.14c0.073,1.048,0.944,1.86,1.995,1.86h39.319
      c1.057,0,1.931-0.821,1.996-1.876L57.006,19h0.978c0.546,0,1.067-0.223,1.444-0.617c0.378-0.394,0.577-0.925,0.554-1.47
      L59.588,7.913z M25.416,4h13.379l0.646,2H24.663L25.416,4z M9.309,10h12.464h20.415h13.488l0.218,5h-0.768H9.872H9.09L9.309,10z
       M50.456,60H14.882l-2.865-41h40.981L50.456,60z"
        />
        <rect
          x="8.648"
          y="36.334"
          transform="matrix(0.0459 0.9989 -0.9989 0.0459 59.0034 14.8911)"
          width="26.116"
          height="4"
        />
        <rect
          x="41.761"
          y="25.276"
          transform="matrix(0.9989 0.0459 -0.0459 0.9989 1.8055 -1.968)"
          width="4"
          height="26.116"
        />
        <rect x="31" y="25" width="4" height="26" />
      </g>
    </g>
  </SvgIcon>
);

export default TrashIcon;
