/**
 *
 * HistoryIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const HistoryIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M56.481,7.031l5.545,0.176l0.127-3.998l-12.57-0.398l-0.171,13.316l4,0.051l0.083-6.477
      c4.308,5.349,6.651,12.168,6.499,19.194c-0.168,7.925-3.358,15.254-8.984,20.639c-5.357,5.127-12.306,7.867-19.622,7.696
      C15.948,56.878,3.664,43.594,4.006,27.617c0.179-8.48,3.924-16.426,10.274-21.8l-2.584-3.054
      C4.471,8.878,0.21,17.906,0.006,27.532C-0.382,45.71,13.655,60.827,31.297,61.229c0.238,0.005,0.478,0.008,0.716,0.008
      c8.104,0,15.808-3.114,21.763-8.813c6.398-6.124,10.027-14.45,10.219-23.443C64.167,20.941,61.458,13.133,56.481,7.031z"
        />
        <polygon points="45,33 32,33 32,18 28,18 28,37 45,37     " />
      </g>
    </g>
  </SvgIcon>
);

export default HistoryIcon;
