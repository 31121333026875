/*
 * Notifications Messages
 *
 * This contains all the text for the Notifications.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  clear: {
    id: "app.components.Notifications.clear",
    defaultMessage: "Clear"
  },
  ok: {
    id: "app.components.Notifications.ok",
    defaultMessage: "Okay"
  },
  viewResults: {
    id: "app.components.Notifications.viewResults",
    defaultMessage: "View Results"
  }
});
