/**
 *
 * VisibilityOffIcon
 * @author Chad Watson
 *
 */
import React from "react";
import styled from "styled-components/macro";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;
const NoFillRect = styled.rect`
  fill: none;
`;
const NoFillPath = styled.path`
  fill: none;
`;

const VisibilityOffIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <NoFillRect width="64" height="64" />
    <NoFillPath d="M23.9,32A8.1,8.1,0,0,1,32,23.9h1a4.2,4.2,0,0,0-.9,1.4l6.2-6.2A13.6,13.6,0,0,0,32,17.7,14.3,14.3,0,0,0,17.7,32a14.6,14.6,0,0,0,1.5,6.3l4.9-4.9A5.9,5.9,0,0,1,23.9,32Z" />
    <NoFillPath d="M36.3,31.7A4.6,4.6,0,0,0,39.8,30a6.4,6.4,0,0,1,.3,2A8.1,8.1,0,0,1,32,40.1,7.9,7.9,0,0,1,26.6,38l-4.4,4.4A14.3,14.3,0,0,0,46.3,32a14.2,14.2,0,0,0-3.9-9.8l-8.7,8.7A4.5,4.5,0,0,0,36.3,31.7Z" />
    <NoFillPath d="M16.5,20.6A58.9,58.9,0,0,0,4.9,32,62.6,62.6,0,0,0,15.1,42.4l.4-.4a19.7,19.7,0,0,1-2.8-10A19,19,0,0,1,16.5,20.6Z" />
    <NoFillPath d="M47.5,20.6a19,19,0,0,1,0,22.8A61.9,61.9,0,0,0,59.1,32,59,59,0,0,0,47.5,20.6Z" />
    <path d="M12.3,45.3l2.8-2.9A62.6,62.6,0,0,1,4.9,32,58.9,58.9,0,0,1,16.5,20.6,19,19,0,0,0,12.7,32a19.7,19.7,0,0,0,2.8,10l3.7-3.7A14.6,14.6,0,0,1,17.7,32,14.3,14.3,0,0,1,32,17.7a13.6,13.6,0,0,1,6.3,1.5l5.5-5.5A26.4,26.4,0,0,0,32,10.8C14.8,10.8,1.3,30.1.8,30.9L0,32l.8,1.2A63.9,63.9,0,0,0,12.3,45.3Z" />
    <path d="M63.2,30.8A63.1,63.1,0,0,0,52.3,19.2L63.2,8.3a2.4,2.4,0,0,0,0-3.5,2.4,2.4,0,0,0-3.5,0L4.8,59.7a2.5,2.5,0,0,0,1.8,4.2,2.2,2.2,0,0,0,1.7-.7L20.9,50.6A26.2,26.2,0,0,0,32,53.2c17.2,0,30.7-19.3,31.2-20.1L64,32ZM32,46.3a13.1,13.1,0,0,1-5.6-1.2l5.1-5H32A8.1,8.1,0,0,0,40.1,32a.9.9,0,0,0-.1-.5l5.1-5.1A13.1,13.1,0,0,1,46.3,32,14.3,14.3,0,0,1,32,46.3Zm15.5-2.9A18.8,18.8,0,0,0,51.3,32a18.2,18.2,0,0,0-2.5-9.3l.7-.6A59,59,0,0,1,59.1,32,61.9,61.9,0,0,1,47.5,43.4Z" />
    <path d="M23.9,32a5.9,5.9,0,0,0,.2,1.4l8-8A4.2,4.2,0,0,1,33,24H32A8.1,8.1,0,0,0,23.9,32Z" />
  </SvgIcon>
);

export default VisibilityOffIcon;
