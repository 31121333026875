/**
 *
 * LaptopIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LaptopIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M60.698,44.803H57.67V12.41c0-1.84-1.481-3.338-3.302-3.338H9.145c-1.82,0-3.302,1.498-3.302,3.338v32.393H3.302
      C1.481,44.803,0,46.301,0,48.142v0.406l1.496,3.531c0.235,1.61,1.61,2.849,3.266,2.849h53.989c1.607,0,2.95-1.168,3.243-2.708
      L64,48.667v-0.526C64,46.301,62.519,44.803,60.698,44.803z M9.843,13.072H53.67v31.194H9.843V13.072z M58.129,50.928H5.353
      l-0.9-2.125h19.313l1.896,1.498h11.563l2.439-1.498h19.664L58.129,50.928z"
        />
        <path
          d="M47.949,15.142H15.42c-1.82,0-3.301,1.498-3.301,3.339v20.75c0,1.841,1.48,3.339,3.301,3.339h32.529
      c1.82,0,3.302-1.498,3.302-3.339V18.48C51.251,16.64,49.77,15.142,47.949,15.142z M47.251,38.569H16.119V19.142h31.132V38.569z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default LaptopIcon;
