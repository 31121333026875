/**
 *
 * ControlLeftIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ControlLeftIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <polygon points="46.803,2.556 43.724,0.003 17.197,32.001 43.724,63.997 46.803,61.444 22.393,32.001   " />
    </g>
  </SvgIcon>
);

export default ControlLeftIcon;
