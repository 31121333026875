/**
 *
 * Form Messages
 * @author Chad Watson
 *
 * This contains all the text for the Form component.
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  loading: {
    id: "app.components.Form.loading",
    defaultMessage: "Loading...",
  },
  save: {
    id: "app.components.Form.save",
    defaultMessage: "Save",
  },
  saving: {
    id: "app.components.Form.saving",
    defaultMessage: "Saving...",
  },
  saved: {
    id: "app.components.Form.saved",
    defaultMessage: "Saved",
  },
  delete: {
    id: "app.components.Form.delete",
    defaultMessage: "Delete",
  },
  deleted: {
    id: "app.components.Form.deleted",
    defaultMessage: "Deleted",
  },
  error: {
    id: "app.components.Form.error",
    defaultMessage: "Error",
  },
  idle: {
    id: "app.components.Form.idle",
    defaultMessage: "Idle",
  },
  reset: {
    id: "app.components.Form.reset",
    defaultMessage: "Reset",
  },
  deleting: {
    id: "app.components.Form.deleting",
    defaultMessage: "Deleting...",
  },
  errorsTitle: {
    id: "app.containers.ProfilesPage.errorsTitle",
    defaultMessage: "There are errors in your settings.",
  },
  numberFieldLabel: {
    id: "app.components.Form.numberFieldLabel",
    defaultMessage: "Number",
  },
  numberFieldError: {
    id: "app.components.Form.numberFieldError",
    defaultMessage: "This number is already taken.",
  },
  dateFieldLabel: {
    id: "app.components.Form.dateFieldLabel",
    defaultMessage: "Date",
  },
  time: {
    id: "app.components.Form.time",
    defaultMessage: "Time",
  },
  prevYearButtonLabel: {
    id: "app.components.Form.prevYearButtonLabel",
    defaultMessage: "Previous Year",
  },
  prevMonthButtonLabel: {
    id: "app.components.Form.prevMonthButtonLabel",
    defaultMessage: "Previous Month",
  },
  nextMonthButtonLabel: {
    id: "app.components.Form.nextMonthButtonLabel",
    defaultMessage: "Next Month",
  },
  nextYearButtonLabel: {
    id: "app.components.Form.nextYearButtonLabel",
    defaultMessage: "Next Year",
  },
  all: {
    id: "app.components.Form.all",
    defaultMessage: "All",
  },
  none: {
    id: "app.components.Form.none",
    defaultMessage: "None",
  },
});
