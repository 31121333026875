/**
 *
 * withErrorMessage
 * @author Chad Watson
 *
 */

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { is } from "ramda";
import Tooltip, { TooltipErrorMessage } from "components/Tooltip";

export const ErrorMessageTooltip = ({ errorMessage, children, ...rest }) => (
  <Tooltip
    anchor={({ registerAnchor }) =>
      children({ ...rest, registerAnchor, errorMessage })
    }
    dark={true}
    open={!!errorMessage}
  >
    <TooltipErrorMessage>
      {!!errorMessage &&
        (React.isValidElement(errorMessage) || is(String, errorMessage) ? (
          errorMessage
        ) : (
          <FormattedMessage {...errorMessage} />
        ))}
    </TooltipErrorMessage>
  </Tooltip>
);

ErrorMessageTooltip.propTypes = {
  children: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ErrorMessageTooltip.defaultProps = {
  errorMessage: null
};

export default Component => {
  const C = forwardRef((props, ref) => (
    <ErrorMessageTooltip {...props}>
      {anchorProps => <Component {...props} {...anchorProps} ref={ref} />}
    </ErrorMessageTooltip>
  ));
  C.displayName = `withErrorMessage(${Component.displayName ||
    Component.name})`;
  return C;
};
