/**
 *
 * Modal
 * @author Chad Watson
 *
 */

import noop from "common/utils/universal/noop";
import { APP_ELEMENT } from "config/app";
import { usePanelBorder, useTheme } from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";
import { Z_INDEX } from "common/utils/styles"

export const STANDARD_MODAL_Z_INDEX = 200;

// NOTE: Styles for the frosted glass effect are found in app/containers/App/index.js in the globalStyles
const Modal = ({
  overlayStyle = {},
  contentStyle = {},
  isPriority = false,
  onRequestClose = noop,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ReactModal
      {...rest}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: isPriority ? Z_INDEX.MODAL_PRIORITY : Z_INDEX.MODAL,
          background: "none",
          ...overlayStyle,
        },
        content: {
          display: "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          padding: 0,
          border: usePanelBorder(),
          outline: "none",
          background: "white",
          borderRadius: "0.5rem",
          boxShadow: theme.elevation500,
          WebkitOverflowScrolling: "touch",
          maxHeight: "calc(100vh - 100px)",
          overflow: "scroll",
          ...contentStyle,
        },
      }}
      appElement={APP_ELEMENT}
      onRequestClose={onRequestClose}
    />
  );
};

Modal.propTypes = {
  contentStyle: PropTypes.object.isRequired,
  isPriority: PropTypes.bool,
};

export default Modal;
