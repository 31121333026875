/**
 *
 * ConfirmModal
 * @author Tyler Torres
 *
 */

import Button, { DullButton } from "components/Button";
import Modal from "components/Modal";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components/macro";
import { intlShape } from "utils/prop-types";
import messages from "./messages";

/**
 * @deprecated Use `<ConfirmModal />` from projects/virtual-keypad/src/components/ConfirmModal instead.
 */
class ConfirmModal extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    active: PropTypes.bool,
    message: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    messageOverride: PropTypes.string,
  };

  static defaultProps = {
    active: false,
    message: messages.defaultMessage,
  };

  componentDidUpdate(prevProps) {
    const { defaultMessage } = messages;
    const { message } = this.props;

    if (message !== defaultMessage && prevProps.message === defaultMessage) {
      window.addEventListener("beforeunload", this.confirmUnload);
    } else if (
      message === defaultMessage &&
      prevProps.message !== defaultMessage
    ) {
      window.removeEventListener("beforeunload", this.confirmUnload);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.confirmUnload);
  }

  confirmUnload = (e) => {
    const message = this.props.intl.formatMessage(this.props.message);
    e.returnValue = message; // eslint-disable-line no-param-reassign
    return message;
  };

  render() {
    return (
      <Modal
        isOpen={this.props.active}
        contentLabel="Confirm"
        onRequestClose={this.props.onCancel}
        isPriority={true}
      >
        <Content>
          <MessageWrapper>
            <Message>
              {this.props.message.messageOverride ? (
                this.props.message.messageOverride
                  .split("\n")
                  .map((line, i) => <p key={i}>{line}</p>)
              ) : (
                <FormattedMessage {...this.props.message} />
              )}
            </Message>
          </MessageWrapper>
          <Buttons>
            <CancelButton size="small" onClick={this.props.onCancel}>
              <FormattedMessage {...messages.cancel} />
            </CancelButton>
            <ConfirmButton size="small" onClick={this.props.onConfirm}>
              <FormattedMessage {...messages.confirm} />
            </ConfirmButton>
          </Buttons>
        </Content>
      </Modal>
    );
  }
}

export default injectIntl(ConfirmModal);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
  padding: 20px;
`;
const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const Message = styled.p`
  width: 100%;
  margin: 0 0 10px;
  font-size: 18px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const CancelButton = styled(DullButton)`
  flex: 1;
  width: 100%;
  margin-right: 5px;
`;
const ConfirmButton = styled(Button)`
  flex: 1;
  width: 100%;
  margin-left: 5px;
`;
