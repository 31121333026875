/**
 *
 * FanIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const FanIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M23.928,31.841c0,2.04,0.789,3.955,2.213,5.385c1.426,1.445,3.329,2.241,5.359,2.241c2.028,0,3.932-0.795,5.363-2.239
      c1.424-1.439,2.208-3.353,2.208-5.387c0-2.028-0.784-3.938-2.211-5.383c-1.433-1.442-3.336-2.236-5.36-2.236
      c-2.026,0-3.928,0.795-5.353,2.234C24.716,27.895,23.928,29.807,23.928,31.841z M28.987,29.273
      c0.671-0.678,1.564-1.052,2.513-1.052c0.95,0,1.846,0.375,2.518,1.051c0.679,0.688,1.053,1.6,1.053,2.568
      c0,0.974-0.374,1.888-1.051,2.573c-1.345,1.356-3.688,1.364-5.038-0.004c-0.68-0.683-1.054-1.595-1.054-2.569
      C27.928,30.871,28.303,29.961,28.987,29.273z"
        />
        <path
          d="M5.705,29.093c3.667,2.603,6.793,2.953,9.368,2.953c0.971,0,1.944-0.06,2.975-0.122l0.402-0.024
      c0.361-0.021,0.739-0.041,1.115-0.067c0.927-0.064,1.688-0.758,1.836-1.675c0.349-2.149,1.34-4.103,2.865-5.646
      c1.077-1.083,2.354-1.897,3.795-2.421c0.515-0.187,0.931-0.576,1.151-1.078c0.219-0.502,0.224-1.072,0.011-1.577
      c-0.277-0.658-0.508-1.34-0.708-2.085c-0.671-2.491,0.054-5.142,0.288-5.883c0.466-1.439,1.882-5.819-1.485-8.954
      c-1.826-1.696-3.742-2.453-6.212-2.453c-1.138,0-2.404,0.145-4.235,0.484C13.269,1.218,4.527,3.712,1.14,12.316
      C-1.932,20.109,1.677,26.223,5.705,29.093z M4.861,13.782c2.656-6.746,9.796-8.755,12.74-9.305
      c1.554-0.288,2.634-0.417,3.503-0.417c1.478,0,2.391,0.362,3.488,1.382c1.32,1.229,0.974,3.031,0.4,4.807
      c-0.412,1.301-1.269,4.698-0.341,8.141c0.068,0.255,0.14,0.505,0.216,0.751c-1.266,0.669-2.419,1.524-3.443,2.556
      c-1.733,1.754-2.969,3.886-3.624,6.235c-0.967,0.059-1.88,0.114-2.728,0.114c-1.902,0-4.172-0.171-7.05-2.213
      C5.173,23.803,2.636,19.429,4.861,13.782z"
        />
        <path
          d="M31.857,19.849c0.061,0.904,0.722,1.655,1.612,1.829c1.994,0.39,3.815,1.371,5.276,2.848
      c1.087,1.088,1.902,2.399,2.42,3.898c0.185,0.533,0.586,0.964,1.106,1.186c0.251,0.106,0.518,0.16,0.784,0.16
      c0.286,0,0.572-0.062,0.837-0.184c0.703-0.324,1.467-0.597,2.341-0.835c0.662-0.183,1.402-0.274,2.201-0.274
      c1.741,0,3.187,0.437,3.629,0.583c0.759,0.245,2.172,0.7,3.73,0.7c2.04,0,3.819-0.767,5.152-2.224
      c2.967-3.268,2.658-6.55,1.944-10.506c-0.665-3.635-3.132-12.455-11.667-15.865C49.297,0.392,47.372,0,45.502,0
      c-6.393,0-9.985,4.41-10.938,5.769c-3.267,4.695-3.042,8.469-2.78,12.869L31.857,19.849z M37.843,8.06
      C38.512,7.107,41.039,4,45.502,4c1.357,0,2.781,0.295,4.235,0.879c6.685,2.67,8.674,9.891,9.218,12.866
      c0.73,4.046,0.54,5.439-0.965,7.096c-0.573,0.627-1.271,0.919-2.196,0.919c-0.93,0-1.877-0.306-2.507-0.508
      c-0.7-0.233-2.574-0.775-4.853-0.775c-1.157,0-2.254,0.141-3.257,0.416c-0.361,0.099-0.709,0.202-1.046,0.313
      c-0.667-1.299-1.521-2.473-2.55-3.501c-1.645-1.663-3.634-2.878-5.82-3.565C35.52,14.061,35.418,11.546,37.843,8.06z"
        />
        <path
          d="M31.549,43.926c-0.052-0.939-0.751-1.715-1.68-1.864c-2.136-0.344-4.074-1.341-5.605-2.886
      c-1.005-1.011-1.778-2.206-2.297-3.552c-0.196-0.51-0.592-0.916-1.095-1.127c-0.503-0.209-1.07-0.206-1.571,0.013
      c-0.658,0.287-1.349,0.528-2.102,0.736c-0.675,0.182-1.417,0.273-2.207,0.273c-1.745,0-3.194-0.438-3.596-0.571
      c-0.008-0.003-0.102-0.033-0.11-0.035c-0.794-0.254-2.124-0.678-3.655-0.678c-2.039,0-3.821,0.768-5.159,2.226
      c-2.969,3.256-2.659,6.542-1.944,10.504c0.662,3.63,3.125,12.442,11.667,15.869C14.118,63.607,16.043,64,17.916,64
      c6.409,0,9.993-4.421,10.94-5.778c3.272-4.698,3.043-8.472,2.778-12.845C31.605,44.914,31.577,44.423,31.549,43.926z
       M25.574,55.935C24.909,56.888,22.389,60,17.916,60c-1.359,0-2.782-0.295-4.231-0.878c-6.693-2.686-8.679-9.898-9.222-12.872
      c-0.731-4.051-0.542-5.442,0.96-7.089c0.58-0.632,1.281-0.926,2.208-0.926c0.909,0,1.83,0.294,2.502,0.508
      c0.701,0.233,2.577,0.776,4.86,0.776c1.141,0,2.233-0.139,3.257-0.414c0.281-0.078,0.556-0.159,0.824-0.244
      c0.635,1.148,1.422,2.197,2.353,3.133c1.744,1.759,3.87,3.009,6.218,3.667C27.898,49.852,28.043,52.39,25.574,55.935z"
        />
        <path
          d="M57.719,34.914c-3.662-2.609-6.795-2.961-9.377-2.961c-1.118,0-2.285,0.068-3.381,0.139
      c-0.496,0.031-1.011,0.062-1.542,0.091c-0.917,0.05-1.683,0.72-1.855,1.622c-0.391,2.048-1.367,3.905-2.831,5.379
      c-1.001,1.019-2.208,1.805-3.586,2.336c-0.517,0.199-0.927,0.604-1.135,1.116s-0.193,1.089,0.04,1.591
      c0.317,0.685,0.596,1.477,0.851,2.421c0.699,2.581-0.121,5.351-0.295,5.892c-0.459,1.442-1.855,5.83,1.496,8.938
      c1.825,1.701,3.742,2.46,6.215,2.46c1.108,0,2.37-0.144,4.228-0.481c3.604-0.671,12.35-3.162,15.734-11.776
      C65.348,43.875,61.739,37.77,57.719,34.914z M58.559,50.216c-2.653,6.752-9.796,8.758-12.736,9.306
      c-1.6,0.291-2.648,0.415-3.503,0.415c-1.478,0-2.39-0.362-3.492-1.39c-1.312-1.217-0.972-3.023-0.411-4.787
      c0.327-1.009,1.316-4.577,0.348-8.157c-0.114-0.421-0.233-0.819-0.358-1.199c1.174-0.65,2.237-1.457,3.172-2.408
      c1.648-1.659,2.853-3.679,3.534-5.905c0.034-0.002,0.067-0.004,0.101-0.006c1.035-0.066,2.129-0.131,3.129-0.131
      c1.907,0,4.183,0.172,7.058,2.221C58.246,40.195,60.781,44.562,58.559,50.216z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FanIcon;
