import PropTypes from "prop-types";
import { always, ifElse, is, prop } from "ramda";
import React from "react";
import styled from "styled-components/macro";

import { messagePropType, useIntl } from "hooks/useIntl";

const DefaultLabel = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: ${ifElse(
    prop("withBorder"),
    always("uppercase"),
    always("initial")
  )}px;
  line-height: 1.1;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 1.5rem; /* Matching root line-height */
  margin-bottom: ${ifElse(prop("withBorder"), always(5), always(2))}px;
  border-bottom: ${({ theme, withBorder }) =>
    withBorder ? `2px solid ${theme.grayAccent}` : "none"};
`;

const Label = ({ children, withBorder = false, ...rest }) => {
  const { formatMessage } = useIntl();

  return (
    <LabelContainer withBorder={withBorder}>
      <DefaultLabel withBorder={withBorder} {...rest}>
        {is(String, children) ? children : formatMessage(children)}
      </DefaultLabel>
    </LabelContainer>
  );
};

export default Label;

Label.propTypes = {
  withBorder: PropTypes.bool,
  children: PropTypes.oneOfType([messagePropType, PropTypes.string]).isRequired,
};
