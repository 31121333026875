/**
 *
 * RotateRightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RotateRightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <rect x="0.208" y="0.129" fill="none" width="63.584" height="63.742" />
    <rect fill="none" width="63.584" height="63.742" />
    <g>
      <rect y="0.002" fill="none" width="64" height="64" />
      <path
        d="M54.205,7.521l-0.176-5.545l3.998-0.127l0.397,12.57L45.109,14.59l-0.051-4l6.477-0.083
    c-5.35-4.308-12.168-6.651-19.193-6.499c-7.925,0.168-15.254,3.358-20.639,8.984C6.575,18.35,3.82,25.336,4.007,32.614
    c0.352,15.439,13.636,27.724,29.612,27.382c8.48-0.179,16.426-3.924,21.8-10.274l3.054,2.584
    c-6.115,7.226-15.143,11.486-24.769,11.69C15.525,64.384,0.409,50.347,0.008,32.705C0.002,32.467,0,32.228,0,31.989
    c0-8.104,3.113-15.808,8.812-21.763c6.125-6.398,14.45-10.027,23.443-10.219C40.295-0.165,48.102,2.544,54.205,7.521z"
      />
      <rect y="0.002" fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RotateRightIcon;
