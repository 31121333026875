import * as React from "react";
import styled from "styled-components/macro";

const SvgIcon = styled.svg`
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: ${({ valign }) => valign};
  fill: ${({ color }) => color || "currentColor"};
  font-size: 1em;
  line-height: 0;
  overflow: visible !important;
`;

SvgIcon.defaultProps = {
  valign: "middle",
};

export default React.memo(SvgIcon);
