/**
 *
 * ControlUpIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ControlUpIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <polygon points="2.556,46.804 0.003,43.724 32.002,17.197 63.997,43.724 61.444,46.804 32.002,22.393       " />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ControlUpIcon;
