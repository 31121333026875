/**
 *
 * DestructiveButton
 * @author Chad Watson
 */

import React from "react";
import { ThemeProvider } from "styled-components/macro";
import { UnthemedButton } from "./index";

const DestructiveButton = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.danger,
      opposedColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton {...props} />
  </ThemeProvider>
);

export default DestructiveButton;
