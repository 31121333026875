/**
 *
 * RadialInfoIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialInfoIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M54.634,9.348c-6.04-6.025-14.081-9.342-22.644-9.342c-8.558,0-16.595,3.324-22.63,9.346C3.324,15.376,0,23.412,0,31.957
      c0,8.547,3.326,16.601,9.367,22.629C15.416,60.62,23.453,64,32.005,64h0.009c8.547,0,16.582-3.376,22.626-9.407
      c6.035-6.023,9.359-14.1,9.36-22.643C64,23.402,60.674,15.377,54.634,9.348z M51.807,51.76C46.519,57.038,39,60,32,60h-0.009
      c-7.479,0-14.505-2.965-19.799-8.246C6.909,46.482,4,39.434,4,31.957c0-7.475,2.907-14.506,8.187-19.773
      c5.279-5.269,12.312-8.178,19.804-8.178c7.496,0,14.531,2.9,19.815,8.174c5.283,5.273,8.19,12.32,8.19,19.797
      C59.996,39.45,57.085,46.493,51.807,51.76z"
        />
        <rect x="31" y="15" width="4" height="5" />
        <polygon points="35,24 33,24 31,24 27,24 27,28 31,28 31,43 27,43 27,47 38,47 38,43 35,43     " />
      </g>
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialInfoIcon;
