import styled from "styled-components/macro";

type Props = {
  small?: boolean;
  large?: boolean;
  horizontal?: boolean;
};

const Space = styled.span<Props>`
  display: ${({ horizontal }) => (horizontal ? "inline-block" : "block")};
  width: ${({ horizontal, small, large }) =>
    !horizontal ? "auto" : small ? "0.5rem" : large ? "2rem" : "1.5rem"};
  height: ${({ horizontal, small, large }) =>
    horizontal ? "auto" : small ? "0.5rem" : large ? "2rem" : "1.5rem"};
`;

export default Space;
