/**
 *
 * CheckInProtectionIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CheckInProtectionIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M63.996,12.63c-0.027-0.845-0.117-2.905-0.354-3.792C63.07,6.753,61.62,5.067,59.331,3.83C56.1,2.083,50.163,0,40.246,0
      C32.784,0,26.76,0.91,22.34,2.707c-5.355,2.184-6.276,5.377-6.426,6.659c-0.089,0.803-0.183,2.998-0.194,3.33V27H2
      c-1.104,0-2,0.896-2,2v23.883c0,0.695,0.361,1.342,0.954,1.705l14,8.596c0.32,0.196,0.683,0.295,1.046,0.295
      c0.336,0,0.673-0.084,0.977-0.254C17.608,62.869,18,62.202,18,61.479V55h4c1.104,0,2-0.896,2-2v-0.914
      c1.691,1.803,3.789,3.907,5.859,5.627C33,60.324,38.291,64,40.225,64c2.758,0,23.772-13.374,23.772-30.98L63.996,12.63z
       M14,57.902L4,51.764V32.282l10,5.153V57.902z M20,51h-2V36.216c0-0.749-0.418-1.435-1.084-1.778L10.245,31H20V51z M59.997,33.02
      c0,14.006-16.376,25.406-19.772,26.892c-1.145-0.509-4.285-2.345-7.809-5.272C28.964,51.77,25.271,47.58,24,46.1V29
      c0-1.104-0.896-2-2-2h-2.28l-0.002-14.221c0.026-0.635,0.109-2.408,0.171-2.963c0.022-0.192,0.323-1.922,3.959-3.405
      C26.555,5.312,31.598,4,40.246,4c9.08,0,14.355,1.821,17.182,3.349c1.946,1.053,2.256,2.179,2.352,2.531
      c0.078,0.318,0.18,1.664,0.219,2.815L59.997,33.02L59.997,33.02z"
        />
        <path
          d="M10.293,48.648c1.104,0,2-0.896,2-2v-1.604c0-1.104-0.896-2-2-2s-2,0.896-2,2v1.604
      C8.293,47.754,9.188,48.648,10.293,48.648z"
        />
        <path
          d="M48.091,22.888L37.006,33.424l-4.322-4.817c-0.735-0.823-2.002-0.892-2.824-0.153c-0.822,0.738-0.891,2.002-0.152,2.824
      l5.696,6.35c0.359,0.402,0.869,0.642,1.408,0.663c0.026,0.001,0.054,0.001,0.08,0.001c0.512,0,1.006-0.196,1.377-0.55
      l12.578-11.954c0.801-0.761,0.832-2.027,0.07-2.828C50.158,22.16,48.891,22.128,48.091,22.888z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default CheckInProtectionIcon;
