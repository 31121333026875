import { themePrimary } from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import { Motion, spring } from "react-motion";
import styled, { keyframes } from "styled-components/macro";

const pulsing = keyframes`
  0% {
    opacity: 1
  }
  50% { 
    opacity: .85
  }
  100% {
    opacity: 1
  }
`;

const Bar = styled.div`
  height: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
`;
const SmallerBar = styled.div`
  height: calc(1em - 4px);
  width: calc(100% - 4px);
  background-color: white;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: calc((1em - 4px) / 2);
  overflow: hidden;
`;
const Progress = styled.div`
  animation: ${pulsing} 2s linear infinite;
  height: calc(1em - 4px);
  background: ${themePrimary};
  background: linear-gradient(
    45deg,
    ${themePrimary} 0%,
    rgba(187, 133, 242, 1) 50%,
    rgba(143, 133, 242, 1) 100%
  );
  border-radius: calc((1em - 4px) / 2);
`;

const ProgressBar = ({ percentage, className }) => (
  <Bar percentage={percentage} className={className}>
    <SmallerBar>
      <Motion
        defaultStyle={{ smoothPercentage: percentage }}
        style={{ smoothPercentage: spring(percentage) }}
      >
        {({ smoothPercentage }) => (
          <Progress style={{ width: `${smoothPercentage}%` }} />
        )}
      </Motion>
    </SmallerBar>
  </Bar>
);

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  className: PropTypes.string,
};

export default ProgressBar;
