/**
 *
 * Icons for Favorites
 * @author Chad Watson
 *
 *
 */
import { compose, defaultTo, prop, __ } from "ramda";
import FAVORITE from "components/Icons/FavoriteIcon";
import THERMOSTAT from "components/Icons/TemperatureIcon";
import LIGHT from "components/Icons/LightIcon";
import LOCK from "components/Icons/LockLockedIcon";
import COOL from "components/Icons/ThermCoolIcon";
import HEAT from "components/Icons/ThermHeatIcon";
import OUTPUTS from "components/Icons/OutletIcon";
import ENERGY_SAVING from "components/Icons/LeafIcon";
import ARM_ALL from "components/Icons/AllIcon";
import ARM_PERIMETER from "components/Icons/PerimeterIcon";
import HOME from "components/Icons/HomeIcon";
import SLEEP from "components/Icons/SleepIcon";
import AWAY from "components/Icons/AwayIcon";
import ROOMS from "components/Icons/RoomsIcon";
import KIDS from "components/Icons/ChildIcon";
import DINNER from "components/Icons/DinnerIcon";
import DAYTIME from "components/Icons/SunnyIcon";
import DUSK from "components/Icons/SunriseSunsetIcon";
import NIGHT from "components/Icons/NightIcon";
import MUSIC from "components/Icons/MusicIcon";
import DATE from "components/Icons/DateIcon";
import MOVIE from "components/Icons/MovieNightIcon";
import EXIT from "components/Icons/ExitIcon";
import OFFICE from "components/Icons/DeskIcon";
import TRAVEL from "components/Icons/TravelIcon";
import GAME from "components/Icons/GameIcon";
import EXTERIOR_LIGHT from "components/Icons/ExteriorLightIcon";
import GARAGE from "components/Icons/GarageIcon";
const favorites = {
  FAVORITE,
  THERMOSTAT,
  LIGHT,
  LOCK,
  COOL,
  HEAT,
  OUTPUTS,
  ENERGY_SAVING,
  ARM_ALL,
  ARM_PERIMETER,
  HOME,
  SLEEP,
  AWAY,
  ROOMS,
  KIDS,
  DINNER,
  DAYTIME,
  DUSK,
  NIGHT,
  MUSIC,
  DATE,
  MOVIE,
  EXIT,
  OFFICE,
  TRAVEL,
  GAME,
  EXTERIOR_LIGHT,
  GARAGE
};
export default favorites;
export const getFavoriteIcon = compose(
  defaultTo(FAVORITE),
  prop(__, favorites)
);
