/**
 *
 * DatePicker Messages
 * @author Chad Watson
 *
 * This contains all the text for the DatePicker component.
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  dateFieldLabel: {
    id: "app.components.DatePicker.dateFieldLabel",
    defaultMessage: "Date"
  },
  time: {
    id: "app.components.DatePicker.time",
    defaultMessage: "Time"
  },
  prevYearButtonLabel: {
    id: "app.components.DatePicker.prevYearButtonLabel",
    defaultMessage: "Previous Year"
  },
  prevMonthButtonLabel: {
    id: "app.components.DatePicker.prevMonthButtonLabel",
    defaultMessage: "Previous Month"
  },
  nextMonthButtonLabel: {
    id: "app.components.DatePicker.nextMonthButtonLabel",
    defaultMessage: "Next Month"
  },
  nextYearButtonLabel: {
    id: "app.components.DatePicker.nextYearButtonLabel",
    defaultMessage: "Next Year"
  },
  startTime: {
    id: "app.components.DatePicker.startTime",
    defaultMessage: "Start Time"
  },
  endTime: {
    id: "app.components.DatePicker.endTime",
    defaultMessage: "End Time"
  },
  sun: {
    id: "app.components.DatePicker.weekDays.sun",
    defaultMessage: "Sun"
  },
  mon: {
    id: "app.components.DatePicker.weekDays.mon",
    defaultMessage: "Mon"
  },
  tue: {
    id: "app.components.DatePicker.weekDays.tue",
    defaultMessage: "Tue"
  },
  wed: {
    id: "app.components.DatePicker.weekDays.wed",
    defaultMessage: "Wed"
  },
  thu: {
    id: "app.components.DatePicker.weekDays.thu",
    defaultMessage: "Thu"
  },
  fri: {
    id: "app.components.DatePicker.weekDays.fri",
    defaultMessage: "Fri"
  },
  sat: {
    id: "app.components.DatePicker.weekDays.sat",
    defaultMessage: "Sat"
  }
});
