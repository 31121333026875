/**
 *
 * Outlet
 * @author Chad Watson
 *
 */

import React from "react";
import SvgIcon from "./SvgIcon";

const VIEW_SIZE = 64;

const Outlet = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <g>
        <g>
          <g>
            <path
              d="M49.775,62h-35.49c-0.434,0-0.855-0.141-1.202-0.401C12.549,61.197,0,51.549,0,33.058
          C0,11.554,12.659,3.11,13.198,2.762c0.324-0.21,0.701-0.321,1.087-0.321h35.49c0.407,0,0.804,0.124,1.139,0.355
          C51.448,3.166,64,12.102,64,33.31c0,17.702-12.448,27.832-12.978,28.254C50.667,61.846,50.228,62,49.775,62z M15.001,58h34.025
          C51.233,55.988,60,47.122,60,33.31C60,16.404,51.219,8.182,49.094,6.44H14.936c-0.907,0.705-3.093,2.589-5.253,5.854
          C7.09,16.215,4,23,4,33.058C4,47.597,12.813,56.111,15.001,58z M38.379,52.112H25.616c-1.438,0-2.609-1.177-2.609-2.623v-6.813
          C23.109,39.971,25.104,35,31.927,35c6.322,0,8.879,4.307,9.059,7.212c0.005,0.06,0.007,0.118,0.007,0.172v7.105
          C40.993,50.936,39.82,52.112,38.379,52.112z M27.006,48.112h9.987v-5.658C36.977,42.219,36.658,39,31.927,39
          c-4.478,0-4.886,3.138-4.922,3.767L27.006,48.112z M42.103,31c-1.104,0-2-0.896-2-2V16.415c0-1.104,0.896-2,2-2s2,0.896,2,2V29
          C44.103,30.104,43.208,31,42.103,31z M22.178,31c-1.104,0-2-0.896-2-2V16c0-1.104,0.896-2,2-2s2,0.896,2,2v13
          C24.178,30.104,23.282,31,22.178,31z"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Outlet;
