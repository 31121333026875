/**
 *
 * ZwaveIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ZwaveIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M47.006,30.14c-9.369,0-16.991,7.596-16.991,16.932C30.016,56.406,37.638,64,47.006,64C56.376,64,64,56.406,64,47.071
        C64,37.735,56.376,30.14,47.006,30.14z M47.006,60c-7.163,0-12.991-5.8-12.991-12.929c0-7.131,5.828-12.932,12.991-12.932
        C54.171,34.14,60,39.94,60,47.071C60,54.2,54.171,60,47.006,60z"
          />
          <path d="M45.619,4.252l-0.127-3.998C20.668,1.043,0.686,21.032,0,45.762l3.998,0.111C4.625,23.256,22.908,4.974,45.619,4.252z" />
          <path
            d="M45.463,14.282c-16.914,0.787-30.698,14.604-31.381,31.454l3.997,0.162c0.6-14.797,12.71-26.929,27.57-27.62
        L45.463,14.282z"
          />
          <path
            d="M51,50h-3.546l5.041-5.671c0.523-0.589,0.652-1.43,0.33-2.148C52.502,41.462,51.788,41,51,41h-8c-1.104,0-2,0.896-2,2
        s0.896,2,2,2h3.546l-5.041,5.671c-0.523,0.589-0.652,1.43-0.33,2.148C41.498,53.538,42.212,54,43,54h8c1.104,0,2-0.896,2-2
        S52.104,50,51,50z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ZwaveIcon;
