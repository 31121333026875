/**
 *
 * ResizeIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ResizeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <polygon
        points="60.001,31.153 59.52,55.892 8.108,5.016 33.216,3.998 33.054,0 1.293,1.288 0,32.727 3.997,32.891 5.036,7.602 
    56.942,58.969 31.425,60.002 31.587,64 63.387,62.711 64,31.231   "
      />
    </g>
  </SvgIcon>
);

export default ResizeIcon;
