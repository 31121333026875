/**
 *
 * AutomationIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AutomationIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect x="0.073" fill="none" width="64" height="64" />
      <g>
        <path
          d="M63.064,23.154L33.067,4.307c-0.638-0.4-1.494-0.4-2.132,0L0.936,23.154C0.354,23.521,0,24.16,0,24.848V30
      c0,1.105,0.896,2,2,2h3v23c0,1.105,0.896,2,2,2h50.896c1.102,0,2.044-0.891,2.048-1.992V32H62c1.105,0,2-0.895,2-2v-5.152
      C64,24.16,63.646,23.521,63.064,23.154z M60,28h-2c-1.102,0-2.042,0.891-2.048,1.992V53H34v-6.832c0-1.104-0.896-2-2-2
      s-2,0.896-2,2V53H9V30c0-1.104-0.896-2-2-2H4v-2.047L32.001,8.361L60,25.953V28z"
        />
        <path
          d="M30.634,21.549c-4.837,0.328-9.417,2.418-12.896,5.883c-0.783,0.779-0.786,2.045-0.006,2.828
      c0.779,0.783,2.046,0.785,2.828,0.006c2.756-2.744,6.527-4.467,10.359-4.729c4.619-0.344,9.348,1.447,12.649,4.795
      c0.391,0.396,0.907,0.596,1.424,0.596c0.507,0,1.015-0.191,1.404-0.576c0.787-0.775,0.795-2.041,0.02-2.828
      C42.3,23.35,36.391,21.115,30.634,21.549z"
        />
        <path
          d="M30.428,28.738c-3.554,0.379-6.897,2.025-9.415,4.635c-0.767,0.797-0.744,2.062,0.051,2.828
      c0.795,0.766,2.062,0.744,2.828-0.051c1.84-1.908,4.376-3.16,6.974-3.436c3.36-0.389,6.935,0.932,9.32,3.428
      c0.393,0.412,0.919,0.619,1.447,0.619c0.496,0,0.994-0.184,1.381-0.553c0.799-0.764,0.828-2.029,0.065-2.828
      C39.798,29.947,35.062,28.209,30.428,28.738z"
        />
        <path
          d="M31.024,35.848c-2.619,0.27-4.984,1.461-6.658,3.354c-0.732,0.826-0.655,2.09,0.172,2.822
      c0.381,0.338,0.854,0.502,1.325,0.502c0.553,0,1.104-0.227,1.499-0.674c0.994-1.123,2.479-1.861,4.07-2.025
      c1.874-0.195,3.877,0.6,5.225,2.057c0.751,0.812,2.016,0.861,2.826,0.111c0.812-0.75,0.861-2.016,0.111-2.826
      C37.376,36.77,34.172,35.527,31.024,35.848z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default AutomationIcon;
