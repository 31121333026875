import styled from "styled-components/macro";

const NakedButton = styled.button<any>`
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font-weight: inherit;
  line-height: 1;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;

export default NakedButton;
