/**
 *
 * ControlDownIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ControlDownIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <polygon points="32.002,46.803 0.003,20.276 2.556,17.196 32.002,41.607 61.444,17.196 63.997,20.276       " />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ControlDownIcon;
