/**
 *
 * TargetIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TargetIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M64,29h-8.017C54.89,18,46,8.702,34,7.744V-1h-4v8.744C18,8.702,9.105,18,8.012,29H0v4h7.968C8.804,45,18,54.786,30,55.76
    V63h4v-7.24C46,54.786,55.191,45,56.027,33H64V29z M34,51.748V46h-4v5.748C20,50.797,12.805,43,11.987,33H18v-4h-5.96
    C13.105,20,21,12.688,30,11.756V17h4v-5.244C43,12.688,50.889,20,51.955,29H46v4h6.008C51.19,43,44,50.797,34,51.748z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default TargetIcon;
