/**
 *
 * ErrorBoundary Messages
 * @author Chad Watson
 *
 *
 */
import { defineMessages } from "react-intl";
export default defineMessages({
  mainMessage: {
    id: "app.components.ErrorBoundary.mainMessage",
    defaultMessage: "Whoops. Looks like something went wrong."
  },
  refreshButtonText: {
    id: "app.components.ErrorBoundary.refreshButtonText",
    defaultMessage: "Refresh"
  },
  downloadButtonText: {
    id: "app.components.ErrorBoundary.downloadButtonText",
    defaultMessage: "Download Log File"
  },
  reportFeedback: {
    id: "app.components.ErrorBoundary.reportFeedback",
    defaultMessage: "Report Feedback"
  }
});
