import { compose, withState, withHandlers } from "recompose";
import Dropdown from "../Dropdown";

const UncontrolledDropdown = compose(
  withState(
    "selectedIndex",
    "updateSelectedIndex",
    ({ defaultIndex = 0 }) => defaultIndex
  ),
  withHandlers({
    onChange: ({ updateSelectedIndex, onChange, options }) => (
      selectedItem,
      selectedIndex
    ) => {
      updateSelectedIndex(selectedIndex);
      onChange(options[selectedIndex], selectedIndex);
    }
  })
)(Dropdown);

export default UncontrolledDropdown;
