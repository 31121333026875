/**
 *
 * UsersIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const UsersIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M26.203,36.883c7.698,0,13.503-5.187,13.503-12.065c0-7.405-6.31-13.9-13.503-13.9c-7.444,0-13.5,5.824-13.5,12.982
      C12.703,31.059,18.759,36.883,26.203,36.883z M26.203,14.917c4.974,0,9.503,4.719,9.503,9.9c0,4.673-3.997,8.065-9.503,8.065
      c-5.238,0-9.5-4.029-9.5-8.983C16.703,18.946,20.965,14.917,26.203,14.917z"
        />
        <path
          d="M51.062,29c3.25,0,5.88-2.045,5.88-5.145s-2.63-6.083-5.88-6.083c-3.248,0-5.878,2.514-5.878,5.614
      C45.183,26.485,47.813,29,51.062,29z"
        />
        <path
          d="M57.016,30.843c-1.68,1.183-3.707,1.709-5.954,1.709c-2.206,0-4.264-0.557-5.931-1.709
      c-5.121,2.611-5.911,3.558-6.111,7.669c-0.109-0.051-0.211-0.1-0.322-0.151l-1.05-0.49l-0.947,0.667
      c-2.808,1.977-6.34,2.979-10.498,2.979c-4.017,0-7.636-1.034-10.466-2.989l-0.982-0.678l-1.063,0.542
      C1.285,44.716,0.774,46.676,0.503,60.114L0.42,64h52.983l0.048-1.951C53.747,50.06,53.508,46.47,45.868,42h11.022h7.078
      C64.157,34.339,63.7,33.961,57.016,30.843z M49.498,60H40v-6h-4v6H18v-6h-4v6H4.506c0.236-11.692,0.306-12.548,9.98-17.521
      c3.321,1.992,7.344,3.039,11.717,3.039c4.538,0,8.506-1.028,11.814-3.06C48.991,47.587,49.738,48.279,49.498,60z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default UsersIcon;
