import { Z_INDEX } from "common/utils/styles";
import Tooltip from "components/Tooltip";
import React, { useEffect, useState } from "react";
import { MessageDescriptor } from "react-intl";
import styled from "styled-components/macro";
import { seconds } from "utils/dates";

const Root = styled.div<{
  className: string;
  hasMessage: boolean;
  canOpen: boolean;
}>`
  position: relative;
  width: 1em;
  cursor: ${({ hasMessage }) => (hasMessage ? "help" : "default")};
  pointer-events: ${({ canOpen }) => (canOpen ? "initial" : "none")};

  &:not(:last-child) {
    margin-right: 7px;
  }
`;
const Message = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  padding: 0.5em 0.75em;
  line-height: 1.5;
`;
const IconWrapper = styled.span`
  display: inline-block;
`;

const Indicator = ({
  icon,
  message,
  messageWidth,
  className,
}: {
  icon: (props: any) => JSX.Element;
  message: string | MessageDescriptor | null | undefined;
  messageWidth?: number;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [canOpen, setCanOpen] = useState(true);

  const timer = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    setOpen(false);
    setCanOpen(false);
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    close();
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => setCanOpen(true), seconds(0.2));
  };

  const handleTooltipClose = () => {
    setCanOpen(true);
    return {};
  };

  const Icon = icon;

  return (
    <Root
      className={className ?? ""}
      hasMessage={!!message}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      canOpen={canOpen}
    >
      <Tooltip
        anchor={({ registerAnchor }) => (
          <IconWrapper ref={registerAnchor}>
            <Icon />
          </IconWrapper>
        )}
        open={!!message && open}
        onDismiss={close}
        onClose={handleTooltipClose}
        zIndex={Z_INDEX.TOOLTIP_PRIORITY}
      >
        <Message width={messageWidth}>
          <>{message}</>
        </Message>
      </Tooltip>
    </Root>
  );
};

export default Indicator;
