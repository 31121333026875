import { BORDER_RADIUS } from "constants/index";
import {
  themeFailure,
  themeGrayAccent,
  themeGrayXlight,
} from "containers/Theme";
import styled, { css } from "styled-components/macro";

export const HEIGHT_IN_PX = 32;

export const inputStyles = css`
  display: block;
  width: 100%;
  padding: 4px 0.8em;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${themeGrayAccent};
  background: white;
  font-size: 1em;
  font-family: inherit;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    line-height: 1.5;
  }

  &[disabled] {
    background: ${themeGrayXlight};
    cursor: not-allowed;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${themeFailure};
      outline-color: ${themeFailure};
    `};

  &:focus {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.failure : theme.primary};
    outline: none;
    box-shadow: none;
  }
`;

const Field = styled.input`
  ${inputStyles};
`;

export default Field;
