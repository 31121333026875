/**
 *
 * TooltipErrorMessage
 * @author Chad Watson
 *
 */

import styled from "styled-components/macro";

const TooltipErrorMessage = styled.p`
  max-width: 18.75rem;
  min-width: 9.375rem;
  padding: 0.5rem;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;

  & ul {
    padding-left: 1.5rem;
  }
`;

export default TooltipErrorMessage;
