/**
 *
 * PoliceIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PoliceIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M56.785,10.266c0.439-0.33,0.723-0.826,0.785-1.372c0.062-0.546-0.104-1.093-0.457-1.514l-5.612-6.668
      c-0.391-0.463-0.979-0.719-1.582-0.705c-0.606,0.015-1.178,0.31-1.547,0.791C48.272,0.929,45.868,4,41.249,4
      c-4.806,0-7.771-2.626-7.895-2.737c-0.072-0.065-0.155-0.131-0.236-0.185c-0.724-0.482-1.679-0.441-2.359,0.103
      c-0.034,0.027-0.07,0.059-0.104,0.089C30.533,1.381,27.617,4,22.773,4c-4.623,0-7.019-3.069-7.109-3.188
      c-0.363-0.493-0.933-0.792-1.545-0.812c-0.627-0.019-1.199,0.242-1.594,0.711L6.91,7.38C6.557,7.8,6.367,8.329,6.429,8.875
      c0.062,0.546,0.322,1.023,0.762,1.354c0.019,0.017,1.898,1.681,2.205,6.709c0.39,6.303-0.599,12.251-1.47,17.5
      c-0.773,4.66-1.385,8.341-0.861,11.184c1.314,7.154,5.616,11.239,13.537,12.858c7.893,1.609,8.98,3.387,9.701,4.563
      c0.009,0.014,0.021,0.023,0.029,0.036c0.169,0.261,0.395,0.479,0.663,0.638c0.025,0.014,0.05,0.028,0.075,0.042
      c0.239,0.128,0.504,0.214,0.788,0.235C31.91,63.998,31.961,64,32.012,64c0.066,0,0.133-0.003,0.198-0.01
      c0.273-0.027,0.528-0.114,0.758-0.24c0.013-0.007,0.025-0.015,0.038-0.021c0.283-0.164,0.521-0.393,0.694-0.668
      c0.004-0.006,0.01-0.011,0.013-0.017c0.723-1.177,1.813-2.956,9.707-4.564c8.209-1.678,12.259-5.528,13.54-12.876
      c0.512-2.926-0.132-6.792-0.876-11.27c-0.866-5.206-1.847-11.106-1.461-17.396C54.932,11.941,56.791,10.266,56.785,10.266z
       M53.02,44.916c-0.839,4.813-2.872,8.105-10.399,9.644c-5.783,1.179-8.767,2.565-10.61,4.28c-1.844-1.715-4.826-3.101-10.608-4.28
      c-7.878-1.609-9.655-5.595-10.402-9.662c-0.397-2.155,0.191-5.7,0.873-9.805c0.861-5.188,1.934-11.646,1.516-18.399
      c-0.247-4.064-1.375-6.583-2.335-8.023l3.137-3.725C15.864,6.321,18.734,8,22.773,8c4.343,0,7.542-1.627,9.24-2.756
      C33.718,6.37,36.932,8,41.249,8c4.037,0,6.91-1.68,8.586-3.056l3.136,3.726c-0.96,1.439-2.089,3.958-2.34,8.022
      c-0.414,6.744,0.651,13.15,1.507,18.298C52.822,39.106,53.413,42.661,53.02,44.916z"
        />
        <path
          d="M43.879,26h-7.611l-2.354-7.26c-0.268-0.824-1.035-1.383-1.902-1.383s-1.636,0.559-1.902,1.383L27.756,26h-7.611
      c-0.876,0-1.649,0.569-1.91,1.406c-0.26,0.836,0.054,1.744,0.775,2.24l6.094,4.198l-2.322,6.927
      c-0.276,0.824,0.011,1.732,0.711,2.247c0.701,0.515,1.654,0.52,2.357,0.01l6.149-4.446l6.158,4.55
      c0.353,0.261,0.771,0.392,1.188,0.392c0.411,0,0.822-0.127,1.173-0.38c0.705-0.511,0.999-1.42,0.727-2.247l-2.331-7.063
      l6.097-4.184c0.723-0.496,1.038-1.405,0.778-2.242S44.755,26,43.879,26z M34.646,33.66l0.83,2.518L33.2,34.496
      c-0.354-0.261-0.771-0.392-1.188-0.392c-0.411,0-0.822,0.126-1.172,0.379l-2.286,1.653l0.821-2.448
      c0.283-0.846-0.027-1.776-0.762-2.283L26.572,30h2.638c0.867,0,1.635-0.559,1.902-1.383l0.899-2.774l0.899,2.774
      C33.178,29.441,33.946,30,34.813,30h2.616l-2.017,1.384C34.68,31.888,34.366,32.814,34.646,33.66z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PoliceIcon;
