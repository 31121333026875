/**
 *
 * HelpTitle
 * @author Chad Watson
 *
 */

import styled from "styled-components/macro";

const HelpTitle = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
`;

export default HelpTitle;
