/**
 *
 * Dropdown Messages
 * @author Alex Matthews
 *
 * This contains all the text for the Dropdown component.
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  default: {
    id: "app.components.Dropdown.default",
    defaultMessage: "Please select an option"
  }
});
