/**
 *
 * VisuallyHidden
 *
 */

import styled, { css } from "styled-components/macro";

export const visuallyHidden = css`
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
`;

const VisuallyHidden = styled.span`
  ${visuallyHidden};
`;

export default VisuallyHidden;
