/**
 *
 * TroubleshootingIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TroubleshootingIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M51.619,37.997l-0.756-0.074c-0.06-0.006-0.121-0.009-0.182-0.009c-1.104,0-2.229,0.157-3.357,0.468
    l-8.155-8.125l10.877-10.781l7.747-3.99c0.343-0.177,0.622-0.456,0.8-0.798l4.191-8.067c0.376-0.722,0.238-1.604-0.338-2.179
    L58.54,0.551c-0.574-0.57-1.449-0.707-2.17-0.336l-8.096,4.177c-0.342,0.177-0.621,0.455-0.798,0.796l-4.013,7.706L32.578,23.69
    l-6.61-6.585c0.311-1.123,0.468-2.241,0.468-3.339c0-0.062-0.003-0.125-0.01-0.188l-0.07-0.69
    C25.893,6.083,20.192,0.754,13.379,0.754c-1.91,0-3.793,0.436-5.599,1.296C7.233,2.311,6.848,2.821,6.745,3.418
    C6.644,4.016,6.838,4.625,7.267,5.053l7.435,7.407c0.175,0.174,0.309,0.39,0.409,0.663c0.257,0.694,0.1,1.441-0.41,1.951
    c-0.353,0.351-0.821,0.544-1.321,0.544c-0.188,0-0.402-0.045-0.631-0.13c-0.302-0.115-0.521-0.247-0.693-0.418L4.621,7.666
    C4.194,7.24,3.584,7.048,2.992,7.149C2.397,7.251,1.889,7.635,1.628,8.178c-0.865,1.797-1.304,3.677-1.304,5.587
    c0,6.791,5.348,12.471,12.116,12.927l0.756,0.075c0.062,0.006,0.123,0.009,0.185,0.009c1.101,0,2.225-0.156,3.354-0.465
    l4.822,4.804L2.444,51.296c-1.41,1.405-2.188,3.273-2.188,5.261s0.777,3.855,2.188,5.262c1.408,1.401,3.281,2.173,5.275,2.173
    s3.866-0.772,5.309-2.211L32.132,41.65l5.963,5.94c-0.308,1.121-0.462,2.239-0.462,3.336c0,0.061,0.003,0.123,0.009,0.184
    l0.069,0.691c0.458,6.805,6.157,12.136,12.975,12.136c1.935,0,3.822-0.437,5.608-1.299c0.545-0.263,0.928-0.772,1.028-1.369
    c0.1-0.596-0.095-1.203-0.522-1.63l-7.438-7.41c-0.17-0.168-0.311-0.396-0.412-0.661c-0.254-0.681-0.092-1.444,0.427-1.958
    c0.337-0.341,0.814-0.537,1.31-0.537c0.19,0,0.407,0.045,0.646,0.135c0.293,0.11,0.507,0.237,0.677,0.412l7.434,7.406
    c0.428,0.426,1.031,0.617,1.629,0.517c0.594-0.101,1.103-0.484,1.365-1.027c0.866-1.796,1.306-3.677,1.306-5.591
    C63.738,44.135,58.389,38.455,51.619,37.997z M10.362,59.179c-1.407,1.403-3.88,1.399-5.287,0
    c-0.704-0.701-1.091-1.632-1.091-2.621s0.387-1.92,1.128-2.66l19.069-20.134l5.295,5.272L10.362,59.179z M46.295,15.335
    c0.137-0.136,0.251-0.292,0.34-0.463l3.874-7.439l6.363-3.283l1.975,1.967l-3.287,6.327l-7.476,3.85
    c-0.169,0.087-0.323,0.2-0.458,0.333l-11.097,11l-1.312-1.307L46.295,15.335z M59.916,52.236l-5.255-5.235
    c-0.532-0.549-1.214-0.981-2.021-1.284c-2.182-0.819-4.441-0.217-5.9,1.258c-1.544,1.53-2.047,3.844-1.275,5.912
    c0.298,0.778,0.728,1.447,1.271,1.986l5.26,5.24c-0.433,0.064-0.87,0.097-1.31,0.097c-4.863,0-8.93-3.804-9.261-8.718l-0.064-0.653
    c0.011-1.01,0.214-2.061,0.604-3.125c0.25-0.683,0.08-1.449-0.435-1.961l-22.967-22.88c-0.514-0.512-1.277-0.678-1.954-0.43
    c-1.074,0.392-2.131,0.596-3.142,0.607l-0.718-0.071c-4.878-0.329-8.699-4.375-8.699-9.213c0-0.438,0.033-0.875,0.1-1.309
    l5.272,5.25c0.546,0.546,1.201,0.96,2.011,1.268c2.201,0.823,4.396,0.238,5.898-1.263c1.532-1.529,2.031-3.839,1.273-5.883
    c-0.288-0.778-0.717-1.455-1.274-2.01l-5.259-5.239c0.434-0.065,0.87-0.099,1.307-0.099c4.861,0,8.928,3.804,9.264,8.721
    l0.065,0.653c-0.011,1.018-0.211,2.038-0.611,3.116c-0.254,0.684-0.085,1.454,0.432,1.969l8.589,8.557
    c0.042,0.052,0.079,0.107,0.127,0.155l3.947,3.939c0.061,0.061,0.129,0.109,0.195,0.16l10.11,10.072
    c0.514,0.511,1.277,0.679,1.959,0.429c1.086-0.399,2.114-0.599,3.14-0.61l0.716,0.07c4.878,0.33,8.701,4.378,8.704,9.215
    C60.016,51.364,59.982,51.801,59.916,52.236z"
      />
    </g>
  </SvgIcon>
);

export default TroubleshootingIcon;
