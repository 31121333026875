import { CheckmarkIcon } from "components/Icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components/macro";
import ButtonWithIcon from "./ButtonWithIcon";
import messages from "./messages";

const DoneButton = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.grayMedium,
      opposingColor: currentTheme.trueWhite,
    })}
  >
    <ButtonWithIcon
      size="small"
      variant="outline"
      {...props}
      icon={<CheckmarkIcon />}
    >
      <FormattedMessage {...messages.done} />
    </ButtonWithIcon>
  </ThemeProvider>
);

export default DoneButton;
