/**
 *
 * LocationSolidIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LocationSolidIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M32,0C20.582,0,11.293,9.156,11.293,20.41c0,18.925,18.397,40.87,19.181,41.795L32,64.008l1.526-1.803
    c0.783-0.925,19.181-22.87,19.181-41.795C52.707,9.156,43.418,0,32,0z M32,11.364c5.087,0,9.226,4.058,9.226,9.046
    S37.087,29.457,32,29.457s-9.225-4.058-9.225-9.046S26.913,11.364,32,11.364z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default LocationSolidIcon;
