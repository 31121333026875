/**
 *
 * SettingsIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SettingsIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M62.367,25.547C61.327,24.492,59.913,24,58.387,24h-2.379c-0.833,0-1.564-0.863-1.564-1.744
      c0-0.422,0.17-0.831,0.57-1.192l1.541-1.528c1.117-1.105,1.732-2.59,1.731-4.161c0-1.571-0.615-3.05-1.73-4.153l-3.481-3.446
      c-1.066-1.067-2.579-1.681-4.15-1.681c-1.548,0-3.055,0.606-4.149,1.677l-1.472,1.468l-0.075,0.078
      c-0.323,0.359-0.907,0.541-1.361,0.541C40.974,9.857,40,9.135,40,8.311v-2.39C40,2.656,37.765,0,34.55,0h-4.755
      C26.588,0,24,2.656,24,5.921v2.374c0,1.222-1.729,2.209-2.878,0.968l-1.499-1.475c-1.051-1.077-2.572-1.695-4.169-1.695
      c-1.547,0-3.056,0.606-4.136,1.662l-3.535,3.452c-1.109,1.107-1.719,2.583-1.714,4.154c0.004,1.564,0.614,3.027,1.712,4.112
      l1.47,1.469l0.078,0.073c0.254,0.228,0.557,0.701,0.557,1.24C9.886,23.137,9.153,24,8.318,24H5.941C2.665,24,0,26.423,0,29.619
      v4.748C0,37.563,2.665,40,5.941,40h2.377c0.835,0,1.567,0.855,1.567,1.736c0,0.539-0.304,0.966-0.626,1.259l-1.478,1.479
      c-1.109,1.107-1.718,2.594-1.713,4.166c0.004,1.564,0.615,3.032,1.716,4.119l3.469,3.453c1.055,1.074,2.573,1.693,4.166,1.693
      c1.546,0,3.053-0.605,4.143-1.67l1.518-1.498l0.086-0.09c0.277-0.312,0.707-0.489,1.133-0.489c0.893,0,1.701,0.723,1.701,1.546
      v2.374C24,61.344,26.562,64,29.779,64h4.754C37.74,64,40,61.344,40,58.079v-2.374c0-0.823,0.958-1.546,1.852-1.546
      c0.548,0,1.043,0.312,1.332,0.617l1.529,1.484c1.082,1.057,2.612,1.663,4.158,1.663s3.063-0.606,4.157-1.676l3.496-3.47
      c1.104-1.104,1.716-2.574,1.716-4.142s-0.606-3.038-1.725-4.154l-1.6-1.562c-0.325-0.291-0.504-0.768-0.504-1.176
      c0-0.881,0.731-1.744,1.564-1.744h2.378C61.521,40,64,37.633,64,34.383v-4.735C64.022,28.038,63.442,26.639,62.367,25.547z
       M60,34.383C60,35.248,59.485,36,58.354,36h-2.378c-3.068,0-5.564,2.625-5.564,5.744c0,1.541,0.671,3.068,1.773,4.051l1.519,1.51
      c0.348,0.347,0.539,0.822,0.539,1.32s-0.191,0.969-0.536,1.313l-3.477,3.459c-0.67,0.654-2.023,0.642-2.662,0.017l-1.428-1.425
      c-1.111-1.181-2.747-1.831-4.289-1.831c-3.077,0-5.852,2.541-5.852,5.547v2.374C36,59.12,35.518,60,34.533,60h-4.754
      C28.786,60,28,59.12,28,58.079v-2.374c0-3.006-2.625-5.546-5.701-5.546c-1.539,0-3.021,0.645-4.055,1.772l-1.478,1.452
      c-0.677,0.658-2.044,0.653-2.672,0.013l-3.492-3.473c-0.342-0.338-0.531-0.794-0.532-1.284c-0.002-0.498,0.188-0.965,0.53-1.306
      l1.401-1.388c1.215-1.092,1.884-2.64,1.884-4.21c0-3.064-2.55-5.736-5.567-5.736H5.941C4.889,36,4,35.341,4,34.367v-4.748
      C4,28.629,4.871,28,5.941,28h2.377c3.07,0,5.567-2.625,5.567-5.744c0-1.553-0.653-3.047-1.841-4.133l-1.443-1.465
      c-0.343-0.338-0.531-0.806-0.532-1.296c-0.002-0.499,0.189-0.972,0.523-1.304l3.521-3.44c0.675-0.658,2.032-0.661,2.668-0.009
      l1.45,1.43c1.067,1.163,2.524,1.803,4.082,1.803c3.076,0,5.687-2.54,5.687-5.547V5.921C28,4.861,28.793,4,29.795,4h4.755
      C35.542,4,36,4.88,36,5.921v2.39c0,3.007,2.79,5.547,5.866,5.547c1.571,0,3.122-0.646,4.2-1.822l1.471-1.42
      c0.67-0.653,2.054-0.648,2.692-0.008l3.501,3.453c0.352,0.347,0.55,0.81,0.55,1.303c0.001,0.494-0.189,0.958-0.533,1.298
      L52.28,18.1c-1.186,1.061-1.838,2.594-1.838,4.156c0,3.066,2.549,5.744,5.565,5.744h2.379c0.454,0,0.846,0.064,1.132,0.355
      c0.318,0.322,0.489,0.704,0.481,1.264V34.383z"
        />
        <path
          d="M32.156,17.495c-8.003,0-14.514,6.503-14.514,14.497s6.511,14.497,14.514,14.497c8.002,0,14.513-6.503,14.513-14.497
      S40.158,17.495,32.156,17.495z M32.156,42.489c-5.797,0-10.514-4.709-10.514-10.497s4.717-10.497,10.514-10.497
      s10.513,4.709,10.513,10.497S37.953,42.489,32.156,42.489z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default SettingsIcon;
