/**
 *
 * StandaloneFormControlBar
 * @author Chad Watson
 *
 */

import Affix from "components/Affix";
import Breakpoints from "components/Breakpoints";
import MediaQuery from "components/Breakpoints/MediaQuery";
import {
  RadialCheckIcon,
  RadialSubtractIcon,
  ResetIcon,
} from "components/Icons";
import LoadingSpinner from "components/LoadingSpinner";
import PageContainer from "components/PageContainer";
import { ActionButton, BackButton } from "components/SectionHeader";
import { MENU_Z_INDEX } from "constants/index";
import { themeDanger, themePanelBorder, themeSave } from "containers/Theme";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components/macro";
import { media } from "utils/styles";
import messages from "./messages";

export { default as ActionButton } from "components/SectionHeader/ActionButton";

const SaveIcon = styled(RadialCheckIcon)`
  color: ${(props) => (props.status ? "lightgray" : themeSave)};
`;
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;
const ActionButtonsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${media.smallMax`
    flex: 4;
  `};
`;
const DeleteIcon = styled(RadialSubtractIcon)`
  color: ${(props) =>
    props.status === "SAVING" || props.status === true
      ? "lightgray"
      : themeDanger};
`;
const AffixWrapper = styled(PageContainer)`
  transition: background 0.5s;

  ${({ affix, left, width, topOffset }) =>
    affix &&
    css`
      position: fixed;
      top: ${topOffset}px;
      left: ${left}px;
      width: ${width}px;
      border-bottom: ${themePanelBorder};
      background: white;
      z-index: ${MENU_Z_INDEX - 1};
    `};
`;

export const SAVE_STATES = {
  SAVING: "SAVING",
  SAVED: "SAVED",
  SAVE: "SAVE",
};
const SAVE_MESSAGES = {
  [SAVE_STATES.SAVING]: messages.saving,
  [SAVE_STATES.SAVED]: messages.saved,
  [SAVE_STATES.SAVE]: messages.save,
};
const SAVE_ICONS = {
  [SAVE_STATES.SAVING]: styled(LoadingSpinner)`
    color: inherit;
  `,
  [SAVE_STATES.SAVED]: SaveIcon,
  [SAVE_STATES.SAVE]: SaveIcon,
};

export const SaveButton = forwardRef(
  (
    {
      status,
      isValid = true,
      statusMessages = SAVE_MESSAGES,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const Icon = SAVE_ICONS[status] ?? SaveIcon;

    return (
      <ActionButton
        {...rest}
        ref={ref}
        icon={
          <Icon
            status={disabled || !isValid || status === SAVE_STATES.SAVING}
          />
        }
        disabled={disabled || !isValid || status === SAVE_STATES.SAVING}
        space
        type="submit"
      >
        <FormattedMessage {...(statusMessages[status] ?? messages.save)} />
      </ActionButton>
    );
  }
);

const StandaloneFormControlBar = ({
  saveStatus,
  goBack,
  onSave,
  onDelete,
  onReset,
  isValid,
  extraButtons,
  className,
  topOffset,
  deleting = false,
  transparent = false,
}) => (
  <Breakpoints.Consumer>
    {({ breakpointNames }) => (
      <Affix topThreshold={topOffset}>
        {(affixState) => (
          <AffixWrapper
            {...affixState}
            topOffset={topOffset}
            paddingY={false}
            style={transparent ? { backgroundColor: "transparent" } : undefined}
          >
            <ActionButtons className={className}>
              {goBack ? <BackButton type="button" onClick={goBack} /> : <div />}
              <ActionButtonsRight>
                {!!onSave && (
                  <SaveButton
                    disabled={saveStatus === SAVE_STATES.SAVING || deleting}
                    status={saveStatus}
                    isValid={isValid}
                    onClick={onSave}
                  />
                )}
                {!!onDelete && (
                  <ActionButton
                    type="button"
                    icon={
                      <DeleteIcon
                        status={saveStatus === SAVE_STATES.SAVING || deleting}
                      />
                    }
                    onClick={onDelete}
                    disabled={saveStatus === SAVE_STATES.SAVING || deleting}
                    space
                  >
                    <FormattedMessage {...messages.delete} />
                  </ActionButton>
                )}
                {!!onReset && (
                  <MediaQuery targetBreakpoint={breakpointNames.SMALL}>
                    <ActionButton
                      type="button"
                      icon={<ResetIcon />}
                      onClick={onReset}
                      disabled={saveStatus === SAVE_STATES.SAVING || deleting}
                    >
                      <FormattedMessage {...messages.reset} />
                    </ActionButton>
                  </MediaQuery>
                )}
                {extraButtons}
              </ActionButtonsRight>
            </ActionButtons>
          </AffixWrapper>
        )}
      </Affix>
    )}
  </Breakpoints.Consumer>
);

StandaloneFormControlBar.propTypes = {
  saveStatus: PropTypes.oneOf(Object.values(SAVE_STATES)),
  userMadeNewChanges: PropTypes.bool,
  isStateEqual: PropTypes.bool,
  goBack: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
  isValid: PropTypes.bool,
  extraButtons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  topOffset: PropTypes.number,
  deleting: PropTypes.bool,
};

StandaloneFormControlBar.defaultProps = {
  saveStatus: SAVE_STATES.SAVE,
  goBack: undefined,
  onSave: undefined,
  onDelete: undefined,
  onReset: undefined,
  isValid: true,
  extraButtons: [],
  className: undefined,
  topOffset: 0,
};

export default StandaloneFormControlBar;
