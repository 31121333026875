/**
 *
 * ActionIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ActionIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <path
      d="M62,26H24.332l32.604-13.859c0.562-0.239,0.983-0.72,1.146-1.308s0.048-1.218-0.312-1.71l-6.084-8.351
  C51.143,0.026,50.16-0.25,49.309,0.1L1.084,19.938c-0.563,0.231-0.99,0.707-1.16,1.291c-0.17,0.584-0.064,1.214,0.286,1.711
  L5,29.729V62c0,1.104,0.896,2,2,2h55c1.104,0,2-0.896,2-2V28C64,26.896,63.104,26,62,26z M60,37H46.066l6.301-7H60V37z M46.986,30
  l-6.301,7H26.041l6.3-7H46.986z M25.618,20.04l-8.163-2.511l13.459-5.537l9.367,2.882l-13.695,5.821
  C26.345,20.396,26.014,20.162,25.618,20.04z M49.378,4.396l3.679,5.047l-7.5,3.188c-0.236-0.272-0.545-0.487-0.914-0.601
  l-7.908-2.433L49.378,4.396z M4.925,22.684l6.71-2.761l9.727,2.993l-7.322,3.112c-0.675,0.287-1.139,0.92-1.208,1.65
  c-0.069,0.73,0.265,1.44,0.874,1.85l0.194,0.131C14.229,29.881,14.619,30,15.017,30H26.96l-6.3,7H9v-7.906
  c0-0.413-0.128-0.815-0.366-1.153L4.925,22.684z M9,60V41h51v19H9z"
    />
  </SvgIcon>
);

export default ActionIcon;
