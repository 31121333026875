import ShowPasswordButton from "components/ShowPasswordButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components/macro";
import BigField from "./BigField";

const Root = styled.div`
  position: relative;
  height: 3.75rem;
`;
const Field = styled(BigField)`
  text-align: left;
`;

const ShowPasswordButtonContainer = styled.div`
  position: absolute;
  bottom: 30%;
  right: 0.5rem;
  transform: translateY(50%);
  height: 2.5rem;
`;

// BigField uses the className on its root div, but it
// passes the style on to the input which is what we need to style.
const fieldStyle = {
  paddingRight: "2.5rem",
};

function BigPasswordField({ value, className, ...rest }) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Root className={className}>
      <Field
        {...rest}
        type={passwordVisible ? "text" : "password"}
        style={fieldStyle}
        value={value}
      />
      <ShowPasswordButtonContainer>
        <ShowPasswordButton
          active={passwordVisible}
          visible={passwordVisible || !!value}
          onClick={() => {
            setPasswordVisible(!passwordVisible);
          }}
        />
      </ShowPasswordButtonContainer>
    </Root>
  );
}

BigPasswordField.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BigPasswordField;
