import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const MobileCredentialsIcon = (
  props: React.ComponentPropsWithoutRef<"svg">
) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <path d="M45.33,0H18.67a6.85,6.85,0,0,0-6.84,6.84V57.1a6.85,6.85,0,0,0,6.84,6.84H45.33a6.85,6.85,0,0,0,6.84-6.84V6.86A6.85,6.85,0,0,0,45.33,0ZM15.83,10.84H48.17V48.63H15.83ZM18.67,4H45.33a2.85,2.85,0,0,1,2.84,2.82H15.83A2.85,2.85,0,0,1,18.67,4ZM45.33,59.94H18.67a2.84,2.84,0,0,1-2.84-2.84V52.63H48.17V57.1A2.84,2.84,0,0,1,45.33,59.94Z" />
      <path d="M32.44,54a2.22,2.22,0,1,0,2.21,2.22A2.22,2.22,0,0,0,32.44,54Z" />
      <path d="M31.92,28.88a7,7,0,1,0-7-7A7,7,0,0,0,31.92,28.88Zm0-10a3,3,0,1,1-3,3A3,3,0,0,1,31.92,18.92Z" />
      <path d="M45.75,38.25c-.36-6.13-1.5-6.87-7.48-9.76L37.19,28l-1,.71a7.3,7.3,0,0,1-8.53,0l-1-.72-1.09.58c-5.69,3-6.61,3.91-6.73,9.75,0,.57,0,4.45,0,4.45l0,2H45.72v-2s0-3.94,0-4.41v-.14Zm-4,2.54H39.31V36.34h-4v4.45h-6V36.34h-4v4.45H22.84c0-1,0-2.1,0-2.38.08-3.59.08-3.88,3.57-5.75a11.33,11.33,0,0,0,11.11-.08c4,1.94,4,2.21,4.21,5.84C41.75,38.77,41.75,39.81,41.74,40.79Z" />
      <rect style={{ fill: "none" }} width="64" height="64" />
    </g>
  </SvgIcon>
);

export default MobileCredentialsIcon;
