/**
 *
 * BrightnessIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const BrightnessIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M31.999,15.343c-9.184,0-16.655,7.472-16.655,16.655c0,9.186,7.472,16.659,16.655,16.659s16.654-7.474,16.654-16.659
      C48.653,22.814,41.183,15.343,31.999,15.343z M31.999,44.657c-6.979,0-12.655-5.679-12.655-12.659
      c0-6.979,5.677-12.655,12.655-12.655c6.978,0,12.654,5.677,12.654,12.655C44.653,38.979,38.977,44.657,31.999,44.657z"
        />
        <rect x="30" width="4" height="11" />
        <rect x="30" y="53" width="4" height="11" />
        <rect y="30" width="11" height="4" />
        <rect x="53" y="30" width="11" height="4" />
        <rect
          x="7.737"
          y="11.325"
          transform="matrix(0.7072 0.7071 -0.7071 0.7072 13.3227 -5.5164)"
          width="11.168"
          height="4"
        />
        <rect
          x="45.09"
          y="48.676"
          transform="matrix(-0.7068 -0.7075 0.7075 -0.7068 50.6399 122.341)"
          width="11.171"
          height="4"
        />
        <rect
          x="11.32"
          y="45.089"
          transform="matrix(-0.7074 -0.7069 0.7069 -0.7074 -13.0772 95.9365)"
          width="4.001"
          height="11.173"
        />
        <rect
          x="48.676"
          y="7.736"
          transform="matrix(0.7074 0.7069 -0.7069 0.7074 24.2474 -31.9224)"
          width="4.001"
          height="11.172"
        />
      </g>
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default BrightnessIcon;
