/**
 *
 * SkipUpIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SkipUpIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <g>
            <polygon points="2.553,51.949 0,48.869 32.002,22.344 64,48.869 61.447,51.949 32.002,27.539         " />
          </g>
        </g>
        <g>
          <g>
            <polygon points="2.556,41.656 0.003,38.576 32.002,12.051 63.997,38.576 61.444,41.656 32.002,17.246         " />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default SkipUpIcon;
