/**
 *
 * SaveButton
 * @author Chad Watson
 *
 */

import { UnthemedButton } from "components/Button";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components/macro";
import messages from "./messages";

/** @type {(props: React.ButtonHTMLAttributes<any>) => JSX.Element} */
const SaveButton = ({ children, ...props }) => (
  <ThemeProvider
    theme={React.useMemo(
      () => (currentTheme) => ({
        ...currentTheme,
        color: currentTheme.save,
        opposedColor: currentTheme.trueWhite,
      }),
      []
    )}
  >
    <UnthemedButton {...props} size="small" variant="outline">
      {children || <FormattedMessage {...messages.save} />}
    </UnthemedButton>
  </ThemeProvider>
);

export default SaveButton;
