/**
 *
 * VisibilityIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const VisibilityIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <rect fill="none" width="64" height="64" />
    <g>
      <path
        d="M63.219,30.866C62.654,30.045,49.186,10.765,32,10.765S1.346,30.045,0.781,30.866L0.001,32l0.78,1.134
    C1.346,33.955,14.814,53.235,32,53.235s30.654-19.281,31.219-20.102L63.999,32L63.219,30.866z M32,46.302
    c-7.874,0-14.279-6.416-14.279-14.302S24.126,17.698,32,17.698S46.279,24.114,46.279,32S39.874,46.302,32,46.302z M4.917,32
    c1.716-2.232,5.937-7.324,11.56-11.398c-2.35,3.199-3.756,7.132-3.756,11.398s1.406,8.199,3.756,11.398
    C10.854,39.324,6.633,34.232,4.917,32z M47.506,43.422c2.359-3.203,3.773-7.146,3.773-11.422c0-4.266-1.406-8.2-3.756-11.398
    c5.624,4.075,9.846,9.168,11.561,11.4C57.368,34.238,53.143,39.345,47.506,43.422z"
      />
      <path
        d="M36.295,31.673c-2.518,0-4.558-2.043-4.558-4.564c0-1.214,0.481-2.312,1.254-3.13
    c-0.325-0.039-0.655-0.066-0.991-0.066c-4.46,0-8.078,3.621-8.078,8.088c0,4.466,3.618,8.087,8.078,8.087s8.079-3.621,8.079-8.087
    c0-0.699-0.099-1.375-0.266-2.022C38.978,31.004,37.721,31.673,36.295,31.673z"
      />
    </g>
  </SvgIcon>
);

export default VisibilityIcon;
