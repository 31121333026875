/**
 *
 * RefreshButton
 * @author Chad Watson
 *
 *
 */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AnimatedCycleIcon } from "components/Icons";
import ActionButton from "./ActionButton";
import messages from "./messages";

const RefreshButton = ({ refreshing, ...rest }) => (
  <ActionButton
    {...rest}
    icon={<AnimatedCycleIcon animated={refreshing} />}
    disabled={refreshing}
  >
    <FormattedMessage {...messages.refreshButtonLabel} />
  </ActionButton>
);

export default RefreshButton;
