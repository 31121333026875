/**
 *
 * CloudDownloadIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CloudDownloadIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M54.347,17.439c0.048-0.203,0.074-0.413,0.056-0.632C53.59,7.464,45.728,0.146,36.503,0.146
        c-5.63,0-11.28,2.809-14.747,7.329c-0.056,0.072-0.097,0.15-0.141,0.227C21.52,7.65,21.429,7.59,21.332,7.542
        c-0.018-0.009-0.102-0.05-0.12-0.058l-0.163-0.078c-0.386-0.177-0.771-0.324-1.172-0.445l-0.113-0.03
        c-0.354-0.102-0.711-0.181-1.066-0.238l-0.33-0.052c-0.472-0.06-0.85-0.086-1.225-0.086c-5.231,0-9.487,4.251-9.487,9.477
        c0,0.165,0.019,0.33,0.051,0.507c0.005,0.046,0.013,0.092,0.021,0.138c-4.436,2.43-7.594,7.59-7.594,12.539
        C0.133,36.264,5.605,41,13.75,41H19c1.104,0,2-0.896,2-2s-0.896-2-2-2h-5.25c-4.649,0-9.617-2.045-9.617-7.785
        c0-3.595,2.607-7.657,5.961-9.259l0.793-0.392C11.568,19.228,12,18.532,12,17.771V17.1c0-0.496-0.168-0.876-0.305-1.113
        c-0.003-0.009-0.006-0.017-0.01-0.024l-0.028-0.072c0.075-2.955,2.507-5.335,5.485-5.335c0.246,0,0.482,0.024,0.698,0.051
        l0.201,0.032c0.208,0.034,0.411,0.078,0.61,0.136l0.086,0.021c0.221,0.067,0.436,0.152,0.717,0.283l0.094,0.044
        c0.191,0.096,0.379,0.198,0.559,0.314c1.532,0.988,2.522,2.844,2.522,4.726c0,1.104,0.896,2,2,2s2-0.896,2-2
        c0-2.164-0.749-4.248-2.032-5.919c0.119-0.098,0.233-0.206,0.332-0.335c2.727-3.555,7.16-5.763,11.573-5.763
        c7.157,0,13.261,5.697,13.909,12.976c-0.74,0.016-1.544,0.068-2.348,0.19c-0.213,0.031-0.455,0.116-0.669,0.226
        c-0.703,0.31-1.194,1.013-1.194,1.831c0,1.104,0.896,2,2,2c0.259,0,0.51-0.047,0.749-0.141c0.673-0.084,1.352-0.112,1.956-0.112
        c1.215,0,2.125,0.123,2.098,0.106c3.991,0.755,7.118,4.656,7.118,8.882c0,5.085-4.517,6.896-8.744,6.896H47c-1.104,0-2,0.896-2,2
        s0.896,2,2,2h4.378C59,41,64.122,36.621,64.122,30.104C64.122,24.154,59.992,18.844,54.347,17.439z"
          />
          <polygon points="37,55.098 37,24 33,24 33,55.425 23.242,45.654 20.412,48.48 34.906,62.995 49.021,48.461 46.152,45.674       " />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default CloudDownloadIcon;
