/**
 *
 * Help
 * @author Chad Watson
 *
 */

import HelpButton from "components/HelpButton";
import styled from "styled-components/macro";

const Help = styled(HelpButton)`
  font-size: 11px;
`;

export default Help;
