/**
 *
 * AddIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AddIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <polygon points="64,30 34,30 34,0 30,0 30,30 0,30 0,34 30,34 30,64 34,64 34,34 64,34   " />
    </g>
  </SvgIcon>
);

export default AddIcon;
