/**
 *
 * RadialQuestionIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialQuestionIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M54.633,9.47c-6.049-6.035-14.087-9.358-22.646-9.358c-8.547,0-16.582,3.322-22.626,9.353
      C3.325,15.487,0.001,23.509,0,32.052c0,8.547,3.326,16.574,9.366,22.602C15.405,60.681,23.446,64,32.009,64h0.003
      c8.557,0,16.593-3.316,22.628-9.339C60.676,48.638,64,40.615,64,32.071C64,23.524,60.674,15.498,54.633,9.47z M51.813,51.83
      C46.534,57.099,39.502,60,32.012,60H32.01c-7.496,0-14.534-2.904-19.817-8.177S4,39.529,4,32.052
      c0.001-7.473,2.908-14.489,8.187-19.757c5.288-5.277,12.32-8.184,19.815-8.184c7.478,0,14.512,2.909,19.806,8.19
      C57.091,17.573,60,24.594,60,32.071C60,39.545,57.093,46.562,51.813,51.83z"
        />
        <path
          d="M32.211,16c-2.32,0-4.281,0.7-5.827,2.079c-1.625,1.447-2.427,3.408-2.383,5.829l4-0.073
      c-0.03-1.623,0.581-2.357,1.044-2.77C29.849,20.348,30.884,20,32.211,20c1.427,0,2.476,0.355,3.207,1.086
      c0.73,0.729,1.086,1.744,1.086,3.1c0,1.112-0.257,2.057-0.785,2.89c-0.634,1.001-1.57,2.086-2.718,3.166
      c-0.751,0.645-1.424,1.353-1.452,1.383L31,32.203V38h4v-4.17c0.194-0.186,0.427-0.401,0.673-0.613c1.459-1.37,2.611-2.716,3.424-4
      c0.934-1.471,1.407-3.164,1.407-5.031c0-2.438-0.76-4.432-2.259-5.93C36.746,16.759,34.716,16,32.211,16z"
        />
        <rect x="31" y="43" width="4" height="5" />
      </g>
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialQuestionIcon;
