/**
 *
 * DocFileIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DocFileIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path d="M0,0v64h44.92L64,41.74V0H0z M4,4h56v36H42v20H4V4z M56.795,44L46,56.594V44H56.795z" />
    </g>
  </SvgIcon>
);

export default DocFileIcon;
