/**
 *
 * AllLargeIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AllLargeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>all_large</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M61.38,0H1.17A1.17,1.17,0,0,0,0,1.17V49.73A1.17,1.17,0,0,0,1.17,50.9H18.39V62.83A1.17,1.17,0,0,0,19.56,64H61.38a1.17,1.17,0,0,0,1.17-1.17V1.17A1.17,1.17,0,0,0,61.38,0Z" />
      </g>
    </g>
  </SvgIcon>
);

export default AllLargeIcon;
