/**
 *
 * FireIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const FireIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M50.764,17.84c-0.677-0.387-1.517-0.344-2.152,0.107c-0.635,0.453-0.948,1.234-0.804,2
    c0.006,0.031,0.523,3.008-0.283,6.299c-0.192-2.162-0.808-4.625-2.234-7.111c-1.457-2.535-3.533-4.328-5.542-6.062
    c-3.472-2.998-6.215-5.365-5.713-10.891c0.065-0.717-0.259-1.412-0.849-1.822c-0.591-0.412-1.357-0.475-2.005-0.164
    c-0.517,0.246-12.606,6.133-9.415,14.426c0.717,1.863,1.486,2.797,2.048,3.479c0.679,0.822,1.087,1.32,1.088,4.73
    c0,1.074-0.921,2.17-2.114,3.113c0.662-3.252,0.628-7.154-2.564-8.941c-0.514-0.289-1.129-0.332-1.68-0.125
    s-0.984,0.648-1.183,1.203c-0.015,0.041-1.524,4.164-5.479,6.914c-5.86,4.084-7.856,9.062-6.284,15.668
    C8.94,54.684,27.107,63.293,28.215,63.805c0.08,0.039,0.163,0.072,0.246,0.098C28.664,63.969,28.871,64,29.076,64
    c0.043,0,0.086,0,0.128-0.004c0.341-0.021,0.679-0.129,0.979-0.328c0.752-0.5,1.077-1.441,0.793-2.297
    c-0.16-0.482-1.407-4.754,3.895-6.543l0.148-0.051c5.244-1.764,17.525-5.896,21.466-13.672C64.267,25.771,50.9,17.916,50.764,17.84
    z M52.917,39.295c-3.201,6.318-14.806,10.223-19.174,11.691l-0.15,0.051c-4.022,1.357-5.75,3.756-6.422,6.021
    c-0.081-0.02-0.165-0.023-0.248-0.033c-0.143-1.002-0.127-2.076,0.158-3.203c1.654-6.521,6.339-5.193,9.922-10.516
    c2.805-4.176-2.053-10.561-2.053-10.561c-1.664,8.326-9.068,5.584-12.814,12.824c-1.958,3.787-0.143,8.07,2.305,11.531
    c-5.394-3.434-13.088-9.545-14.951-17.365c-1.173-4.93,0.227-8.355,4.68-11.459c2.248-1.564,3.859-3.451,4.963-5.082
    c-0.104,2.006-0.914,4.594-1.629,6.182c-0.335,0.738-0.189,1.607,0.368,2.199c0.558,0.588,1.417,0.783,2.175,0.49
    c0.906-0.35,8.857-3.586,8.856-9.236c-0.001-4.52-0.757-5.764-2.001-7.273c-0.472-0.574-0.918-1.115-1.402-2.373
    c-1.16-3.014,2.056-5.941,4.61-7.701c0.7,5.156,4.026,8.029,7.024,10.617c1.836,1.586,3.57,3.084,4.688,5.027
    c3.109,5.418,1.224,10.674,1.145,10.885c-0.271,0.717-0.112,1.523,0.412,2.082c0.523,0.559,1.321,0.768,2.051,0.541
    c0.168-0.053,4.135-1.336,5.705-6.439c0.455-1.479,0.684-2.92,0.781-4.201C54.339,26.803,56.697,31.85,52.917,39.295z"
      />
    </g>
  </SvgIcon>
);

export default FireIcon;
