/**
 *
 * ComputerPointerIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ComputerPointerIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M35.155,64c-2.427,0-5.343-0.931-6.25-3.543l-4.44-12.761l-4.832,7.359c-0.733,0.963-1.891,1.538-3.096,1.538
      l-0.325-0.026l-0.89-0.172c-1.578-0.525-2.637-2.008-2.637-3.688V3.867c0-1.596,1.005-3.053,2.501-3.624
      c1.603-0.586,3.272-0.084,4.311,1.146L50.41,38.396c1.052,1.24,1.204,3.029,0.368,4.44c-0.72,1.2-1.968,1.91-3.344,1.91l0,0
      c-0.226,0-0.455-0.019-0.685-0.06l-0.173-0.038l-8.601-2.312l4.423,12.732c0.488,1.391,0.33,3.047-0.445,4.656
      c-0.883,1.83-2.386,3.263-4.019,3.83C37.12,63.845,36.155,64,35.155,64z M25.574,38.71l7.109,20.434
      C32.805,59.494,33.755,60,35.155,60c0.541,0,1.057-0.078,1.454-0.219c0.625-0.218,1.336-0.953,1.741-1.794
      c0.298-0.617,0.402-1.23,0.272-1.601l-6.904-19.875l15.354,4.128L16.686,4.264v48.001L25.574,38.71z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ComputerPointerIcon;
