/**
 *
 * SuccessButton
 * @author Chad Watson
 */

import React from "react";
import { ThemeProvider } from "styled-components/macro";
import { UnthemedButton } from "./index";

/** @type {(props: {
 * shape?: "default" | "round";
 * variant?: "default" | "outline";
 * size?: "default" | "small";
 * next?: boolean;
 * prev?: boolean;
  } & React.ButtonHTMLAttributes<any>) => JSX.Element} */
const SuccessButton = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.success,
      opposedColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton {...props} />
  </ThemeProvider>
);

export default SuccessButton;
