/**
 *
 * HeadsetMonitoringIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const HeadsetMonitoringIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M55.852,19.179C55.111,16.463,49.874,0,32,0S8.889,16.463,8.148,19.179C3.517,20.051,0,24.119,0,29v10
    c0,5.514,4.486,10,10,10h1c1.832,0,3.273-0.516,4.283-1.534c1.767-1.781,1.745-4.522,1.723-7.424L17,29l0.005-1.042
    c0.022-2.902,0.044-5.643-1.723-7.424c-0.75-0.756-1.741-1.233-2.952-1.429C13.605,15.167,18.418,4,32,4
    c13.584,0,18.396,11.171,19.669,15.104c-1.21,0.196-2.202,0.673-2.952,1.429c-1.767,1.781-1.745,4.522-1.723,7.424L47,39
    l-0.005,1.042c-0.022,2.831-0.03,5.501,1.608,7.284c-1.071,1.807-3.948,5.372-9.906,5.64C37.823,50.096,35.152,48,32,48
    c-3.86,0-7,3.14-7,7s3.14,7,7,7c3.177,0,5.862-2.13,6.714-5.035c8.355-0.295,12.216-5.554,13.562-8.019
    C52.516,48.966,52.745,49,53,49h1c5.514,0,10-4.486,10-10V29C64,24.119,60.483,20.051,55.852,19.179z M13.005,27.926L13,39
    l0.005,1.074c0.015,1.865,0.031,3.977-0.562,4.575C12.155,44.939,11.5,45,11,45h-1c-3.309,0-6-2.691-6-6V29c0-3.309,2.691-6,6-6h1
    c0.5,0,1.155,0.062,1.443,0.351C13.037,23.949,13.02,26.062,13.005,27.926z M32,58c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3
    S33.654,58,32,58z M60,39c0,3.309-2.691,6-6,6h-1c-0.5,0-1.155-0.061-1.443-0.351c-0.593-0.598-0.577-2.71-0.562-4.575L51,29
    l-0.005-1.074c-0.015-1.864-0.031-3.977,0.562-4.575C51.845,23.062,52.5,23,53,23h1c3.309,0,6,2.691,6,6V39z"
      />
    </g>
  </SvgIcon>
);

export default HeadsetMonitoringIcon;
