/**
 *
 * DateField
 * @author Chad Watson
 *
 */

import DatePicker from "components/DatePicker";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import FieldWithPicker, { FIELD_TYPES } from "./FieldWithPicker";
import messages from "./messages";

const StyledDatePicker = styled(DatePicker)`
  width: 250px;
`;

const DISPLAY_FORMATS = {
  WITH_TIME: "L LT",
  WITHOUT_TIME: "L",
};

/** @type {(props: {
 *  id: string,
 *  onChange: (newDate: string) => void,
 *  displayFormat?: string,
 *  fieldType?: "default" | "big",
 *  label?: React.ReactNode,
 *  value?: Date | string,
 *  utc?: boolean,
 *  canClear?: boolean,
 *  disablePast?: boolean,
 *  disableAfterOneYear?: boolean,
 *  withTime?: boolean,
 *  ref?: any,
 *  className?: string,
 *  zIndex?: number,
 *  disabled?: boolean,
 *  closeOnSelect?: boolean,
 * }) => JSX.Element} */
const DateField = (props) => {
  const { withTime } = props;
  const displayFormat =
    props.displayFormat ||
    (withTime ? DISPLAY_FORMATS.WITH_TIME : DISPLAY_FORMATS.WITHOUT_TIME);

  const { value, utc } = props;
  const date = !value ? null : utc ? moment.utc(value) : moment(value);
  const displayValue = date ? date.format(displayFormat) : "";

  const clearValue = () => props.onChange("");

  return (
    <FieldWithPicker
      id={props.id}
      label={props.label}
      fieldType={props.fieldType}
      value={displayValue}
      onClear={props.canClear ? clearValue : undefined}
      ref={props.ref}
      className={props.className}
      zIndex={props.zIndex}
      dismissOnScroll={props.dismissOnScroll}
      closeOnSelect={props.closeOnSelect}
      disabled={props.disabled}
    >
      <StyledDatePicker
        disablePast={props.disablePast}
        disableAfterOneYear={props.disableAfterOneYear}
        id={props.id}
        startValue={
          typeof props.value === "string" && utc
            ? moment.utc(props.value)
            : props.value
        }
        onStartChange={props.onChange}
        withTime={props.withTime}
      />
    </FieldWithPicker>
  );
};

DateField.defaultProps = {
  label: <FormattedMessage {...messages.dateFieldLabel} />,
  displayFormat: null,
  value: "",
  canClear: true,
  withTime: true,
  ref: null,
  fieldType: FIELD_TYPES.DEFAULT,
  utc: false,
  disablePast: false,
  disableAfterOneYear: false,
  className: "",
  disabled: false,
  closeOnSelect: false,
};

export default DateField;
